import React, { useState, useEffect } from "react";
import Myaccount from "../MyAccount/Myaccount";
import "./Wishlist.css";
// import prodData from '../products.json'
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Config } from "../../Config/Config";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  getData,
  getLocalStorage,
} from "../DataFromLocalStorage/LocalstorageData";

function Wishlist() {
  const [favicon, setFavIcon] = useState(true);
  const [userdata, SetUserData] = useState(undefined);
  const [custcode, SetCustcode] = useState(undefined);
  const [alldata, setAlldata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputstate, setInputstate] = useState("");
  const [show, setShow] = useState(false);
  const [wishlist_mainid, setWishlist_mainid] = useState("");
  const [newdata, setNewdata] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    console.log("....", e.target.value);
    setInputstate(e.target.value);
  };

  useEffect(() => {
    try {
      const d = getLocalStorage("CustomerData");
      SetUserData(d.data);
      SetCustcode(d.data.cust_code);
      // console.log(">....", d.data.cust_code)
      const URL = Config.PRODUCT_WISHLIST + d.data.cust_code;
      console.log(URL);

      setLoading(true);
      axios
        .get(`${URL}`, {
          headers: {
            "Cache-Control": "no-store",
          },
        })
        .then((res) => {
          console.log("axios response wishlist", res.data);
          console.log("axios response wishlist", res.data.wishlistData);
          // console.log("axios response ", res.data.length);
          const xyz = res.data;
          setAlldata(xyz);
          if (res.data.wishlistData.length > 0) {
            // setLoading(false);
            setNewdata(res.data.wishlistData);
          }
          console.log("axios response fash", alldata);
          console.log("res.data.wishlistData", res.data.wishlistData);
        })
        .catch((err) => {
          // setLoading(false);
          // console.log("axios error elec", err)
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      // console.log('is error', error);
    }
  }, []);

  const handleAdd = () => {
    let formData = new FormData();

    formData.append("cust_code", custcode);
    formData.append("access_key", userdata.access_token);
    formData.append("is_private", "0");
    formData.append("is_default", newdata.length > 0 ? "0" : "1");
    formData.append("label", inputstate);

    axios
      .post(`${Config.CREATE_WISHLIST}`, formData)
      .then((res) => {
        // console.log("............000.....", res.data);
        setLoading(true);
        axios
          .get(`${URL}`, {
            headers: {
              "Cache-Control": "no-store",
            },
          })
          .then((res) => {
            console.log("axios response wishlist", res.data);
            console.log("axios response wishlist", res.data.wishlistData);
            // console.log("axios response ", res.data.length);
            const xyz = res.data;
            setAlldata(xyz);
            if (res.data.wishlistData.length > 0) {
              // setLoading(false);
              setNewdata(res.data.wishlistData);
            }
            console.log("axios response fash", alldata);
            console.log("res.data.wishlistData", res.data.wishlistData);
          })
          .catch((err) => {
            // setLoading(false);
            // console.log("axios error elec", err)
          })
          .finally(() => {
            setLoading(false);
          });
        alert("done");
      })
      .catch((err) => {
        // console.log("............000.....", err.data);
        alert("fail");
      });
    setShow(false);
  };

  const handledelete = (e) => {
    // console.log("id", e);
    setWishlist_mainid(e);
    // console.log("......id...........", wishlist_mainid);
    // console.log(".....remove url.........",Config.REMOVE_WISHLIST)

    var data = new FormData();
    data.append("wishlist_id", e);
    data.append("cust_code", custcode);
    data.append("access_key", userdata.access_token);

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        // console.log(this.responseText);
        alert("wishlist removed");
        setLoading(true);
        axios
          .get(`${URL}`, {
            headers: {
              "Cache-Control": "no-store",
            },
          })
          .then((res) => {
            console.log("axios response wishlist", res.data);
            console.log("axios response wishlist", res.data.wishlistData);
            // console.log("axios response ", res.data.length);
            const xyz = res.data;
            setAlldata(xyz);
            if (res.data.wishlistData.length > 0) {
              // setLoading(false);
              setNewdata(res.data.wishlistData);
            }
            console.log("axios response fash", alldata);
            console.log("res.data.wishlistData", res.data.wishlistData);
          })
          .catch((err) => {
            // setLoading(false);
            // console.log("axios error elec", err)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });

    xhr.open("POST", `${Config.REMOVE_WISHLIST}`);

    xhr.send(data);
  };

  return (
    <div className="user-wishlist">
      <div>
        <Myaccount />
      </div>

      <div className="user-wishlist-product">
        {!loading ? (
          <div>
            {newdata.length == 0 ? (
              <div>
                <div className="wishlist-header">
                  <h2>My WishList</h2>
                  <Button
                    variant="primary"
                    onClick={handleShow}
                    className="add-wishlist"
                  >
                    + Create WishList
                  </Button>
                </div>
                <div className="empty-wish">
                  <i class="fa-solid fa-cart-arrow-down  empty-wish-icon"></i>
                  <h3>Your WishList is empty!</h3>
                  <h6>Looks like you haven't add your products yet.</h6>
                </div>
              </div>
            ) : (
              <div>
                <div className="wishlist-header">
                  <h2>My WishList</h2>
                  <Button
                    variant="primary"
                    onClick={handleShow}
                    className="add-wishlist"
                  >
                    + Create WishList
                  </Button>
                </div>
                <div>
                  {newdata.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <div>
                          <div className="wishlist-folders-div">
                            <Link
                              to={"/myaccount/wishlist/" + `${item.label}`}
                              className="wishlist-folders-link"
                            >
                              <h5 className="wishlist-folders">
                                <i class="fa-solid fa-folder-closed wishlist-folder"></i>
                                {item.label}
                              </h5>
                              <h6>({item.products.length})</h6>
                            </Link>

                            <i
                              className="fa-solid fa-trash delete-wishlist-icon"
                              onClick={() => {
                                handledelete(item.id);
                              }}
                              value={item.id}
                            ></i>
                          </div>
                          <div className="overview_img_div">
                            {item.products.map((prod, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Link   to={`/${prod.slug}/${prod.book_id}`}>
                                  <img src={prod.feature_image} className="overview_img" />
                                  </Link>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ) : (
          <Spinner animation="border" />
        )}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>New WishList</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="modal-label">Wishlist Name</Form.Label>
                <Form.Control
                  type="text"
                  // placeholder="Wishlist Name"
                  autoFocus
                  className="modal-input"
                  onChange={handleChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="madal-footer">
            <Button
              variant="secondary"
              onClick={handleClose}
              className="madal-footer-close"
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handleAdd}
              className="madal-footer-save"
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Wishlist;
