import React, { useState, useEffect } from "react";
import { Container, Col, Row ,Spinner} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Policy.css";
import axios from "axios";
import { Config } from "../../Config/Config";

function Policy() {

	const [alldata, setAllData] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		axios.get(`${Config.GET_PRIVACY_DETAIL}`)
			.then(res => {
				console.log("axiois data", res.data);
				setAllData(res.data);
				setLoading(true);
			})
			.catch(err => {
				console.log("axios error", err);
			})
	}, [])

	const renderList = () => {

		if (alldata.length == 0) {
			return null;
		}

		return alldata.value.map((d, index) => {
			// console.warn("inside", alldata)
			return (<div key={index}>
				<a><p>{d.link_name}</p></a>
			</div>)
		}
		)
	}

	const AllrenderList = () => {

		if (alldata.length == 0) {
			return null;
		}

		return alldata.value.map((d, index) => {
			console.warn("inside", alldata)
			return (<div key={index}>
				<div>
					<h5 className="policy-container-text-h">{d.link_name}</h5>
					<p className="policy-container-text">{d.link_url}</p>
				</div>
			</div>)
		}
		)
	}
	return (
		<div>
			{loading ?
			<React.Fragment>
			<div className="policy-header">
				<h2 className="policy-text">{alldata.menu_title}</h2>
				<Container className="policy-subtext">
					<div className="policy-icon">
						<i className="fa fa-home" aria-hidden="true"></i>
					</div>
					<div>
						<Link to="" className="policy-subtext-link1">
							Home
						</Link>
					</div>
					<div className="policy-icon">
						<i className="fa fa-angle-right" aria-hidden="true"></i>
					</div>

					<div>
						<Link to="" className="policy-subtext-link2">
							{alldata.menu_slug}
						</Link>
					</div>
				</Container>
			</div>
			<div>
				<Container className="policy-container">
					<Row>
						<Col sm={4}>
							{/* <div>
								<h6 className="policy-container-text-h">Personal Information</h6>
							</div>
							<div>
								<h6 className="policy-container-text-h">Snap</h6>
							</div>
							<div>
								<h6 className="policy-container-text-h">Other Information</h6>
							</div>
							<div>
								<h6 className="policy-container-text-h">Links</h6>
							</div>
							<div>
								<h6 className="policy-container-text-h">Third Party Advertisers</h6>
							</div>
							<div>
								<h6 className="policy-container-text-h">Security</h6>
							</div> */}

							{renderList()}

						</Col>

						<Col sm={8}>
							{AllrenderList()}
							{/* <div>
								<h5 className="policy-container-text-h">Personal Information</h5>
								<p className="policy-container-text">
									“BoroBazar” is a trademark of BoroBazar Private
									Limited (“Company”), a company incorporated under the
									Companies Act, 2013 with its registered and corporate
									office at Plot 64H, Sector 18, Gudgeon 122001 in the
									course of its business. The domain name grocers.com
									is owned by the Company.
								</p>
								<p className="policy-container-text">
									If you are a California resident, the information
									below also applies to you. Certain terms used in this
									section have the meanings given to them in the
									California Consumer Privacy Act of 2018 (“CCPA”).
								</p>
							</div>

							<div>
								<h5 className="policy-container-text-h">Snap</h5>
								<p className="policy-container-text">
									Customer are advised to read and understand our
									Privacy Policy carefully, as by accessing the
									website/app you agree to be bound by the terms and
									conditions of the Privacy Policy and consent to the
									collection, storage and use of information relating
									to you as provided herein.
								</p>
								<p className="policy-container-text">
									If you do not agree with the terms and conditions of
									our Privacy Policy, including in relation to the
									manner of collection or use of your information,
									please do not use or access the website/app.
								</p>
								<p className="policy-container-text">
									Our Privacy Policy is incorporated into the Terms and
									Conditions of Use of the website/app, and is subject
									to change from time to time without notice. It is
									strongly recommended that you periodically review our
									Privacy Policy as posted on the App/Web.
								</p>
							</div>

							<div>
								<h5 className="policy-container-text-h">Other Information</h5>
								<p className="policy-container-text">
									We may automatically track certain information about
									you based upon your behavior on the website. We use
									this information to do internal research on our
									users’ demographics, interests, and behavior to
									better understand, protect and serve our users. This
									information is compiled and analyzed on an aggregated
									basis. This information may include the URL that you
									just came from (whether this URL is on the website or
									not), which URL you next go to (whether this URL is
									on the website or not), your computer browser
									information, your IP address, and other information
									associated with your interaction with the website.
									<strong>
										We may also share your Mobile IP/Device IP with
										third party(ies) and to the best of our knowledge,
										be-life and representations given to us by these
										third party(ies) this information is not stored.
									</strong>
								</p>
							</div>

							<div>
								<h5 className="policy-container-text-h">Links</h5>
								<p className="policy-container-text">
									We use this information to do internal research on
									our users’ demographics, interests, and behavior to
									better understand, protect and serve our users. This
									information is compiled and analyzed on an aggregated
									basis. This information may include the URL that you
									just came from (whether this URL is on the website or
									not), which URL you next go to (whether this URL is
									on the website or not), your computer browser
									information, your IP address
								</p>
								<ol type="1">
									<li className="policy-container-text">
										Identifiers (e.g. name, mailing address, email
										address, phone number, credit/debit card number)
									</li>
									<li className="policy-container-text">
										Characteristics of protected classNameifications (e.g.
										gender, age)
									</li>
									<li className="policy-container-text">
										Commercial information (e.g. products or services
										purchased, purchase history)
									</li>
									<li className="policy-container-text">
										Internet or other electronic network activity
										(e.g. browse or search history)
									</li>
									<li className="policy-container-text">
										Geo location data (e.g. latitude or longitude)
									</li>
									<li className="policy-container-text">
										Audio, electronic, visual, or similar information
										(e.g. recording of Guest service calls)
									</li>
									<li className="policy-container-text">
										Inferences drawn from any of the above (e.g.
										preferences or characteristics)
									</li>
								</ol>
							</div>

							<div>
								<h5 className="policy-container-text-h">Third Party Advertisers</h5>
								<p className="policy-container-text">
									To protect against the loss, misuse and alteration of
									the information under its control, the Company has in
									place appropriate physical, electronic and managerial
									procedures. For example, the Company servers are
									accessible only to authorized personnel and your
									information is shared with employees and authorized
									personnel on a need to know basis to complete the
									transaction and to provide the services requested by
									you. Although the Company endeavour to safeguard the
									confidentiality of your personally identifiable
									information, transmissions made by means of the
									Internet cannot be made absolutely secure. By using
									the website, you agree that the Company will have no
									liability for disclosure of your information due to
									errors in transmission and/or unauthorized acts of
									third parties.
								</p>
							</div>

							<div>
								<h5 className="policy-container-text-h">Security</h5>
								<p className="policy-container-text">
									Please note that the Company will not ask you to
									share any sensitive data or information via email or
									telephone. If you receive any such request by email
									or telephone, please do not respond/divulge any
									sensitive data or information and forward the
									information relating to the same to
									info@borobazar.com for necessary action.
								</p>
								<ol type="1">
									<li className="policy-container-text">
										Performing services, including maintaining or
										servicing accounts, providing customer service,
										processing or fulfilling orders and transactions,
										verifying customer information, processing
										payments, providing advertising or marketing
										services, providing analytics services, or
										providing similar services;
									</li>
									<li className="policy-container-text">
										Auditing related to a current interaction with you
										and concurrent transactions, including, but not
										limited to, counting ad impressions to unique
										visitors, verifying positioning and quality of ad
										impressions, and auditing compliance;
									</li>
									<li className="policy-container-text">
										Short-term, transient use, including, but not
										limited to, the contextual customization of ads
										shown as part of the same interaction;
									</li>
									<li className="policy-container-text">
										Detecting security incidents, protecting against
										malicious, deceptive, fraudulent, or illegal
										activity, and prosecuting those responsible for
										that activity;
									</li>
									<li className="policy-container-text">
										Debugging to identify and repair errors that
										impair existing intended functionality;
									</li>
									<li className="policy-container-text">
										Undertaking internal research for technological
										development and demonstration; and
									</li>
									<li className="policy-container-text">
										Undertaking activities to verify or maintain the
										quality or safety of a service or device that is
										owned, manufactured, manufactured for, or
										controlled by us, and to improve, upgrade, or
										enhance the service or device that is owned,
										manufactured, manufactured for, or controlled by
										us.
									</li>
								</ol>
							</div> */}
						</Col>
					</Row>
				</Container>
			</div>
			</React.Fragment>
			: 
			<Spinner animation="border" />
			}
		</div>
	);
}

export default Policy;
