import React from "react";
import AppContext from "./AppContext";

export default class GlobalState extends React.Component {
	constructor(props) {
		super(props);

		this.setCustomerName = (data) => this.setState({ customerName: data });
		this.setCustomerMobile = (data) => this.setState({ customerMobile: data });
		this.setCustomerFirstName = (data) => this.setState({ customerFirstName: data });
		this.setCustomerLastName = (data) => this.setState({ customerLastName: data });
		
		this.setCustomerSigninMobile = (data) => this.setState({ customerSigninMobile: data });


		this.setCustomerSigninDetail = (data) => this.setState({ customerSigninDetail: data });
		this.setCustomerSignupDetail = (data) => this.setState({ customerSignupDetail: data });

		this.setCustomerDetail = (data) => this.setState({ customerDetail: data });

		this.setCustomerCode = (data) => this.setState({ customerCode: data });
		

	

		this.state = {
			customerName: props.persistcustomerName,	
			setCustomerName: this.setCustomerName,

			customerMobile: props.persistcustomerMobile,	
			setCustomerMobile: this.setCustomerMobile,

			customerSigninMobile: props.persistcustomerSigninMobile,	
			setCustomerSigninMobile: this.setCustomerSigninMobile,

			customerFirstName: props.persistcustomerFirstName,
			setCustomerFirstName: this.setCustomerFirstName,
			
			customerLastName: props.persistcustomerLastName,
			setCustomerLastName: this.setCustomerLastName,

			customerSigninDetail: props.persistcustomerSigninDetail,
			setCustomerSigninDetail: this.setCustomerSigninDetail,

			customerSignupDetail: props.persistcustomerSignupDetail,
			setCustomerSignupDetail: this.setCustomerSignupDetail,

			customerDetail: props.persistcustomerDetail,
			setCustomerDetail: this.setCustomerDetail,

			customerCode: props.persistcustomerCode,
			setCustomerCode: this.setCustomerCode,
		};
	}

	render = () => (
		<AppContext.Provider value={this.state}>
			{this.props.children}
		</AppContext.Provider>
	);
}