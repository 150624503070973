import { AlarmTwoTone } from '@mui/icons-material';
import React,{useState,useEffect} from 'react'

function New() {
    // const getLocalItem=()=>{
    //     let list = localStorage.getItem('lists');
    //     console.log(list);
    //     if(list){
    //         console.log(JSON.parse(localStorage.getItem('lists')));
    //         return JSON.parse(localStorage.getItem('lists'));
    //     }else{
    //         return[]
    //     }
    // };

    // const AddressText=()=>{
    //     const [inputData,setInpitData]=useState('');
    //     const [item,setItem]=useState(getLocalItem());
    //     const [toogleSubmit,setToogleSubmit]=useState(true);
    //     const [isEditItem,setIsEditItem]=useState(null);

    //     const addItem=()=>{
    //         if(!inputData){
    //             alert("plz fill the data")
    //         }else if(inputData & !toogleSubmit){

    //         }
    //     }
    // }



const [addresstitle,setAddressTitle]=useState('')
const [address,setAddress]=useState('')

const handleSubmit =(e)=>{
    e.preventDefault();
    if (addresstitle === ""){
        alert("required")
    }else if(address === ""){
    alert ("required")
    }else{
        localStorage.setItem('addresstitle',addresstitle)
        localStorage.setItem('address',address)
    }
};


  return (
    <div>
        <form onSubmit={handleSubmit}>
            <label>Address-Title</label>
            <input 
            type='text'
            value={addresstitle}
            name='addresstitle'
            onChange={e => setAddressTitle(e.target.value)}
            /><br/><br/>



            <label>Address</label>
            <textarea 
            type='text'
            value={address}
            name='address'
            onChange={e => setAddress(e.target.value)}
            /><br/><br/>
            <button type='submit' >Submit</button>
        </form>
    </div>
  )
}

export default New