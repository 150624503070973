import React, { useState, useEffect } from "react";
import "./Footer.css";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";

import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SendIcon from "@mui/icons-material/Send";
import { Container,Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Config } from "../../Config/Config";
import axios from "axios";
import {
  getData,
  getLocalStorage
} from "../DataFromLocalStorage/LocalstorageData";

function Footer() {
  const [footdetail, setFootdetail] = useState({
    footdetails: [],
  });
  const [subfootdetail, setSubFootdetail] = useState([]);
  const [footcopyright, setFootCopyright] = useState("");
  const[copyloader,setCopyloader]=useState(false);
  const [footsocial, setFootSocial] = useState("");
  const [loading, setLoading] = useState(false);
  const [footercat, setFootercat] = useState([]);

  const [mastercardlogo, setMastercardlogo] = useState([]);
  const [visalogo, setVisalogo] = useState([]);
  const [rupaylogo, setRupaylogo] = useState([]);
  const [upilogo, setUpilogo] = useState([]);
  const [footerimg, setFooterimg] = useState([]);
  const [applestore, setApplestore] = useState([]);
  const [playstore, setPlaystore] = useState([]);

  const [aboutus, setAboutus] = useState([]);
  const [categories, setcategories] = useState([]);
  const [contact_us, setContact_us] = useState([]);
  const [faq, setFaq] = useState([]);
  const [privacy, setPrivacy] = useState([]);
  const [terms, setTerms] = useState([]);
  const [our_info, setOur_info] = useState([]);
  const [sub, setSub] = useState([]);
 
  useEffect((e) => {
    fetch_footerimg();
    fetch_mastercardlogo();
    fetch_visalogo();
    fetch_rupaylogo();
    fetch_upilogo();
    fetch_apple_store();
    fetch_play_store();

    fetch_privacy();
    fetch_about();
    fetch_terms();
    fetch_faq();
    fetch_category();
    fetch_contact();
    fetch_our_info();
    fetch_sub();
  }, []);

  const fetch_footerimg = async () => {
    const res = await getData(`${Config.FOOTER_IMG}`);
    if (res) {
      setFooterimg(res);
      // console.log("mastercard image",res)
    }
  }
  const fetch_mastercardlogo = async () => {
    const res = await getData(`${Config.MASTERCARD}`);
    if (res) {
      setMastercardlogo(res);
      // console.log("mastercard image",res)
    }
  }
  const fetch_visalogo = async () => {
    const res = await getData(`${Config.VISACARD}`);
    if (res) {
      setVisalogo(res);
      // console.log("visacard image",res)
    }
  }
  const fetch_rupaylogo = async () => {
    const res = await getData(`${Config.RUPAYCARD}`);
    if (res) {
      setRupaylogo(res);
      // console.log("rupaycard image",res)
    }
  }
  const fetch_upilogo = async () => {
    const res = await getData(`${Config.UPI}`);
    if (res) {
      setUpilogo(res);
      // console.log("Upicard image",res)
    }
  }
  const fetch_apple_store = async () => {
    const res = await getData(`${Config.FOOTER_APPLE_STORE}`);
    if (res) {
      setApplestore(res);
      // console.log("fetch_play_store image",res)
    }
  }
  const fetch_play_store = async () => {
    const res = await getData(`${Config.FOOTER_PLAY_STORE}`);
    if (res) {
      setPlaystore(res);
      // console.log("fetch_play_store image",res)
    }
  }

  const fetch_about = async () => {
    const res = await getData(`${Config.GET_ABOUT_DETAIL}`);
    if (res) {
      setAboutus(res);
      console.log("fetch_about",res)
    }
  }
  const fetch_terms= async () => {
    const res = await getData(`${Config.GET_TERMS_DETAIL}`);
    if (res) {
      setTerms(res);
      // console.log("fetch_terms",res)
    }
  }
  const fetch_faq= async () => {
    const res = await getData(`${Config.GET_FAQ_DETAIL}`);
    if (res) {
      setFaq(res);
      // console.log("fetch_faq",res)
    }
  }
  const fetch_category= async () => {
    const res = await getData(`${Config.GET_CATEGORIES_DETAIL}`);
    if (res) {
      setcategories(res);
      // console.log("fetch_category",res)
    }
  }
  const fetch_contact= async () => {
    const res = await getData(`${Config.GET_CONTACT_DETAIL}`);
    if (res) {
      setContact_us(res);
      // console.log("fetch_contact",res)
    }
  }
  const fetch_privacy= async () => {
    const res = await getData(`${Config.GET_PRIVACY_DETAIL}`);
    if (res) {
      setPrivacy(res);
      console.log("fetch_privacy details",res)
    }
  }
  const fetch_our_info= async () => {
    const res = await getData(`${Config.GET_OUR_INFO_DETAIL}`);
    if (res) {
      setOur_info(res);
      // console.log("fetch_our_info",res)
    }
  }
  
  const fetch_sub= async () => {
    const res = await getData(`${Config.GET_SUBSCRIBE_DETAIL}`);
    if (res) {
      setSub(res);
      // console.log("fetch_sub",res)
    }
  }

  useEffect(() => {
    axios
      .get(`${Config.FOOTER_DETAILS}`)
      .then((res) => {
        // console.log("axios responce", res.data);
        // console.log("..........", res.data.categories);
        setFootdetail({ footdetails: res.data });
        setFootercat(res.data.categories)
      })
      .catch((err) => {
        // console.log("Axios error", err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${Config.FOOTER_SUBDETAILS}`)
      .then((res) => {
        // console.log("axios responce", res.data);
        setSubFootdetail(res.data);
      })
      .catch((err) => {
        // console.log("Axios error", err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${Config.FOOTER_COPYRIGHT}`)
      .then((res) => {
        // console.log("axios responce", res.data);
        setFootCopyright(res.data);
        setCopyloader(true);
      })
      .catch((err) => {
        // console.log("Axios error", err);
        setCopyloader(true);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${Config.FOOTER_SOCIAL}`)
      .then((res) => {
        // console.log("axios responce", res.data);
        setFootSocial(res.data);
        setLoading(true);
      })
      .catch((err) => {
        // console.log("Axios error", err);
        setLoading(true);
      });
  }, []);

  return (
    <div>
      <footer>
        <div className="footer-row">
          <Container>
            <div className="col-md-8 col-lg-8 col-8 downlaod-left">
              {/* <div
                dangerouslySetInnerHTML={{ __html: subfootdetail.content }}
              /> */}

              {subfootdetail.map((k, index) => {
                return (
                  <React.Fragment key={index}>
                    <div dangerouslySetInnerHTML={{ __html: k.content }} />
                  </React.Fragment>
                );
              })}

              {/* <h1>Make your online shop easier with our mobile app</h1><br />
                            <p className="downlaod-left-p">BoroBazar makes online grocery shopping fast and easy. Get groceries delivered and order the best of seasonal farm fresh food.</p><br /> */}
              <button className="social-1">
                <img
                  className="social-btn"
                  // src="../../../../image/app-store.webp"
                  src={applestore}
                  href="#"
                />
              </button>
              <button className="social-2">
                <img
                  className="social-btn"
                  // src="../../../../image/play-store.webp"
                  src={playstore}
                  href="#"
                />
              </button>
            </div>
          </Container>
          <div className="col-md-4 col-lg-4 col-4">
            <img
              className="download-banner"
              // src="../../../../image/app-thumbnail.webp"
              src={footerimg}
            />
          </div>
        </div>

        <div className=" footer-main">
          <div className="footer-main-logo">
            <Link to="/home">
              <img src={footdetail.footdetails.footer_logo} className="footer_logo" />
            </Link>
            <div className="footer-p">
             {/* <p> We offers high-quality foods and the best delivery service, and
              the food market you can blindly trust</p> */}
             <div dangerouslySetInnerHTML={{__html: footdetail.footdetails.about_us}} />
            </div>
            {loading ?
            <div className="footer_social">
              {footsocial.map((f) => {
                return (
                  <React.Fragment key={f.id}>
                    <a href={f.social_url} target="_blank" className="footer_social_logo">
                      <i className={f.icon}></i>
                    </a>
                  </React.Fragment>
                );
              })}
            </div>
          :  <Spinner animation="border" />}
          </div>
          <div className=" footer-links">
            <h6 className="mt-lg-0 footer-links-h ">{aboutus.menu_title}</h6>
            <ul className="m-0 p-0 footer-sublink" type="none">
              <li>
                <a href="/about" className="footer-a">
                {aboutus.menu_title}
                </a>
              </li>
              <li>
                <a href="/contact" className="footer-a">
                  {contact_us.menu_title}
                </a>
              </li>
              <li>
                <a href="/terms" className="footer-a">
                  {terms.menu_title}
                </a>
              </li>
              
            </ul>
          </div>
          <div className=" footer-links">
            <h6 className="mt-lg-0 footer-links-h ">{our_info.menu_title}</h6>
            <ul className="m-0 p-0 footer-sublink" type="none">
              <li>
                <a href="/policy" className="footer-a">
                  {privacy.menu_title}
                </a>
              </li>
              <li>
                <a href="/terms" className="footer-a">
                {terms.menu_title}
                </a>
              </li>
              {/* <li>
                <a href="/" className="footer-a">
                  Return Policy
                </a>
              </li>
              <li>
                <a href="/" className="footer-a">
                  Site Map
                </a>
              </li> */}
            </ul>
          </div>
          <div className=" footer-links">
            <h6 className="mt-lg-0 footer-links-h ">{categories.menu_title}</h6>
            <ul className="m-0 p-0 footer-sublink" type="none">
            {footercat.map((prod)=>{
              return(
                <React.Fragment key={prod.id}>
                  <li>
                <Link to={`/${prod.name}`} className="footer-a">
                  {prod.name}
                </Link>
              </li>
                </React.Fragment>
              )
            })
          }
              {/* <li>
                <a href="/" className="footer-a">
                  Announcements
                </a>
              </li>
              <li>
                <a href="/" className="footer-a">
                  Answer center
                </a>
              </li>
              <li>
                <a href="/" className="footer-a">
                  Discussion boards
                </a>
              </li>
              <li>
                <a href="/" className="footer-a">
                  Giving works
                </a>
              </li> */}
            </ul>
          </div>

          <div>
            <h6>{sub.menu_title}</h6>
            <p className="footer-p">
              Subscribe your email for newsletter and featured news based on
              your interest
              {/* {sub.value.link_url} */}
            </p>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              className="footer-email"
            >
              <FormControl>
                <Input
                  id="outlined-basic"
                  placeholder="Write your email here"
                  startAdornment={
                    <InputAdornment position="start">
                      <EmailOutlinedIcon className="footer-email-icon" />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <SendIcon className="footer-send-icon" />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </div>
        </div>

        <div className=" footer-copy">
          <div className=" footer-copy-text">
              {copyloader ?
            <p>
              {/* © Copyright 2022 Desun Technology PVT LTD. All rights reserved */}
              {footcopyright.map((g,index) => (
                  <React.Fragment key={index}>
                <div dangerouslySetInnerHTML={{ __html: g.content }} />
                </React.Fragment>
              )
              )}

            </p>
            :
            <Spinner animation="border" />}
          </div>
          <div className="footer-paylogo">
            <img
              src={mastercardlogo}
              alt="MasterCard_Logo"
              className="footer-logo"
            />
            <img
              src={visalogo}
              alt="VisaCard_Logo"
              className="footer-logo"
            />
            <img
              src={rupaylogo}
              alt="Rupay_Logo"
              className="footer-logo"
            />
            <img
              src={upilogo}
              alt="JCB_Logo"
              className="footer-logo"
            />         
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
