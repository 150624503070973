import React, { useState, useEffect } from "react";
import { Container, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Faq.css";
import axios from "axios";
import { Config } from "../../Config/Config";

function Faq() {


	const [alldata, setAllData] = useState([])

	useEffect(() => {
		axios.get(`${Config.GET_FAQ_DETAIL}`)
			.then(res => {
				console.log("axiois data", res.data);
				setAllData(res.data);
			})
			.catch(err => {
				console.log("axios error", err);
			})
	}, [])


	const renderList = () => {
		
		if(alldata.length == 0){
			return null;
		}

		return alldata.value.map((d, index) => {
			console.warn("inside", alldata)
			return (<div key={index}>

<Accordion defaultActiveKey={['0']} alwaysOpen>
							<Accordion.Item eventKey={d.index}>
								<Accordion.Header> 

				<p>{d.link_name}</p>

				</Accordion.Header>
								<Accordion.Body className="accordions-body"> 
									<p>{d.link_url}</p>
								</Accordion.Body>
							</Accordion.Item><br />
							</Accordion>
			</div>)
		}
		) 
	}

	return (
		<div>
			<div className="faq-header">
				<h2 className="faq-text">{alldata.menu_title}</h2>
				<Container className="faq-subtext">
					<div className="faq-icon">
						<i className="fa fa-home" aria-hidden="true"></i>
					</div>
					<div>
						<Link to="" className="faq-subtext-link1">
							Home
						</Link>
					</div>
					<div className="faq-icon">
						<i className="fa fa-angle-right" aria-hidden="true"></i>
					</div>

					<div>
						<Link to="" className="faq-subtext-link2">
							{alldata.menu_slug}
						</Link>
					</div>
				</Container>
			</div>

			<Container className="accordions">

				{renderList()}
						
			</Container>
		</div>
	);
}

export default Faq;
