import React from 'react'
import {Route,Routes,BrowserRouter as Router} from 'react-router-dom'
import Notfound from '../Components/404/Notfound'
import Aboutus from '../Components/Aboutus/Aboutus'
import Cart from '../Components/Cart/Cart'
import Category from '../Components/Category/Category'
import Checkout from '../Components/Checkout/Checkout'
import Contact from '../Components/contact/contact'
import AllDealofday from '../Components/DealofDay/AllDealofday'
import Faq from '../Components/Faq/Faq'
import Footer from '../Components/Footer/Footer.js'
import Header from '../Components/Header/Header'
import HomeCards from '../Components/Home-caro/Cards'

import Demo2 from '../Components/Home-caro/demo2'
import HomeTag from '../Components/Home-tag/Tag'
import Home from '../Components/Home/Home'
import AllLatestColl from '../Components/Latest_Collection/AllLatestColl'
import Accountsettings from '../Components/MyAccount/Accountsettings/Accountsettings'
import AddingAddress from '../Components/MyAccount/Address/AddingAddress'
import Address from '../Components/MyAccount/Address/Address'
import EditAddress from '../Components/MyAccount/Address/EditAddress'
import ChangePass from '../Components/MyAccount/ChangePass/ChangePass'
import Myaccount from '../Components/MyAccount/Myaccount'
import Myorder from '../Components/Myorder/Myorder'
import New from '../Components/new/New'

// import Mynav from '../Components/Navbar'

import Policy from '../Components/policy/Policy'
import ProductDetail from '../Components/ProductDetail/ProductDetail'
import Products from '../Components/Products/products'
import RegOtp from '../Components/RegOtp/RegOtp'
import SignIn from '../Components/SignIn/SignIn'
import SigninOtp from '../Components/SigninOtp/SigninOtp'
import SignUp from '../Components/SignUp/SignUp'
import Terms from '../Components/Terms/Terms'
import Subwishlist from '../Components/Wishlist/SubWishlist'


import Wishlist from '../Components/Wishlist/Wishlist'
import ProtectedRoutes from './ProtectedRoutes'


export const RootRoutes = () => {
  return (
      <Router>
          {/* <Mynav/> */}
          <Header />
          <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/home' element={<Home />} />
              <Route path='signin' element={<SignIn />} />
        <Route path='signup' element={<SignUp />} />
        <Route path='demo2' element={<Demo2 />} />
        <Route path='regotp' element={<RegOtp />} />
        <Route path='signinotp' element={<SigninOtp />} />
        <Route path='about' element={<Aboutus />} />
        <Route path='faq' element={<Faq />} />
        <Route path='policy' element={<Policy/>}/>
        <Route path='terms' element={<Terms/>}/>
        
        <Route path='footer' element={<Footer/>}/>
        <Route path='header' element={<Header/>}/>
        <Route path='card' element={<HomeCards/>}/>
        <Route path='tag' element={<HomeTag/>}/>
        <Route path='contact' element={<Contact/>}/>
        {/* <Route path='myaccount' element={<Myaccount/>}/> */}


        <Route path='myaccount' element={<Accountsettings/>}/>
        {/* <Route path='myaccount/:acc-setting' element={<Accountsettings/>}/> */}
        <Route path='myaccount/:acc-changepass' element={<ChangePass/>}/>
        <Route path='myaccount/:acc-address' element={<Address/>}/>


        <Route path='add' element={<AddingAddress/>}/>
        <Route path='editadd/:addid' element={<EditAddress/>}/>
        <Route path='new' element={<New/>}/>
        <Route path='products' element={<Products/>}/>

        <Route path='products' element={<Products/>}/>

        <Route path='alllatestColl' element={<AllLatestColl/>}/>

        <Route path='allDealofday' element={<AllDealofday/>}/>

        <Route path='/:c_name/' element={<Category/>}/>


        <Route path='/:pslug/:pid' element={<ProductDetail/>}/>

        <Route path='myaccount/wishlist' element={<Wishlist/>}/>
        <Route path='myaccount/wishlist/:wname' element={<Subwishlist/>}/>


        <Route element={<ProtectedRoutes/>}>
        <Route path='cart' element={<Cart/>}/>
        <Route path='checkout' element={<Checkout/>}/>
        </Route>
        
        <Route path='myorder' element={<Myorder/>}/>

        <Route path='*' element={<Notfound/>}/>
      

        
        
      
        
        </Routes>
        <Footer />
    </Router>
  )
}
