import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import './contact.css'
import { Link } from "react-router-dom";
// import Header from '../header'
// import Newfooter from '../newfooter';

const Contact = () => {
    return (
        <>
            {/* <Header /> */}
            <div className='contact-head' >
                <div className="contact-head-text">
                    <h1 >Do you need support? Our team is ready to help</h1>
                    <p className='contact-head-p' >We are passionate about building carefully thought out products that will improve your design workflow.</p>
                </div>
            </div>


         
                <div className='contact-form'>
                
                    <Row>
                        <Col sm={6} >
                            <h4 className="contact-form-h">Support is our main priority</h4>
                            <p>We created reusable react components, and modern mono repo architecture, so you can build multiple apps with common components. You can use these landing for your react app. Its super easy to deploy, we have provided complete firebase integration it.</p>
                            <div className="contact-head-people">
                        <div className="contact-head-img"><img src='../../../image/people1.webp'/></div>
                        <div className="contact-head-img"><img src='../../../image/people2.webp'/> </div>
                        <div className="contact-head-img"><img src='../../../image/people3.webp'/></div>
                        <div className="contact-head-img"><img src='../../../image/people4.png'/></div>
                    </div>
                        </Col>
                        <Col></Col>
                        <Col sm={5}>
                            <Form className="contact-form-detail">
                                <label className="contact-label">Full Name(requared) </label>
                                <br />
                                <input type="text" className="contact-form-input" placeholder='Enter Your Full Name' /><br />
                                <label className="contact-label">Email Address(requared)  </label>
                                <br />
                                <input type="text" className="contact-form-input" placeholder='Enter Your Email' /><br />
                                <label className="contact-label">Phone(Optional) </label>
                                <br />
                                <input type="text" className="contact-form-input" placeholder='Enter Your Phone' /><br />
                                <label className="contact-label">Message</label>
                                <br />
                                <textarea rows="4" cols="50" placeholder='Briefly Describe...' className="contact-form-textarea" /><br />

                                <button type="submit" className="contact-send-btn" href='/otp' >
                                    <Link to='/' className="contact-send-btn-link" >
                                        Send Message
                                    </Link>
                                </button>
                            </Form>
                        </Col>
                    </Row>
               
                </div>
    
<div className='contact-detail-main'>
    <Container>
        <Row>
            <Col className='contact-detail'>
            <div className='contact-detail-img'>
            <img src='../../../image/compass-icon.png'/>
            </div>
            <div>
            <h5>Office Location</h5>
            <p>2756 Quiet Valley Lane, Reseda, California, United Stats</p>
            </div>
            </Col>
            <Col className='contact-detail'>
            <div >
            <img src='../../../image/whatsapp-icon.png'  className='contact-detail-img-whatsapp'/>
            </div>
            <div>
            <h5>Call us anytime</h5>
            <p>Change the design through a range +89 5631 564 +88 5321 036</p>
            </div>
            </Col>
            <Col className='contact-detail'>
            <div >
            <img src='../../../image/at-sign.png' className='contact-detail-img-whatsapp'/>
            </div>
            <div>
            <h5>Send Mail</h5>
            <p>support@demoagency.com hire.us@demoteam.io</p>
            </div>
            </Col>
           
        </Row>
    </Container>
</div>
<div className='contact-map'>
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14746.495760889753!2d88.3918931!3d22.4807619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1652682751562!5m2!1sen!2sin"  height="450"   loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='contact-map'>
</iframe>
</div>
        </>
    )
}

export default Contact