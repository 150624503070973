import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./SignUp.css";
import axios from "axios";
import { useNavigate } from 'react-router-dom'
import AppContext from "../../context/AppContext";
import {Config} from '../../Config/Config'

function SignUp() {

	const navigate = useNavigate();

	//--getting AppContext data--

	const context = useContext(AppContext);



	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	// const handleShow = () => setShow(true);

	const handlenav = () => navigate('/signin')

	const [wrongnumber, setWrongnumber] = useState(false);
	const validateMobile = RegExp('^([6-9]{1})([0-9]{9})$');
	
	const [loader, setLoader] = useState(false);

	const [inputState, setInputState] = useState({
		isError: {
			full_name: '', mobile: ''
		}

	})

	const handleChange = (e) => {
		//console.log("Context", context)
		e.persist();
		console.log("Event", e);

		//-- form validations --

		let { name, value } = e.target;
		let err = { ...inputState.isError };

		switch (name) {
			case 'full_name': err.full_name = value.length < 3 ? "Atleast 3 characters required" : "";
				break;

			case 'mobile': err.mobile = validateMobile.test(value) ? " " : "Invalid Mobile Number"
				break;
			default:
				break;
		}


		setInputState({ ...inputState, [name]: value, isError: err });
		console.log('input value', inputState);

	}
	const submitHandle = (e) => {
		e.preventDefault();
		console.log("after submit", inputState);

		setLoader(true)

		//--post form data to api--

		let full_name = inputState.full_name
		let splitFull_name = full_name.split(" ");
		let first_name = splitFull_name[0];
		let last_name = splitFull_name[1];

		context.setCustomerMobile(inputState.mobile);
		context.setCustomerName(inputState.full_name);
		context.setCustomerFirstName(first_name);
		context.setCustomerLastName(last_name);

		let formData = new FormData()

		formData.append("first_name", first_name)
		formData.append("last_name", last_name)
		formData.append("mobile", inputState.mobile)

		axios.post(`${Config.USER_SIGNUP}`, formData)
			.then(res => {
				console.log("axios responce", res.data);
				if (res.data.is_success === "success") {
					navigate('/regotp')

					setLoader(false)

				} else if (res.data.is_success === "failure") {
			
					setWrongnumber(true)

					setLoader(false)

					setShow(true)
				}
			})
			.catch(err => { console.log("axios error", err) })


	}




	return (

		<div className="signup">

			<div>
				<img src="../../../image/signup.webp" className="signup-img" />
			</div>

			<div className="signup-form">
				<Form

					onSubmit={submitHandle}
				>
					<div>
						<img src="../../../image/logo.png" className="signup-logo" />
						<h3 className="signup-h">SignUp for free!</h3>
						<p className="signup-text">
							Already Registered?
							<Link to="/signin" className="signup-link-signin">Sign in Now</Link>
						</p>
					</div>
					<div>
						<label className="signup-text">Full Name*</label>
						<br />
						<input
							type="text"
							className="signup-form-input"
							name="full_name"
							onChange={handleChange}
						/>
						{inputState.isError.full_name.length > 0 && (<span className="signup-input-error">{inputState.isError.full_name}</span>)}<br/>

						<label className="signup-text">Mobile</label>
						{wrongnumber ?
							<input
								type="text"
								className="signup-form-input-wrong"
								name="mobile"
								onChange={handleChange}

							/>
							:
							<input
								type="text"
								className="signup-form-input"
								name="mobile"
								onChange={handleChange}

							/>

						}
						{inputState.isError.mobile.length > 0 && (<span className="signup-input-error">{inputState.isError.mobile}</span>)}
					</div>
					<div className="signup-check-box">
						<div className="container slide">
							<label className="switch" >
								<input type="checkbox" id="checkbox" />
								<div className="slider round"></div>
							</label>
							<p className="slide-p signin-text">Remember me</p>
						</div>
						<div className="signup-policy">
							<Link to=" " className="signup-link-policy">Privacy and policy</Link>
						</div>
					</div>

					{loader ?

						<button
							type="submit"
							disabled
							className="signup-submit-loader"
						>
							<Spinner animation="border" />
						</button>

						:
						<button
							type="submit"
							className="signup-submit"
						>
							Register
						</button>
					}

					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							
						</Modal.Header>
						<Modal.Body>Mobile number is already exists. Please Signin With this number.</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Close
							</Button>
							<Button variant="primary" onClick={handlenav}>
				
							Sign In
						
							</Button>
						</Modal.Footer>
					</Modal>

				</Form>

			</div>
		</div>

	);
}

export default SignUp;
