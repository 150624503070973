import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Spinner, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./SignIn.css";
import axios from "axios";
import { useNavigate } from 'react-router-dom'
import AppContext from "../../context/AppContext";
import { Config } from '../../Config/Config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function SignIn() {


	const validateMobile = RegExp('^([6-9]{1})([0-9]{9})$');

	//--getting AppContext data--

	const context = useContext(AppContext);

	const navigate = useNavigate();

	//--initializing states--

	const [erralert, setErralert] = useState(false)

	const [wrongnumber, setWrongnumber] = useState(false);

	const [loader, setLoader] = useState(false);

	const [inputState, setInputState] = useState({
		isError: {
			mobile: ''
		}
	})


	const handleChange = (e) => {
		e.persist();
		console.log("Event", e);

		//--form validations--

		let { name, value } = e.target;
		let err = { ...inputState.isError };

		switch (name) {
			case 'mobile': err.mobile = validateMobile.test(value) ? " " : "Invalid Mobile Number"
				break;
			default:
				break;
		}

		setInputState({ ...inputState, [name]: value, isError: err });
		console.log('input value', inputState);

	}



	const submitHandle = (e) => {
		e.preventDefault();

		console.log("after submit", inputState);

		setLoader(true)

		context.setCustomerSigninMobile(inputState.mobile);

		//--post form data to api--

		let formData = new FormData()

		formData.append("mobile", inputState.mobile)

		axios.post(`${Config.USER_SIGNIN}`, formData)
			.then(res => {
				console.log("axios responce", res.data);
				if (res.data.is_success === "success") {
					navigate('/signinotp')

					setLoader(false)

				} else if (res.data.is_success === "failure") {

					setErralert(true)

					setWrongnumber(true)

					setLoader(false)
			
				}
			})
			.catch(err => { console.log("axios error", err) })
			
	}

	useEffect(()=>{
		if (erralert === true) {
			toast.error('Mobile number dose not exists.', {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored"
			});
		} 

	},[erralert])

	return (
		<div className="signin">
			<div>
				<img src="../../../image/signIn.webp" className="signin-img" />
			</div>
			<div className="signin-form">
				<Form onSubmit={submitHandle}>
					<img src="../../../image/logo.png" className="signin-logo" />
					<h3 className="signin-h">Welcome Back!</h3>
					<p className="signin-text">
						Don't have an account?
						<Link to="/signup" className="signin-link-create">
							Create Account
						</Link>
					</p>
					<label className="signin-text">Mobile</label>
					<br />

					{wrongnumber ?
						<input
							type="text"
							className="signin-form-input-wrong"
							name="mobile"
							onChange={handleChange}

						/>
						:

					<input
						type="text"
						name="mobile"
						className="signin-form-input"
						onChange={handleChange} />
					 } 
					{inputState.isError.mobile.length > 0 && (<span className="signin-input-error">{inputState.isError.mobile}</span>)}
					<div className="signin-check-box">

						<div className="flex-container slide">
							<label className="switch" >
								<input type="checkbox" id="checkbox" />
								<div className="slider round"></div>
							</label>
							<p className="slide-p signin-text">Remember me</p>
						</div>
						<div className="signin-forget-pass">
							<a href="/signup" className="signin-link-forget">
								Forget password?
							</a>
						</div>
					</div>



					{loader ?

						<button
							type="submit"
							disabled
							className="signin-submit-loader"
						>
							<Spinner animation="border" />
						</button>

						:
						<button type="submit" className="signin-submit" href='/otp' >

							Sign In

						</button>

					}
					{erralert ?
					<ToastContainer
						position="top-right"
						autoClose={2000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
					: null }
				</Form>
			</div>
		</div>
	);
}

export default SignIn;
