import React, { useState, useEffect, useContext } from 'react'
import { Form, Button } from "react-bootstrap";
import './EditAddress.css'
import { Config } from '../../../Config/Config';
import axios from "axios";
import AppContext from "../../../context/AppContext";
import { useNavigate, useParams } from 'react-router-dom';



const EditAddress = () => {
    const value = useParams();
    console.log("value of add", value)

    const address_id = (value.addid);

    const [alladdress, setAllAddress] = useState([])

    const [edituserdata, SetEditUserData] = useState(undefined);

    //--getting localstorage value--
    useEffect(() => {
        try {
            const arrayOfData = localStorage.getItem('CustomerData')
            const d = arrayOfData !== null ? JSON.parse(arrayOfData) : [];
            console.log('code data', d.data.cust_code)
            SetEditUserData(d.data);
        }
        catch (error) {
            console.log('is error', error);
        }
    }, [])


    const [inputfullName, setInputFullName] = useState(" ");
    const [inputstate, setInputstate] = useState("");
    const [inputcity, setInputcity] = useState('');
    const [inputmobile, setInputmobile] = useState("");
    const [inputpincode, setInputpincode] = useState("");
    const [inputadd_one, setInputadd_one] = useState("");
    const [inputadd_two, setInputadd_two] = useState("");
    const [inputlandmark, setInputlandmark] = useState("");
    let [input_states_id, setInput_States_id] = useState("");

    //getting all address data--

    useEffect(() => {

        console.warn(typeof edituserdata !== 'undefined')

        if (typeof edituserdata != 'undefined') {

            const addressdata = (Config.GET_ADDRESS + edituserdata.cust_code);

            console.log("inside", addressdata, edituserdata)
            var xhr = new XMLHttpRequest();
            xhr.withCredentials = true;

            xhr.addEventListener("readystatechange", function () {



                if (this.readyState === 4) {
                    console.log("data", this.responseText, typeof this.responseText);

                    setAllAddress(JSON.parse(this.responseText));
                    let editad = (JSON.parse(this.responseText) || []).find((addr) => addr.id === value.addid);
                    setInputcity(editad.city_id)
                    setInputstate(editad.state_id)
                    setInputadd_one(editad.address_one)
                    setInputadd_two(editad.address_two)
                    setInputmobile(editad.mobile)
                    setInputpincode(editad.pincode)
                    setInputFullName(`${editad.first_name} ${editad.last_name}`)
                    console.log("array data", alladdress, JSON.parse(this.responseText))
                }

            });

            xhr.open("POST", `${addressdata}`);
            xhr.setRequestHeader("Cache-Control", "no-cache");

            xhr.send();
        }

    }, [edituserdata])

    // let editad = (alladdress || []).find((addr) => addr.id === value.addid);
    // console.log('value edit data ', editad);





    const navigate = useNavigate();

    const [allstate, setAllState] = useState({
        allstates: []
    });
    const [allcity, setAllCity] = useState({
        allCitys: []
    });


    const handleName = (e) => {
        setInputFullName(...inputfullName,e.target.value);
    }
    const handleState = (e) => {
        console.log(e);
        setInput_States_id(e.target.value);

        {
            allstate.allstates.map((d) => {
                if (e.target.value == d.id) {
                    setInputstate(...inputstate,d.state_name)
                    console.log(d.state_name);
                }
            })
        }


        // console.log(input_states);
        
        let formData = new FormData()

        formData.append("state_id", input_states_id)
       
            axios.get(`${Config.GET_CITY}`, formData)
                .then(res => {
                    console.log("all city", res.data);
                    setAllCity({ allCitys: res.data })
                })
                .catch(err => {
console.log("error",err);
                })
    }

    const handleCity = (e) => {
        setInputcity(...inputcity,e.target.value);
    }
    const handleMobile = (e) => {
        console.log("mobile",e.target.value);
        setInputmobile(...inputmobile,e.target.value);
    }
    const handlePin = (e) => {
        console.log("pin",e.target.value);
        setInputpincode(...inputpincode.target.value);
    }
    const handleadd_one = (e) => {
        console.log("add1",e.target.value);
        setInputadd_one(...inputadd_one,e.target.value);
    }
    const handleadd_two = (e) => {
        console.log("add2",e.target.value);
        setInputadd_two(...inputadd_two,e.target.value);
    }
    const handlelandmark = (e) => {
        console.log("land",e.target.value);
        setInputlandmark(...inputlandmark,e.target.value);
    }

    const submitHandle = (e) => {
        e.preventDefault();
        console.log("after submit name",inputfullName);
        console.log('state',inputstate);
        console.log('city',inputcity);
        console.log("after submit mobile",inputmobile);
        console.log("after submit pin",inputpincode);
        console.log("after submit add1",inputadd_one);
        console.log("after submit add2 ",inputadd_two);
        console.log("after submit land " ,inputlandmark);
        console.log(address_id);
        console.log(edituserdata.access_token);
        console.log(edituserdata.cust_code);

        // --send form data to api--

        let full_name = inputfullName
        let splitFull_name = full_name.split(" ");
        let first_name = splitFull_name[0];
        let last_name = splitFull_name[1];



        let formData = new FormData()

        formData.append("cust_code", edituserdata.cust_code)
        formData.append("access_key", edituserdata.access_token)
        formData.append("id", address_id)
        formData.append("first_name", first_name)
        formData.append("last_name", last_name)
        formData.append("city", inputcity.city)
        formData.append("state", inputstate.state)
        formData.append("mobile", inputmobile.mobile)
        formData.append("pincode", inputpincode.pincode)
        formData.append("address_one", inputadd_one.address_one)
        formData.append("address_two", inputadd_two.address_two)
        formData.append("landmark", inputlandmark.landmark)

        console.log("form data", formData);

        axios.post(`${Config.EDIT_ADDRESS}`, formData)
            .then(res => {
                console.log("axios responce", res.data);
                alert('add done')

                navigate("/myaccount/:acc-address")
            })
            .catch(err => {
                console.log("axios error", err)
                alert('add error')
            })

    }



    //-- get allstates--

    useEffect(() => {
        axios.get(`${Config.GET_STATE}`)
            .then(res => {

                setAllState({ allstates: res.data })
            })
            .catch(err => {

            })
    }, [])

    // useEffect(() => {
    //     axios.get(`${Config.GET_CITY}`)
    //         .then(res => {
    //             console.log("all city", res.data);
    //             setAllCity({ allCitys: res.data })
    //         })
    //         .catch(err => {

    //         })
    // }, [])




    return (
        <div className='Add-address-main' >
            <Form
                className='Add-address-form'
                onSubmit={submitHandle}
            >

                <h4>Edit Delivery Address</h4>
                <label className="signup-text">Full Name*</label>
                <input
                    type="text"
                    className='editAddingaddress-input'
                    name="full_name"
                    value={inputfullName}
                    onChange={handleName}
                />


                <div className='editAdd-address-state_city'>
                    <div className='editAdd-address-state-div'>
                        <label className="signup-text">State</label>

                        <select
                            name='state'
                            className='editAddingaddress-input-select'
                            onChange={handleState}

                        >

                            <option >  {inputstate}  </option>
                            {/* --maping allstates-- */}
                            {

                                allstate.allstates.map((everystates) => {
                                    return (
                                        <React.Fragment key={everystates.id}>


                                            <option value={everystates.id}>{everystates.state_name}</option>

                                        </React.Fragment>
                                    )
                                }

                                )
                            }
                        </select>

                    </div>

                    <div className='editAdd-address-city-div'>
                        <label className="signup-text">City</label>
                  

                        <select
                            name='city'
                            className='editAddingaddress-input-select'
                            onChange={handleCity}
                            
                        >
                             <option>  {inputcity} </option> 

                            {/* --maping allstates-- */}
                           
                            {
                                allcity.allCitys.filter((xyz) => {

                                    if (xyz.state_id === input_states_id) {
                                        return xyz
                                    }
                                })
                                    .map((everycitys) => {
                                        return (
                                            <React.Fragment key={everycitys.id}>


                                                <option>{everycitys.city_name}</option>

                                            </React.Fragment>
                                        )
                                    }

                                    )



                            }
                        </select>

                    </div>

                </div>
                <label className="signup-text">Phone Number</label>
                <input
                    type="text"
                    className='editAddingaddress-input'
                    name="mobile"
                    value={inputmobile}
                    // placeholder={context.customerSigninMobile}
                    onChange={handleMobile}
                />

                <label className="signup-text">Pincode</label>
                <input
                    type="text"
                    className='editAddingaddress-input'
                    name="pincode"
                    value={inputpincode}
                    onChange={handlePin}
                />

                <label className="signup-text">House No.,Building Name</label>
                <input
                    type="text"
                    className='editAddingaddress-input'
                    name="address_one"
                    value={inputadd_one}
                    onChange={handleadd_one}
                />

                <label className="signup-text">Road Name,Area,Colony</label>
                <input
                    type="text"
                    className='editAddingaddress-input'
                    name="address_two"
                    value={inputadd_two}
                    onChange={handleadd_two}
                />

                <label className="signup-text">Add Nearby Location/Landmark</label>
                <input
                    type="text"
                    className='editAddingaddress-input'
                    name="landmark"
                    onChange={handlelandmark}
                />


                {/* {varifysubmit ? */}

                <button type="submit" className='editAddaddress-Btne' >
                    Save Address
                </button>
                {/* :  */}
                {/* <button 
                disabled
                type="submit" 
                className='editAddaddress-Btne-disable' >
                Save Address
            </button> */}
                {/* } */}
            </Form>
        </div>
    )
}

export default EditAddress