import React from 'react'
import './Myaccount.css'
import { Nav } from "react-bootstrap";
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom'

function Myaccount() {

    const navigate = useNavigate();

    const LoggingOut = () => {
        window.localStorage.clear();
        navigate("/")
    }

    return (
        <div>
            <div className='myacc-nav'>
                <Nav defaultActiveKey="/home" className="flex-column">
                    <Nav.Link href="/myaccount" className='myacc-nav-link'>
                        <AccountCircleOutlinedIcon className='myacc-icon' />
                        Account Settings
                    </Nav.Link>
                    <Nav.Link href="/myorder" className='myacc-nav-link'>
                        <ShoppingBagOutlinedIcon className='myacc-icon' />
                        Orders
                    </Nav.Link>
                    <Nav.Link href="/myaccount/wishlist" className='myacc-nav-link'>
                        <FavoriteBorderOutlinedIcon className='myacc-icon' />
                        Wishlist
                    </Nav.Link>
                    <Nav.Link href="/myaccount/:acc-address" className='myacc-nav-link'>
                
                        <FmdGoodOutlinedIcon className='myacc-icon' />
                        Address
                   
                    </Nav.Link>
                    <Nav.Link href="/myaccount/:acc-changepass" className='myacc-nav-link'>
                        <SettingsOutlinedIcon className='myacc-icon' />
                        Change Password
                    </Nav.Link>
                    <button className='myacc-nav-button-logout' onClick={LoggingOut}>
                        <LockOutlinedIcon className='myacc-icon' />
                        Logout
                    </button>

                </Nav>
            </div>
            <div>

            </div>
        </div>
    )
}

export default Myaccount