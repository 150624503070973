import React, { useState, useEffect } from "react";
import "./Cart.css";
import { Accordion } from "react-bootstrap";
// import OnlyAddress from '../MyAccount/Address/OnlyAddress';
// import prodData from '../products.json'
import { useParams, useNavigate, Link } from "react-router-dom";
import { Spinner, Modal, Button } from "react-bootstrap";
import { Config } from "../../Config/Config";
import {
  getData,
  getLocalStorage,
} from "../DataFromLocalStorage/LocalstorageData";
import axios from "axios";

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allcartdata: [],
      cartdata: [],
      custCode: "",
      access_token: "",
      loading: false,
      total_amount: "",
      inc_qunt: "",
      promo_detail: [],
      promo_icon: "",
      promo_code: "",
      allcoupon_code: "",
      valid_promo: "",
      valid_promo_input: false,
      promo_ids: [],
      descrip: true,
      show_promo: false,
      all_promo: [],
      promo_placeholder: "",
      add_txn:"",
    };
  }
  handleClose = () => this.setState({ show_promo: false });
  handleShow = () => {
    this.setState({ show_promo: true });

    axios
      .get(`${Config.GET_COUPON}`)
      .then((res) => {
        this.setState({
          all_promo: res.data,
        });
        console.log("all coupon", res.data);
      })
      .catch((err) => {});
  };

  componentDidMount() {
    const d = getLocalStorage("CustomerData");
    const custCode = d.data.cust_code;
    this.setState({ custCode: d.data.cust_code });
    this.setState({ access_token: d.data.access_token });

    var data = new FormData();
    data.append("cust_code", custCode);

    this.setState({ loading: true });
    axios
      .post(`${Config.GET_CART}`, data)
      .then((res) => {
        console.log(res.data);
        this.setState({
          allcartdata: res.data,
        });
        this.setState({
          cartdata: res.data.data,
        });
      })
      // this.props.navigation('/')
      .catch((err) => {})
      .finally(() => {
        this.setState({ loading: false });
      });
      this.setState({
        promo_placeholder : "Have a promocode?Enter here"
      })

    axios.get(`${Config.PROMO_ICON}`).then((res) => {
      console.log(res.data);
      this.setState({
        promo_icon: res.data,
      }).catch((err) => {});
    });
  }

  handleIncrement = (e) => {
    console.log("......eee.......", e);
    this.setState({
      valid_promo: "",
    });
    this.setState({
      valid_promo_input: false,
    });

    console.log("product data", e);
    console.log("qty", "1");
    console.log("cust_code", this.state.custCode);
    console.log("product id", `${e.varient_1},${e.varient_2}`);
    console.log("product code", e.product_code);
    console.log("product final_amount", e.final_amount);

    const productvar_id = `${e.varient_1},${e.varient_2}`;
    console.log("productvar_id.................>", productvar_id);

    let formData = new FormData();

    formData.append("cust_code", this.state.custCode);
    formData.append("product code", e.product_code);
    formData.append("varient", `${e.varient_1},${e.varient_2}`);
    formData.append("price", e.final_amount);
    formData.append("qty", "1");

    axios
      .post(`${Config.ADD_CART}`, formData)
      .then((res) => {
        console.log(".........>", res.data);
        if (res.data.check === "success") {
          var data = new FormData();
          data.append("cust_code", this.state.custCode);

          this.setState({ loading: true });
          axios
            .post(`${Config.GET_CART}`, data)
            .then((res) => {
              console.log(res.data);
              this.setState({
                allcartdata: res.data,
              });
              this.setState({
                cartdata: res.data.data,
              });
            })

            .catch((err) => {})
            .finally(() => {
              this.setState({ loading: false });
            });
        }
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleRemoveitem = (e) => {
    console.log("000000000..........?>", e);
    this.setState({
      valid_promo: "",
    });
    this.setState({
      valid_promo_input: false,
    });

    console.log("product data", e);
    console.log("cust_code", this.state.custCode);
    console.log("access_token", this.state.access_token);
    console.log("cart product id", e.id);

    let formData = new FormData();

    formData.append("cust_code", this.state.custCode);
    formData.append("access_key", this.state.access_token);
    formData.append("cart_id", e.id);
    formData.append("is_clear", "1");

    this.setState({ loading: true });

    axios.post(`${Config.REMOVE_CART_ITEM}`, formData).then((res) => {
      console.log(res.data);
      if (res.data.check === "success") {
        var data = new FormData();
        data.append("cust_code", this.state.custCode);

        this.setState({ loading: true });
        axios
          .post(`${Config.GET_CART}`, data)
          .then((res) => {
            console.log(res.data);
            this.setState({
              allcartdata: res.data,
            });
            this.setState({
              cartdata: res.data.data,
            });
          })

          .catch((err) => {})
          .finally(() => {
            this.setState({ loading: false });
          });
      }
    });
  };

  handleDecrementitem = (e) => {
    console.log("000000000..........?>", e);
    this.setState({
      valid_promo: "",
    });
    this.setState({
      valid_promo_input: false,
    });

    console.log("product data", e);
    console.log("cust_code", this.state.custCode);
    console.log("access_token", this.state.access_token);
    console.log("cart product id", e.id);

    let formData = new FormData();

    formData.append("cust_code", this.state.custCode);
    formData.append("access_key", this.state.access_token);
    formData.append("cart_id", e.id);

    this.setState({ loading: true });

    axios.post(`${Config.REMOVE_CART_ITEM}`, formData).then((res) => {
      console.log(res.data);
      if (res.data.check === "success") {
        var data = new FormData();
        data.append("cust_code", this.state.custCode);

        this.setState({ loading: true });
        axios
          .post(`${Config.GET_CART}`, data)
          .then((res) => {
            console.log(res.data);
            this.setState({
              allcartdata: res.data,
            });
            this.setState({
              cartdata: res.data.data,
            });
          })

          .catch((err) => {})
          .finally(() => {
            this.setState({ loading: false });
          });
      }
    });
  };

  handleClearCart = () => {
    console.log("cust_code", this.state.custCode);
    console.log("access_token", this.state.access_token);
    this.setState({
      valid_promo: "",
    });
    this.setState({
      valid_promo: false,
    });

    var data = new FormData();
    data.append("cust_code", this.state.custCode);
    data.append("access_key", this.state.access_token);

    this.setState({ loading: true });
    axios
      .post(`${Config.CLEAR_CART}`, data)
      .then((res) => {
        console.log(res.data);
        if (res.data.check === "success") {
          var data = new FormData();
          data.append("cust_code", this.state.custCode);

          this.setState({ loading: true });
          axios
            .post(`${Config.GET_CART}`, data)
            .then((res) => {
              console.log(res.data);
              this.setState({
                allcartdata: res.data,
              });
              this.setState({
                cartdata: res.data.data,
              });
            })

            .catch((err) => {})
            .finally(() => {
              this.setState({ loading: false });
            });
        }
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  handleChange = (e) => {
    console.log("promo code", e.target.value);
    this.setState({
      promo_code: e.target.value,
    });
    this.setState({
      promo_placeholder: e.target.value,
    });
    // if(e.target.value.length == 1){
    //   this.setState({
    //     valid_promo: "",
    //   })
    // }
  };
  handleApplypromo = (e) => {
    // console.log("000000000",e.map((k)=> k.id))

    e.map((prod) => {
      this.state.promo_ids.push(prod.id);
    });
    // console.log("push data", this.state.promo_ids);

    let All_promo_ids = this.state.promo_ids.toString();
    // console.log("string",All_promo_ids);

    this.setState({
      promo_ids: e,
    });
    this.setState({
      total_amount: this.state.allcartdata.total_final_amout,
    });
    console.log("user_id", this.state.custCode);
    console.log("promo_code", this.state.promo_code);
    console.log("total", this.state.total_amount);
    console.log("platform", "Web");
    console.log("book_ids", All_promo_ids);

    var data = new FormData();
    data.append("user_id", this.state.custCode);
    data.append("promo_code", this.state.promo_code);
    data.append("total", this.state.allcartdata.total_final_amout);
    data.append("platform", "Web");
    data.append("book_ids", All_promo_ids);

    axios
      .post(`${Config.PROMO_CODE}`, data)
      .then((res) => {
        console.log(res.data);
        this.setState({
          promo_detail: res.data,
        });
        this.setState({
          valid_promo: true,
        });
        this.setState({
          valid_promo_input: true,
        });
      })
      .catch((err) => {
        console.log("error", err.response.data);
        this.setState({
          promo_detail: err.response.data,
        });
        this.setState({
          valid_promo: false,
        });
        this.setState({
          valid_promo_input: true,
        });
      });
      this.setState({ show_promo: false });
      
  };
  handleApplyallcoupon = (e , f) => {
    console.log("000000000",e.map((k)=> k.id))
    console.log("promo code", f);
    this.setState({
      allcoupon_code: f,
    });
    this.setState({
      promo_placeholder: f,
    });
 
    e.map((prod) => {
      this.state.promo_ids.push(prod.id);
    });
    console.log("push data", this.state.promo_ids);

    let All_promo_ids = this.state.promo_ids.toString();
    console.log("string",All_promo_ids);

    this.setState({
      promo_ids: e,
    });
    this.setState({
      total_amount: this.state.allcartdata.total_final_amout,
    });
    console.log("user_id", this.state.custCode);
    console.log("promo_code", f);
    console.log("total", this.state.allcartdata.total_final_amout);
    console.log("platform", "Web");
    console.log("book_ids", All_promo_ids);

    var data = new FormData();
    data.append("user_id", this.state.custCode);
    data.append("promo_code", f);
    data.append("total", this.state.allcartdata.total_final_amout);
    data.append("platform", "Web");
    data.append("book_ids", All_promo_ids);

    axios
      .post(`${Config.PROMO_CODE}`, data)
      .then((res) => {
        console.log(res.data);
        this.setState({
          promo_detail: res.data,
        });
        this.setState({
          valid_promo: true,
        });
        this.setState({
          valid_promo_input: true,
        });
      })
      .catch((err) => {
        console.log("error", err.response.data);
        this.setState({
          promo_detail: err.response.data,
        });
        this.setState({
          valid_promo: false,
        });
        this.setState({
          valid_promo_input: true,
        });
      });
      this.setState({ show_promo: false });
     
  };

  handlePlaceorder = () =>{
    console.log("clicked")

    // axios.get(`${Config.ADD_TXN}`)
    // .then(res=>{
    //   console.log("add_txn",res.data)
    // })
    // .catch(err=>{

    // })
    this.props.navigation('/checkout')
  }

  render() {
    return (
      <div>
        {!this.state.loading ? (
          <div>
            {this.state.cartdata.length > 0 ? (
              <div className="checkout">
                <div className="checkout-details">
                  <div className="checkout-item-component">
                    {!this.state.loading ? (
                      <>
                        {this.state.cartdata.map((prod) => {
                          return (
                            <React.Fragment key={prod.id}>
                              <div className="cart-main-items">
                                <div className="cart-item">
                                  <div className="cart-item-img-div">
                                    <Link
                                      to={`/${prod.slug}/${prod.product_id}`}
                                    >
                                      <img
                                        src={prod.feature_image}
                                        className="cart-item-img"
                                      />
                                    </Link>
                                  </div>
                                  <div className="cart-item-detail">
                                    <Link
                                      to={`/${prod.slug}/${prod.product_id}`}
                                    >
                                      <h6 className="cart_iten_name">
                                        {" "}
                                        {prod.title}
                                      </h6>
                                    </Link>
                                    <p className="cart-item-variant">{`${prod.varient_one},${prod.varient_two}`}</p>
                                    <div className="cart_item_price_details">
                                      <h6 className="cart_item_price_details_mrp">
                                        ₹{Math.trunc(prod.mrp)}
                                      </h6>

                                      <h6 className="cart_item_price_details_final">
                                        ₹{Math.trunc(prod.final_amount)}
                                      </h6>

                                      <h6 className="cart_item_price_details_discount">
                                        {Math.trunc(prod.discount)}% off
                                      </h6>
                                    </div>
                                    {/* <div dangerouslySetInnerHTML={{__html: prod.short_description}} /> */}
                                    <h6 className="cart_item_price_details_seller">
                                      Seller: {prod.seller_name}
                                    </h6>

                                    {/* {this.state.descrip ? */}

                                    <h6 className="cart_item_price_details_seller">
                                      Description:{" "}
                                      {prod.short_description.slice(0, 100)}
                                      {/* <button onClick={()=>this.setState({descrip :false})} className="descrip-show">...Show more</button> */}
                                    </h6>
                                    {/* :
                                     <h6 className="cart_item_price_details_seller">Description: {prod.short_description}
                                    <button onClick={()=>this.setState({descrip :true})} className="descrip-show">Show less</button></h6>
                       } */}
                                  </div>
                                </div>
                                <div className="cart-item-quant">
                                  <div className="cart-item-quantity">
                                    {prod.quantity == 1 ? (
                                      <button
                                        className="cart-item-quantity-butn-disable"
                                        disabled
                                      >
                                        <p>-</p>
                                      </button>
                                    ) : (
                                      <button
                                        className="cart-item-quantity-butn"
                                        onClick={() => {
                                          this.handleDecrementitem(prod).bind(
                                            this
                                          );
                                        }}
                                      >
                                        <p>-</p>
                                      </button>
                                    )}
                                    <p className="cart-item-quantity-num">
                                      {prod.quantity}
                                    </p>
                                    <button
                                      className="cart-item-quantity-butn"
                                      onClick={() => {
                                        this.handleIncrement(prod).bind(this);
                                      }}
                                    >
                                      <p>+</p>
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className="cart-item-remove-butn"
                                      onClick={() => {
                                        this.handleRemoveitem(prod).bind(this);
                                      }}
                                    >
                                      <h6>REMOVE</h6>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <Spinner animation="border" className="loader-spinner" />
                    )}
                    <div className="cart-clear-div">
                      <div>
                        <div className="promo_icon_div">
                          <img
                            src={this.state.promo_icon}
                            alt="promo_icon"
                            className="promo_icon"
                          />
                          <h6>Apply Promocode</h6>
                        </div>
                        <div className="promo_apply_input_div">
                          {this.state.valid_promo_input ? (
                            <React.Fragment>
                              {this.state.valid_promo ? (
                                <input
                                  type="text"
                                  className="true_promo_input"
                                  placeholder={this.state.promo_placeholder}
                                  onChange={this.handleChange.bind(this)}
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="false_promo_input"
                                  placeholder="Have a promocode?Enter here"
                                  onChange={this.handleChange.bind(this)}
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            <input
                              type="text"
                              className="promo_apply_input"
                              placeholder="Have a promocode?Enter here"
                              onChange={this.handleChange.bind(this)}
                            />
                          )}
                
                          <button
                            className="promo_apply_butn"
                            onClick={() =>
                              this.handleApplypromo(this.state.cartdata).bind(
                                this
                              )
                            }
                          >
                            Apply
                          </button>
                        </div>
                        <div className="promo_detail">
                          <div className="promo_check">
                            {this.state.valid_promo_input ? (
                              <React.Fragment>
                                {this.state.valid_promo ? (
                                  <p className="true_promo">
                                    {this.state.promo_detail.message}
                                  </p>
                                ) : (
                                  <p className="false_promo">
                                    {this.state.promo_detail.message}
                                  </p>
                                )}
                              </React.Fragment>
                            ) : null}
                          </div>
                          <div>
                            <a onClick={this.handleShow.bind(this)}>
                              <p className="all_promo_code">View all Offers</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          className="cart-clear-button"
                          onClick={() => {
                            this.handleClearCart().bind(this);
                          }}
                        >
                          Clear Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="checkout-price">
                  <h5 className="checkout-price-component">PRICE DETAILS</h5>

                  <div className="cart-price-div">
                    <h6>Price</h6>
                    <h6>₹{this.state.allcartdata.total_cart_price}</h6>
                  </div>
                  <div className="cart-price-div">
                    <h6>Discount</h6>
                    {this.state.valid_promo ? (
                      <h6 className="promo_discount">
                        -₹{this.state.promo_detail.discount}
                      </h6>
                    ) : (
                      <h6></h6>
                    )}
                  </div>
                  <div className="cart-price-div">
                    <h6>Delivery Charges</h6>
                    <h6>₹{this.state.allcartdata.shipping_charge}</h6>
                  </div>
                  <div className="cart-totalprice-div ">
                    <h5>Total Amount</h5>
                    {!this.state.valid_promo == "" ? (
                      <h5>₹{this.state.promo_detail.discounted_amount}</h5>
                    ) : (
                      <h5>₹{this.state.allcartdata.total_final_amout}</h5>
                    )}
                  </div>
                  <button type="Submit" className="checkout-butn" onClick={this.handlePlaceorder.bind(this)}>
                    Order Now
                  </button>
                </div>
              </div>
            ) : (
              <div className="empty-cart">
                <i class="fa-solid fa-cart-arrow-down  empty-cart-logo"></i>
                <h3>Your cart is empty!</h3>
                <h6>Looks like you haven't add your products yet.</h6>
              </div>
            )}
          </div>
        ) : (
          <Spinner animation="border spin-data" />
        )}
        <Modal
          show={this.state.show_promo}
          onHide={this.handleClose.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title>All Coupons</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.all_promo.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <div className="all_coupon_code">
                    <div>
                      <h6>{item.coupon_name}</h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.coupon_details,
                        }}
                        className="all_coupon_detail"
                      />
                    </div>
                    <div>
                      <h6 className="coupon_code">{item.coupon_code}</h6>
                      <button
                        onClick={() =>
                          this.handleApplyallcoupon(
                            this.state.cartdata,
                            item.coupon_code
                          ).bind(this)
                        }
                        className="all_coupon_apply"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </Modal.Body>
          
        </Modal>
      </div>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();

  return <Cart {...props} navigation={navigation} />;
}



