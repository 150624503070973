import "bootstrap/dist/css/bootstrap.min.css";
import { RootRoutes } from "./RouteModules.js/Routes";
import GlobalState from "./context/GlobalState";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Config } from "./Config/Config";

function App() {
  // console.log('globalstate', GlobalState);

  const [metadata, setMetaData] = useState([]);

  useEffect((e) => {
    axios
      .get(`${Config.HOME_META}`)
      .then((res) => {
        // console.log("metadata", res.data.og_image);
        setMetaData(res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  return (
    <div className="App">
      <Helmet>
        <title>{metadata.meta_title}</title>
        <meta name="description" content="App Description" />
        <link
          rel="icon"
          type="image/png"
          href={metadata.og_image}
          sizes="16x16"
        />
        <meta name="theme-color" content="#008f68" />
      </Helmet>

      <GlobalState>
        <RootRoutes />
      </GlobalState>
    </div>
  );
}

export default App;
