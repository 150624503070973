import React, { useState, useEffect } from 'react'
import Slider from 'react-slick/lib/slider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Card } from "react-bootstrap";
import './Demo2.css'
import axios from "axios";
import { Config } from "../../Config/Config";
import {Link} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';

const NextBtn = (props) => {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick} >
			<ArrowForwardIosIcon />
		</div>
	)
}

const PreBtn = (props) => {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick} >
			<ArrowBackIosIcon />
		</div>
	)
}


const HomeCards = () => {

	const [alldata, setAllData] = useState([])
	const [loader, setLoader] = useState(false)

	useEffect(() => {
		// console.log(Config.END_POINT)
		axios.get(`${Config.GET_CATEGORY}`)
			.then(res => {
				console.log("axiois data", res.data);
				setAllData(res.data);
				setLoader(true);
			})
			.catch(err => {
				// console.log("axios error", err);
				setLoader(true);
			})
	}, [])

	const renderList = () => {

		if (alldata.length == 0) {
			return null;
		}

		return alldata.map((d, index) => {
			// console.warn("inside", alldata)
			return (<div key={index}>

				<div >
				<Link to={`/${d.name}`} className='cards-catlink'>
					<Card className="cards">
						<div className='card-img-div'>
							<Card.Img
								className="card-imge"
								variant="top"
								src={`${Config.END_POINT}`+(d.image)}
								alt="category image"
							/>
						</div>
						<Card.Body>
							<Card.Title className='card-title'>
								{d.name}
							</Card.Title>
						</Card.Body>
					</Card>
					</Link>
				</div>
			</div>)
		}
		)
	}

	return (
		<div className="slide-cards">
			{loader ?
			<Slider
				prevArrow={<PreBtn />}
				nextArrow={<NextBtn />}
				slidesToShow={3}
				slidesToScroll={1}
				infinite={false}

			>

				{renderList()}

				{/* <div >
					<Card className="cards">
						<div className='card-img-div'>
							<Card.Img
								className="card-imge"
								variant="top"
								src="../../../../image/caro-img4.webp"
							/>
						</div>
						<Card.Body>
							<Card.Title className='card-title'>Feel the thirsty in summer anytime
							</Card.Title>
							<Card.Text>
								Your body's way of telling you that it's make strong

							</Card.Text>
						</Card.Body>
					</Card>
				</div>

				<div >
					<Card className="cards">
						<div className='card-img-div'>
							<Card.Img
								className="card-imge"
								variant="top"
								src="../../../../image/caro-img1.webp"
							/>
						</div>
						<Card.Body>
							<Card.Title>Most popular item for Fast food</Card.Title>
							<Card.Text>
								Testy and spicy fast food with different flavors.
							</Card.Text>
						</Card.Body>
					</Card>
				</div>

				<div >
					<Card className="cards">
						<div className='card-img-div'>
							<Card.Img
								className="card-imge"
								variant="top"
								src="../../../../image/caro-img2.webp"
							/>
						</div>
						<Card.Body>
							<Card.Title>Authentic japanese food in real taste</Card.Title>
							<Card.Text>
								Your body's way of telling you that it's make strong
							</Card.Text>
						</Card.Body>
					</Card>
				</div>

				<div >
					<Card className="cards">
						<div className='card-img-div'>
							<Card.Img
								variant="top"
								src="../../../../image/caro-img3.webp"
								className="card-imge"
							/>
						</div>
						<Card.Body>
							<Card.Title>Explore our family of freshest® Foods
							</Card.Title>
							<Card.Text>
								Your pet’s way of telling you that it's make taste
							</Card.Text>
						</Card.Body>
					</Card>
				</div> */}

				{/* <div >
					<Card className="cards">
						<div className='card-img-div'>
							<Card.Img
								className="card-imge"
								variant="top"
								src="../../../../image/caro-img1.webp"
							/>
						</div>
						<Card.Body>
							<Card.Title>Card Title</Card.Title>
							<Card.Text>
								Some quick example text to build on the card title
							</Card.Text>
						</Card.Body>
					</Card>
				</div>

				<div >
					<Card className="cards">
						<div className='card-img-div'>
							<Card.Img
								className="card-imge"
								variant="top"
								src="../../../../image/caro-img2.webp"
							/>
						</div>
						<Card.Body>
							<Card.Title>Card Title</Card.Title>
							<Card.Text>
								Some quick example text to build on the card title
							</Card.Text>
						</Card.Body> 
					</Card> 
				</div>*/}

			</Slider>
: 
<Spinner animation="border" />}
		</div>
	)
}

export default HomeCards;