import React from "react";
import "./Checkout.css";
import { Accordion } from "react-bootstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Spinner, Modal, Form, Button } from "react-bootstrap";
import { Config } from "../../Config/Config";
import {
  getData,
  getLocalStorage,
} from "../DataFromLocalStorage/LocalstorageData";
import axios from "axios";
import AddingAddress from "../MyAccount/Address/AddingAddress";
import AddAddressInCheckout from "../MyAccount/Address/AddAddressInCheckout";

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allcartdata: [],
      cartdata: [],
      custCode: "",
      access_token: "",
      loading: false,
      total_amount: "",
      inc_qunt: "",
      promo_detail: [],
      promo_icon: "",
      promo_code: "",
      allcoupon_code: "",
      valid_promo: "",
      valid_promo_input: false,
      promo_ids: [],
      descrip: true,
      show_address: false,
      all_promo: [],
      promo_placeholder: "",
      add_txn: "",
      allAddress: [],
      noAddress: false,
      allAddress_url: "",
      show_promo: false,
      order_address: [],
      order_id: "",
      txn_no: "",
      COD: "1",
      Razorpay: "1",
      truepromo: "",
     emptyaddress: false, 
     not_signin : true,
    };
  }
  componentDidMount() {
    const d = getLocalStorage("CustomerData");
    const custCode = d.data.cust_code;
    this.setState({ custCode: d.data.cust_code });
    this.setState({ access_token: d.data.access_token });

    var data = new FormData();
    data.append("cust_code", custCode);

    this.setState({ loading: true });
    axios
      .post(`${Config.GET_CART}`, data)
      .then((res) => {
        console.log(res.data);
        this.setState({
          allcartdata: res.data,
        });
        this.setState({
          cartdata: res.data.data,
        });
      })
      // this.props.navigation('/')
      .catch((err) => {})
      .finally(() => {
        this.setState({ loading: false });
      });

    axios.get(`${Config.PROMO_ICON}`).then((res) => {
      // console.log(res.data);
      this.setState({
        promo_icon: res.data,
      })
    })
    .catch((err) => {});

    const address_url = Config.GET_ADDRESS + d.data.cust_code;
    this.setState({
      allAddress_url: address_url,
    });
    axios
      .post(`${address_url}`)
      .then((res) => {
        console.log("all address............>", res.data);
        this.setState({
          allAddress: res.data,
        });
        if(res.data.length > 0){
          this.setState({noAddress : true})
        }
        this.setState({
          emptyaddress : true,
        })

        // let defaultAddress = this.state.allAddress.filter((data)=> data.is_default == 1)
        // console.log("defaultaddress",defaultAddress)
        // this.setState({ order_address : defaultAddress});
        this.setState({
          order_address: this.state.allAddress.filter(
            (data) => data.is_default == 1
          ),
        });
        console.log("order_address", this.state.order_address);
      })
      .catch((err) => {});

      axios
      .get(`${Config.GET_ORDER_ID}`)
      .then((res) => {
        // console.log("order id",res.data.order_id);
        this.setState({
          order_id: res.data.order_id,
        });
      })

      .catch((err) => {});
   
  }

  handleClose = () => {
    this.setState({ show_address: false });

    axios
      .post(`${this.state.allAddress_url}`)
      .then((res) => {
        console.log("all address............>", res.data);
        this.setState({
          allAddress: res.data,
        });
        if(res.data.length > 0){
          this.setState({noAddress : true})
        }
        this.setState({
          order_address: this.state.allAddress.filter(
            (data) => data.is_default == 1
          ),
        });
        // console.log("defaultaddress", defaultAddress);
        // this.setState({ order_address: defaultAddress });
        // console.log("order_address", this.state.order_address);
      })
      .catch((err) => {});
  };
  handleShow = () => {
    this.setState({ show_address: true });
  };

  handleIncrement = (e) => {
    console.log("......eee.......", e);
    this.setState({
      valid_promo: "",
    });
    this.setState({
      valid_promo_input: false,
    });

    console.log("product data", e);
    console.log("qty", "1");
    console.log("cust_code", this.state.custCode);
    console.log("product id", `${e.varient_1},${e.varient_2}`);
    console.log("product code", e.product_code);
    console.log("product final_amount", e.final_amount);

    const productvar_id = `${e.varient_1},${e.varient_2}`;
    console.log("productvar_id.................>", productvar_id);

    let formData = new FormData();

    formData.append("cust_code", this.state.custCode);
    formData.append("product code", e.product_code);
    formData.append("varient", `${e.varient_1},${e.varient_2}`);
    formData.append("price", e.final_amount);
    formData.append("qty", "1");

    axios
      .post(`${Config.ADD_CART}`, formData)
      .then((res) => {
        console.log(".........>", res.data);
        if (res.data.check === "success") {
          var data = new FormData();
          data.append("cust_code", this.state.custCode);

          this.setState({ loading: true });
          axios
            .post(`${Config.GET_CART}`, data)
            .then((res) => {
              console.log(res.data);
              this.setState({
                allcartdata: res.data,
              });
              this.setState({
                cartdata: res.data.data,
              });
            })

            .catch((err) => {})
            .finally(() => {
              this.setState({ loading: false });
            });
        }
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleRemoveitem = (e) => {
    console.log("000000000..........?>", e);
    this.setState({
      valid_promo: "",
    });
    this.setState({
      valid_promo_input: false,
    });

    console.log("product data", e);
    console.log("cust_code", this.state.custCode);
    console.log("access_token", this.state.access_token);
    console.log("cart product id", e.id);

    let formData = new FormData();

    formData.append("cust_code", this.state.custCode);
    formData.append("access_key", this.state.access_token);
    formData.append("cart_id", e.id);
    formData.append("is_clear", "1");

    this.setState({ loading: true });

    axios.post(`${Config.REMOVE_CART_ITEM}`, formData).then((res) => {
      console.log(res.data);
      if (res.data.check === "success") {
        var data = new FormData();
        data.append("cust_code", this.state.custCode);

        this.setState({ loading: true });
        axios
          .post(`${Config.GET_CART}`, data)
          .then((res) => {
            console.log(res.data);
            this.setState({
              allcartdata: res.data,
            });
            this.setState({
              cartdata: res.data.data,
            });
          })

          .catch((err) => {})
          .finally(() => {
            this.setState({ loading: false });
          });
      }
    });
  };

  handleDecrementitem = (e) => {
    console.log("000000000..........?>", e);
    this.setState({
      valid_promo: "",
    });
    this.setState({
      valid_promo_input: false,
    });

    console.log("product data", e);
    console.log("cust_code", this.state.custCode);
    console.log("access_token", this.state.access_token);
    console.log("cart product id", e.id);

    let formData = new FormData();

    formData.append("cust_code", this.state.custCode);
    formData.append("access_key", this.state.access_token);
    formData.append("cart_id", e.id);

    this.setState({ loading: true });

    axios.post(`${Config.REMOVE_CART_ITEM}`, formData).then((res) => {
      console.log(res.data);
      if (res.data.check === "success") {
        var data = new FormData();
        data.append("cust_code", this.state.custCode);

        this.setState({ loading: true });
        axios
          .post(`${Config.GET_CART}`, data)
          .then((res) => {
            console.log(res.data);
            this.setState({
              allcartdata: res.data,
            });
            this.setState({
              cartdata: res.data.data,
            });
          })

          .catch((err) => {})
          .finally(() => {
            this.setState({ loading: false });
          });
      }
    });
  };

  handleDefault = (e) => {
    console.log("address id", e);
    console.log("cust_code", this.state.custCode);
    console.log("access_key", this.state.access_token);

    let formData = new FormData();

    formData.append("cust_code", this.state.custCode);
    formData.append("access_key", this.state.access_token);
    formData.append("id", e);

    axios
      .post(`${Config.DEFAULT_ADDRESS}`, formData)
      .then((res) => {
        console.log("axios response delete", res.data);

        axios
          .post(`${this.state.allAddress_url}`)
          .then((res) => {
            console.log("all address............>", res.data);
            this.setState({
              allAddress: res.data,
            });
            if(res.data.length > 0){
              this.setState({noAddress : true})
            }
            this.setState({
              order_address: this.state.allAddress.filter(
                (data) => data.is_default == 1
              ),
            });
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };
  handlepayOption = (e) => {
    console.log(e);
  };

  handlepromoChange = (e) => {
    console.log("promo code", e.target.value);
    this.setState({
      promo_code: e.target.value,
    });
    this.setState({
      promo_placeholder: e.target.value,
    });
    // if(e.target.value.length == 1){
    //   this.setState({
    //     valid_promo: "",
    //   })
    // }
  };
  handleApplypromo = (e) => {
    // console.log("000000000",e.map((k)=> k.id))

    e.map((prod) => {
      this.state.promo_ids.push(prod.id);
    });
    // console.log("push data", this.state.promo_ids);

    let All_promo_ids = this.state.promo_ids.toString();
    console.log("string", All_promo_ids);

    this.setState({
      promo_ids: e,
    });
    this.setState({
      total_amount: this.state.allcartdata.total_final_amout,
    });
    console.log("user_id", this.state.custCode);
    console.log("promo_code", this.state.promo_code);
    console.log("total", this.state.total_amount);
    console.log("platform", "Web");
    console.log("book_ids", All_promo_ids);

    var data = new FormData();
    data.append("user_id", this.state.custCode);
    data.append("promo_code", this.state.promo_code);
    data.append("total", this.state.allcartdata.total_final_amout);
    data.append("platform", "Web");
    data.append("book_ids", All_promo_ids);

    axios
      .post(`${Config.PROMO_CODE}`, data)
      .then((res) => {
        console.log(res.data);
        this.setState({
          promo_detail: res.data,
        });
        this.setState({
          truepromo: true,
        });
        this.setState({
          valid_promo_input: true,
        });
      })
      .catch((err) => {
        console.log("error", err.response.data);
        this.setState({
          promo_detail: err.response.data,
        });
        this.setState({
          truepromo: false,
        });
        this.setState({
          valid_promo_input: true,
        });
      });
    this.setState({ show_promo: false });
  };
  // handleApplyallcoupon = (e, f) => {
  //   console.log(
  //     "000000000",
  //     e.map((k) => k.id)
  //   );
  //   console.log("promo code", f);
  //   this.setState({
  //     allcoupon_code: f,
  //   });
  //   this.setState({
  //     promo_placeholder: f,
  //   });

  //   e.map((prod) => {
  //     this.state.promo_ids.push(prod.id);
  //   });
  //   console.log("push data", this.state.promo_ids);

  //   let All_promo_ids = this.state.promo_ids.toString();
  //   console.log("string", All_promo_ids);

  //   this.setState({
  //     promo_ids: e,
  //   });
  //   this.setState({
  //     total_amount: this.state.allcartdata.total_final_amout,
  //   });
  //   console.log("user_id", this.state.custCode);
  //   console.log("promo_code", f);
  //   console.log("total", this.state.allcartdata.total_final_amout);
  //   console.log("platform", "Web");
  //   console.log("book_ids", All_promo_ids);

  //   var data = new FormData();
  //   data.append("user_id", this.state.custCode);
  //   data.append("promo_code", f);
  //   data.append("total", this.state.allcartdata.total_final_amout);
  //   data.append("platform", "Web");
  //   data.append("book_ids", All_promo_ids);

  //   axios
  //     .post(`${Config.PROMO_CODE}`, data)
  //     .then((res) => {
  //       console.log(res.data);
  //       this.setState({
  //         promo_detail: res.data,
  //       });
  //       this.setState({
  //         valid_promo: true,
  //       });
  //       this.setState({
  //         valid_promo_input: true,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log("error", err.response.data);
  //       this.setState({
  //         promo_detail: err.response.data,
  //       });
  //       this.setState({
  //         valid_promo: false,
  //       });
  //       this.setState({
  //         valid_promo_input: true,
  //       });
  //     });
  //   this.setState({ show_promo: false });
  // };

  handlepromoClose = () => this.setState({ show_promo: false });
  handlepromoShow = () => {
    this.setState({ show_promo: true });

    axios
      .get(`${Config.GET_COUPON}`)
      .then((res) => {
        this.setState({
          all_promo: res.data,
        });
        console.log("all coupon", res.data);
      })
      .catch((err) => {});
  };

  Submithandle = () => {

    
    const formData = new FormData();
    formData.append("cust_code", this.state.custCode);
    formData.append("payable_amount", this.state.allcartdata.total_final_amout);
    formData.append("pay_method", "COD");
    formData.append("payment_method_pay_id", "1");

    axios
      .post(`${Config.ADD_TXN}`, formData)
      .then((res) => {
        console.log("txn no", res.data.payment_txn_ref);
        this.setState({
          txn_no: res.data.payment_txn_ref,
        });

        // console.log("order address", this.state.order_address[0]);

        console.log("cust_code", this.state.custCode);
        // console.log("items", JSON.stringify(this.state.cartdata));
        console.log("sub_total", this.state.allcartdata.total_cart_price);
        console.log("coupon_code");
        console.log("shipping", this.state.allcartdata.shipping);
        console.log("shipping_charge", this.state.allcartdata.shipping_charge);
        console.log("grand_total", this.state.allcartdata.total_final_amout);
        console.log("guest_checkout", "0");
        console.log("tax_amount", this.state.allcartdata.total_tax);
        console.log("tax_percentage", "0");
        console.log("discount", "0");
        console.log("promo_discount", "0");
        // console.log("address", JSON.stringify(this.state.order_address));
        console.log("order_id_fk", this.state.order_id);
        console.log("payment_txn_ref", res.data.payment_txn_ref);

        var cartArr = [];
        let i = 0;
        for (i = 0; i < this.state.cartdata.length; i++) {
          var myObj = {
            product_id_fk: this.state.cartdata[i].product_code,
            is_variable: this.state.cartdata[i].is_variable,
            varient_one: this.state.cartdata[i].varient_1,
            varient_two: this.state.cartdata[i].varient_2,
            qty: this.state.cartdata[i].quantity,
            price: this.state.cartdata[i].final_amount,
            line_total: this.state.cartdata[i].final_line_total,
          };
          cartArr.push(myObj);
        }
        console.log("items..............>?",JSON.stringify(cartArr));

          var myaddressObj = {
            "first_name": this.state.order_address[0].first_name,
            "last_name": this.state.order_address[0].last_name,
            "address_one": this.state.order_address[0].address_one,
            "address_two": this.state.order_address[0].address_two,
            "mobile": this.state.order_address[0].mobile,
            "landmark": this.state.order_address[0].landmark,
            "pincode" : this.state.order_address[0].pincode,
            "city" : this.state.order_address[0].city_id,
            "state" : this.state.order_address[0].state_id,
          }
        console.log("address............>",myaddressObj);
       

        const data = new FormData();
        data.append("user_id", this.state.custCode);
        data.append("items", JSON.stringify(cartArr));
        data.append("sub_total", this.state.allcartdata.total_cart_price);
        data.append("coupon_code", null);
        data.append("shipping", this.state.allcartdata.shipping);
        data.append("shipping_charge", this.state.allcartdata.shipping_charge);
        data.append("grand_total", this.state.allcartdata.total_final_amout);
        data.append("guest_checkout", "0");
        data.append("tax_amount", this.state.allcartdata.total_tax);
        data.append("tax_percentage", "0");
        data.append("discount", "0");
        data.append("promo_discount", "0");
        data.append("address", JSON.stringify(myaddressObj));
        data.append("order_id_fk", this.state.order_id);
        data.append("payment_txn_ref", res.data.payment_txn_ref);

        axios
          .post(`${Config.PLACE_ORDER}`, data)
          .then((res) => {
            console.log("place order", res.data);
            alert("order placed");
            this.props.navigation("/myorder");
          })
          .catch((err) => {
            console.log("error order", err);
            alert("error");
          });
      })
      .catch((err) => {});
  };

  render() {
    return (
      <div className="order_main_div">
        <div className="order_accordion">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Delivery Address</Accordion.Header>
              <Accordion.Body>
                {this.state.emptyaddress ?
                <div>
                  {this.state.noAddress ? 
                  <React.Fragment>
                  {this.state.allAddress.map((item) => {
                    return (
                      <div key={item.id}>
                        {item.is_default == 1 ? (
                          <div className="order_address_default">
                            <div className="order_checkbox">
                              <div className="order_address_input">
                                <input
                                  type="radio"
                                  name="payment_address"
                                  checked
                                  className="address_radio"
                                />
                              </div>
                              <div className="order_address_name">
                                <h6>
                                  {item.first_name} {item.last_name}
                                </h6>
                                <p className="order_address_mobile">
                                  {item.mobile}
                                </p>
                              </div>
                            </div>
                            <div>
                              <p>
                                {item.address_one},{item.address_two},
                                {item.city_id},{item.state_id}-
                                <span className="order_address_pin">
                                  {item.pincode}
                                </span>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="order_address">
                            <div className="order_checkbox">
                              <div className="order_address_input">
                                <input
                                  type="radio"
                                  name="payment_address"
                                  onClick={() =>
                                    this.handleDefault(item.id).bind(this)
                                  }
                                />
                              </div>
                              <div className="order_address_name">
                                <h6>
                                  {item.first_name} {item.last_name}
                                </h6>
                                <p className="order_address_mobile">
                                  {item.mobile}
                                </p>
                              </div>
                            </div>
                            <div>
                              <p>
                                {item.address_one},{item.address_two},
                                {item.city_id},{item.state_id}-
                                <span className="order_address_pin">
                                  {item.pincode}
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  </React.Fragment>
                  :
                  null
                  }
                  <button
                    className="add_address"
                    onClick={this.handleShow.bind(this)}
                  >
                    + Add Address
                  </button>
                </div>
                :
                <div>
                  <button
                    className="add_address"
                    onClick={this.handleShow.bind(this)}
                  >
                    + Add Address
                  </button>
                </div>
  }
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Order Summary</Accordion.Header>
              <Accordion.Body>
                <div className="checkout">
                  <div className="order-details">
                    <div className="checkout-item-component">
                      {!this.state.loading ? (
                        <>
                          {this.state.cartdata.map((prod) => {
                            return (
                              <React.Fragment key={prod.id}>
                                <div className="cart-main-items">
                                  <div className="cart-item">
                                    <div className="cart-item-img-div">
                                      <Link
                                        to={`/${prod.slug}/${prod.product_id}`}
                                      >
                                        <img
                                          src={prod.feature_image}
                                          className="cart-item-img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="cart-item-detail">
                                      <Link
                                        to={`/${prod.slug}/${prod.product_id}`}
                                      >
                                        <h6 className="cart_iten_name">
                                          {" "}
                                          {prod.title}
                                        </h6>
                                      </Link>
                                      <p className="cart-item-variant">{`${prod.varient_one},${prod.varient_two}`}</p>
                                      <div className="cart_item_price_details">
                                        <h6 className="cart_item_price_details_mrp">
                                          ₹{Math.trunc(prod.mrp)}
                                        </h6>

                                        <h6 className="cart_item_price_details_final">
                                          ₹{Math.trunc(prod.final_amount)}
                                        </h6>

                                        <h6 className="cart_item_price_details_discount">
                                          {Math.trunc(prod.discount)}% off
                                        </h6>
                                      </div>
                                      {/* <div dangerouslySetInnerHTML={{__html: prod.short_description}} /> */}
                                      <h6 className="cart_item_price_details_seller">
                                        Seller: {prod.seller_name}
                                      </h6>

                                      {/* {this.state.descrip ? */}

                                      <h6 className="cart_item_price_details_seller">
                                        Description:{" "}
                                        {prod.short_description.slice(0, 100)}
                                        {/* <button onClick={()=>this.setState({descrip :false})} className="descrip-show">...Show more</button> */}
                                      </h6>
                                      {/* :
                                     <h6 className="cart_item_price_details_seller">Description: {prod.short_description}
                                    <button onClick={()=>this.setState({descrip :true})} className="descrip-show">Show less</button></h6>
                       } */}
                                    </div>
                                  </div>
                                  <div className="cart-item-quant">
                                    <div className="cart-item-quantity">
                                      {prod.quantity == 1 ? (
                                        <button
                                          className="cart-item-quantity-butn-disable"
                                          disabled
                                        >
                                          <p>-</p>
                                        </button>
                                      ) : (
                                        <button
                                          className="cart-item-quantity-butn"
                                          onClick={() => {
                                            this.handleDecrementitem(prod).bind(
                                              this
                                            );
                                          }}
                                        >
                                          <p>-</p>
                                        </button>
                                      )}
                                      <p className="cart-item-quantity-num">
                                        {prod.quantity}
                                      </p>
                                      <button
                                        className="cart-item-quantity-butn"
                                        onClick={() => {
                                          this.handleIncrement(prod).bind(this);
                                        }}
                                      >
                                        <p>+</p>
                                      </button>
                                    </div>
                                    <div>
                                      <button
                                        className="cart-item-remove-butn"
                                        onClick={() => {
                                          this.handleRemoveitem(prod).bind(
                                            this
                                          );
                                        }}
                                      >
                                        <h6>REMOVE</h6>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </>
                      ) : (
                        <Spinner
                          animation="border"
                          className="loader-spinner"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Payment Option</Accordion.Header>
              <Accordion.Body className="payment_accordion">
                <div>
                  <div className="pay-option">
                    <snap className="cod_snap">
                      <input
                        type="radio"
                        value="COD"
                        name="payment_method"
                        onClick={() =>
                          this.handlepayOption(this.state.COD).bind(this)
                        }
                      />{" "}
                      COD
                    </snap>
                    <snap>
                      <input
                        type="radio"
                        value="razorpay"
                        name="payment_method"
                        onClick={() =>
                          this.handlepayOption(this.state.Razorpay).bind(this)
                        }
                        disabled
                      />{" "}
                      Razorpay
                    </snap>
                  </div>
                  <div>
                    <div>
                      <div className="promo_icon_div">
                        <img
                          src={this.state.promo_icon}
                          alt="promo_icon"
                          className="promo_icon"
                        />
                        <h6>Apply Promocode</h6>
                      </div>
                      {this.state.valid_promo_input ? (
                        <React.Fragment>
                          {this.state.valid_promo ? (
                            <input
                              type="text"
                              className="true_promo_input"
                              placeholder={this.state.promo_placeholder}
                              onChange={this.handlepromoChange.bind(this)}
                            />
                          ) : (
                            <input
                              type="text"
                              className="false_promo_input"
                              placeholder="Have a promocode?Enter here"
                              onChange={this.handlepromoChange.bind(this)}
                            />
                          )}
                        </React.Fragment>
                      ) : (
                        <input
                          type="text"
                          className="promo_apply_input"
                          placeholder="Have a promocode?Enter here"
                          onChange={this.handlepromoChange.bind(this)}
                        />
                      )}
                      <button
                        className="promo_apply_butn"
                        onClick={() =>
                          this.handleApplypromo(this.state.cartdata).bind(this)
                        }
                      >
                        Apply
                      </button>
                    </div>
                    <div className="promo_detail">
                      <div className="promo_check">
                        {this.state.valid_promo_input ? (
                          <React.Fragment>
                            {this.state.valid_promo ? (
                              <p className="true_promo">
                                {this.state.promo_detail.message}
                              </p>
                            ) : (
                              <p className="false_promo">
                                {this.state.promo_detail.message}
                              </p>
                            )}
                          </React.Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="checkout-price">
          <h5 className="checkout-price-component">PRICE DETAILS</h5>

          <div className="cart-price-div">
            <h6>Price</h6>
            <h6>₹{this.state.allcartdata.total_cart_price}</h6>
          </div>
          <div className="cart-price-div">
            <h6>Discount</h6>
            {this.state.valid_promo ? (
              <h6 className="promo_discount">
                -₹{this.state.promo_detail.discount}
              </h6>
            ) : (
              <h6></h6>
            )}
          </div>
          <div className="cart-price-div">
            <h6>Delivery Charges</h6>
            <h6>₹{this.state.allcartdata.shipping_charge}</h6>
          </div>
          <div className="cart-totalprice-div ">
            <h5>Total Amount</h5>
            {!this.state.valid_promo == "" ? (
              <h5>₹{this.state.promo_detail.discounted_amount}</h5>
            ) : (
              <h5>₹{this.state.allcartdata.total_final_amout}</h5>
            )}
          </div>
          <button
            type="Submit"
            className="checkout-butn"
            onClick={this.Submithandle.bind(this)}
          >
            Place Order
          </button>
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.show_address}
          onHide={this.handleClose.bind(this)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="add_address_modal">
            <AddAddressInCheckout className="address_component" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.handleClose.bind(this)}
              className="add_checkout_address"
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default function (props) {
  const navigation = useNavigate();

  return <Checkout {...props} navigation={navigation} />;
}
