import React from "react";
import "./About.css";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

function Aboutus() {
	return (
		<div>
			<img src="../../../../image/about-us.png" className="about-img1" />
			<Container>
				<div>
					<div >
						<h3 className="about">About us</h3>
					</div>

					<div className="about">
						<p className="about about-p">
							We may automatically track certain information about you
							based upon your behavior on the website. We use this
							information to do internal research on our users’
							demographics, interests, and behavior to better understand,
							protect and serve our users. This information is compiled
							and analyzed on an aggregated basis. This information may
							include the URL that you just came from (whether this URL
							is on the website or not), which URL you next go to
							(whether this URL is on the website or not), your computer
							browser information, your IP address, and other information
							associated with your interaction with the website.
							<strong>
								We may also share your Mobile IP/Device IP with third
								party(ies) and to the best of our knowledge, be-life and
								representations given to us by these third party(ies)
								this information is not stored.
							</strong>
						</p>
						<p className="about about-p">
							Our Privacy Policy is incorporated into the Terms and
							Conditions of Use of the website/app, and is subject to
							change from time to time without notice. It is strongly
							recommended that you periodically review our Privacy Policy
							as posted on the App/Web.
						</p>
						<p className="about about-p">
							Should you have any clarifications regarding this Privacy
							Policy, please do not hesitate to contact us at &nbsp;
							<Link to="" className="about-link">
								info@borobazar.com
							</Link>
							&nbsp;.
						</p>
					</div>

					<div className="about-img2 about">
						<div>
							<img
								src="../../../../image/about-2.webp"
								className="about-img3"
							/>
						</div>
						<div>
							<img
								src="../../../../image/about-3.webp"
								className="about-img3"
							/>
						</div>
					</div>

					<div className="about">
						<p className="about about-p">
							To protect against the loss, misuse and alteration of the
							information under its control, the Company has in place
							appropriate physical, electronic and managerial procedures.
							For example, the Company servers are accessible only to
							authorized personnel and your information is shared with
							employees and authorized personnel on a need to know basis
							to complete the transaction and to provide the services
							requested by you. Although the Company endeavour to
							safeguard the confidentiality of your personally
							identifiable information, transmissions made by means of
							the Internet cannot be made absolutely secure. By using the
							website, you agree that the Company will have no liability
							for disclosure of your information due to errors in
							transmission and/or unauthorized acts of third parties.
						</p>
						<p className="about about-p">
							Please note that the Company will not ask you to share any
							sensitive data or information via email or telephone. If
							you receive any such request by email or telephone, please
							do not respond/divulge any sensitive data or information
							and forward the information relating to the same to &nbsp;
							<Link to="" className="about-link">
								info@borobazar.com
							</Link>
							&nbsp; for necessary action.
						</p>
						<div className="about">
							<img
								src="../../../../image/about-4.webp"
								className="about-careimg"
							/>
						</div>

						<div className="about">
							<h3 className="about">Be safe, be secure!!</h3>
						</div>
						<div className="about">
							<p className="about about-p">
								BoroBazar is leading the charge in transforming India’s
								vast, unorganized grocery landscape through cutting-edge
								technology and innovation. We believe every Indian
								deserves the opportunity to continually improve their
								life – a process that often begins at home. As part of
								our mission of helping consumers make healthier, better
								choices when buying everyday products, we make a wide
								range of high-quality grocery and household products
								accessible, affordable, and available right at their
								doorsteps.
							</p>
							<h5 className="about">
								For media enquiries please contact us at:&nbsp;
								<Link to="" className="about-link">
									press@borobazar.com
								</Link>
								&nbsp;.
							</h5>
							<p className="about about-p">
								For all other inquiries, visit our &nbsp;
								<Link to="" className="about-link">	
									Contact Us
								</Link>
								&nbsp; page.
							</p>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default Aboutus;
