import React, { useState } from 'react'
import './ChangePass.css'
import { Form } from "react-bootstrap";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Myaccount from '../Myaccount';

function ChangePass() {

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }


    const [newpasswordType, setNewPasswordType] = useState("newpassword");
    const [newpasswordInput, setNewPasswordInput] = useState("");
    const handleNewPasswordChange = (evnt) => {
        setNewPasswordInput(evnt.target.value);
    }
    const newtogglePassword = () => {
        if (newpasswordType === "newpassword") {
            setNewPasswordType("text")
            return;
        }
        setNewPasswordType("newpassword")
    }


    return (
        <div className='myacc-changepass'>
            <div>
                <Myaccount />
            </div>
            <div className='myacc-changepass-form'>
                <Form>
                <h5>Change Password</h5>
              
                    <div>
                       
                        <label className="acc-text">
                            Old Password</label><br />
                        <div className="input-group acc-text-input changepass">

                            <input type={passwordType} onChange={handlePasswordChange} value={passwordInput} name="password" class="form-control change-password " />
                            <div className="change-pass-toggleBtn">
                                <button className="change-toggleBtn" onClick={togglePassword}>
                                    {passwordType === "password" ? <VisibilityOffOutlinedIcon className="toggleBtn-icon" /> : <RemoveRedEyeOutlinedIcon className="toggleBtn-icon" />}
                                </button>
                            </div>
                        </div>
                      
                    </div>
                    <div>
                       
                        <label className="acc-text">
                            New Password</label><br />
                        <div className="input-group acc-text-input changepass">

                            <input type={newpasswordType} onChange={handleNewPasswordChange} value={newpasswordInput} name="newpassword" class="form-control change-password " />
                            <div className="change-pass-toggleBtn">
                                <button className="change-toggleBtn" onClick={newtogglePassword}>
                                    {newpasswordType === "newpassword" ? <VisibilityOffOutlinedIcon className="toggleBtn-icon" /> : <RemoveRedEyeOutlinedIcon className="toggleBtn-icon" />}
                                </button>
                            </div>
                        </div>
                        <button type="submit" className="acc-submit">
                        Change Password
                            </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default ChangePass