import React,{useState,useEffect} from "react";

export const flatArray = (arraySample) => {
    let margeArray = [];
            let i = 0;
    for(i=0; i < Number(Object.keys(arraySample[0]).length); i++){
        if(typeof arraySample[0][i] == 'object'){
            margeArray.push(arraySample[0][i][0])
        }
    }
    return margeArray;
}


export const easyArrayFlatten = (arraySample) => {
    let margeArray = [];
    arraySample.map((item) => {
        if(typeof item == 'object'){
            item.books.forEach(element => {
                margeArray.push(element);
            });
        }
    });
    // console.log("+++++++++++++++++++++")
    // console.log(margeArray)
    // console.log("+++++++++++++++++++++")
    return margeArray;
}

