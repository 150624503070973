import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Col,
  Row,
  Card,
  Container,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import {
  getData,
  getLocalStorage,
} from "../DataFromLocalStorage/LocalstorageData";
// import prodData from '../products.json'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Config } from "../../Config/Config";
import { Link } from "react-router-dom";
import axios from "axios";
import { easyArrayFlatten } from "../../Utils/Utils";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate } from "react-router-dom";

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIosIcon />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIosIcon />
    </div>
  );
};

const Electronics = () => {
  const [favicon, setFavIcon] = useState(true);
  const [userdata, SetUserData] = useState(undefined);
  const [alldata, setAlldata] = useState([]);
  const [newdata, setNewdata] = useState([]);
  const [alldataItem, setAlldataItem] = useState([]);
  const [sectionName, setSectionName] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wishlistdata, setWishlistdata] = useState([]);
  const [nowishlistdata, setNoWishlistdata] = useState(false);
  const [show, setShow] = useState(false);
  const [createshow, setCreateShow] = useState(false);
  const [accesskey, setAccesskey] = useState("");
  const [inputstate, setInputstate] = useState("");
  const [wistlistloader, setWistlistloader] = useState(false);
  const [productcode, setProductcode] = useState("");


  useEffect(() => {
    try {
      const d = getLocalStorage("CustomerData");
      SetUserData(d.data.cust_code);
      setAccesskey(d.data.access_token);
      // //console.log(">....", d.data.cust_code)
    } catch (error) {
      // //console.log('is error', error);
    }
  }, []);

  const URL = Config.ELECTRONICS + userdata;
  // //console.log(URL);

  useEffect((e) => {
    axios
      .get(`${URL}`)
      .then((res) => {
        console.log("axios response first", res.data);
        setAlldata(res.data);
        setSectionName(res.data[0]);

        // //console.log("axios response fash", alldata);
        const abc = easyArrayFlatten(res.data);
        setAlldataItem(abc);
        setLoading(false);
        // //console.log("444444444444", alldataItem);
      })
      .catch((err) => {
        // //console.log("axios error elec", err)
        setLoading(false);
      });
  }, [loading]);

  const wishlist_url = Config.PRODUCT_WISHLIST + userdata;

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    //console.log("product code", e);
    setProductcode(e);
    setShow(true);

    axios
      .get(`${wishlist_url}`,{headers: { 
        'Cache-Control': 'no-store'
      }})
      .then((res) => {
        //console.log("axios response wishlist", res.data);
        //console.log("axios response ", res.data.length);
        const xyz = res.data;
        setWishlistdata(xyz);
        setNewdata(res.data.wishlistData)
        if(res.data.wishlistData.length > 0){
          setNoWishlistdata(true)
        }
        setWistlistloader(true);
        //console.log("axios response fash", wishlistdata);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setWistlistloader(true);
        // //console.log("axios error elec", err)
      });
  };

  const handleCreate = () => {
    setShow(false);
    setCreateShow(true);
  };
  const handleCreateoff = () => {
    setShow(false);
    setCreateShow(false);
  };

  const handleChange = (e) => {
    //console.log("....", e.target.value);
    setInputstate(e.target.value);
  };

  const handleAdd = () => {
    let formData = new FormData();

    formData.append("cust_code", userdata);
    formData.append("access_key", accesskey);
    formData.append("is_private", "0");
    formData.append("is_default", newdata.length > 0 ? "0" : "1");
    formData.append("label", inputstate);

    axios
      .post(`${Config.CREATE_WISHLIST}`, formData)
      .then((res) => {
        //console.log("............000.....", res.data);
        if(res.data.check == "success"){
          axios
          .get(`${wishlist_url}`,{headers: { 
            'Cache-Control': 'no-store'
          }})
          .then((res) => {
            //console.log("axios response wishlist", res.data);
            //console.log("axios response ", res.data.length);
            const xyz = res.data;
            setWishlistdata(xyz);
            setNewdata(res.data.wishlistData)
            if(res.data.wishlistData.length > 0){
              setNoWishlistdata(true)
            }
            setWistlistloader(true);
            //console.log("axios response fash", wishlistdata);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            setWistlistloader(true);
            // //console.log("axios error elec", err)
          });
        }
        alert("done");
      })
      .catch((err) => {
        //console.log("............000.....", err.data);
        alert("fail");
      });

    setCreateShow(false);
    setShow(true);
  };
  const handleadditem = (e) => {
    //console.log("........", e);

    let formData = new FormData();

    formData.append("cust_code", userdata);
    formData.append("access_key", accesskey);
    formData.append("wishlist_id", e);
    formData.append("type", "1");
    formData.append("product_code", productcode);

    axios
      .post(`${Config.ADD_WISHLIST}`, formData)
      .then((res) => {
        //console.log("............000.....", res.data);
        alert("done");
        axios
        .get(`${URL}`)
        .then((res) => {
          //console.log("axios response elec", res.data);
          setAlldata(res.data);
          setSectionName(res.data[0]);
  
          // //console.log("axios response fash", alldata);
          const abc = easyArrayFlatten(res.data);
          setAlldataItem(abc);
          setLoading(false);
          // //console.log("444444444444", alldataItem);
        })
        .catch((err) => {
          // //console.log("axios error elec", err)
          setLoading(false);
        });
      })
      .catch((err) => {
        //console.log("............000.....", err.data);
        alert("fail");
      });

    setShow(false);
  };
  const handledeleteitem = (e) => {
    //console.log(e);

    let formData = new FormData();

    formData.append("cust_code", userdata);
    formData.append("access_key", accesskey);
    formData.append("product_code", e);
    formData.append("type", "0");

    axios
      .post(`${Config.REMOVE_WISHLIST_ITEM}`, formData)
      .then((res) => {
        //console.log("............000.....", res.data);
       
        axios
      .get(`${URL}`)
      .then((res) => {
        //console.log("axios response elec", res.data);
        setAlldata(res.data);
        setSectionName(res.data[0]);

        // //console.log("axios response fash", alldata);
        const abc = easyArrayFlatten(res.data);
        setAlldataItem(abc);
        setLoading(false);
        // //console.log("444444444444", alldataItem);
      })
      .catch((err) => {
        // //console.log("axios error elec", err)
        setLoading(false);
      });
          alert("remove done");
      })
      .catch((err) => {
        //console.log("............000.....", err.data);
        alert("fail");
      });

    setShow(false);
  };

  return (
    <>
    {!loading ?
      <div className="product-caro">
        {/* <div className="all-product">
          <button className="for-allbtun">
            <h6>VIEW ALL</h6>
          </button>
        </div> */}
        <div className="product-home-carousel">
          {/* {sectionName.map((u, index) => { */}
            {/* return ( */}
                {/* <React.Fragment key={index}> */}
                <div className='fashion-header'>
                <div>
                <h4 className='header-section-name'>{sectionName.section_name}</h4>
                </div>
                <div >
                    <Link to={`/${sectionName.section_name}`} className='fashion-allbtun'>
                        <h6>VIEW ALL</h6>
                    </Link>
                </div>
                </div>
            {/* </React.Fragment> */}
            {/* ); */}
          {/* })} */}
          <Slider
            slidesToShow={3}
            slidesToScroll={1}
            infinite={false}
            prevArrow={<PreviousBtn />}
            nextArrow={<NextBtn />}
          >
            {alldataItem.map((prod) => (
              <React.Fragment key={prod.id}>
                {/* <Link
                  to={`/${prod.slug}/${prod.id}`}
                  className="prod-card-link"
                >
                  <div class="prod-card"> */}
                    {/* <div className="caro-wishlist">
                      {favicon ? (
                        <button
                          className="subprod-wishlist-icon"
                          onClick={() => setFavIcon(false)}
                        >
                          <FavoriteBorderOutlinedIcon className="" />
                        </button>
                      ) : (
                        <button
                          className="subprod-wishlist-favicon"
                          onClick={() => setFavIcon(true)}
                        >
                          <FavoriteIcon className="" />
                        </button>
                      )}
                    </div> */}
                    {/* <img
                      class="prod-card-img-top"
                      src={prod.feature_image}
                      alt="Card image cap"
                    />

                    <div class="card-body">
                      <div className="prod-price-detail">
                        <h6 class="prod-card-price">${prod.sale_price}</h6>
                        <p className="prod-price-cut">${prod.mrp}</p>
                      </div>
                      <h5 class="prod-card-title">{prod.title}</h5>
                    </div>
                  </div>
                </Link> */}
                   <Card className="prod-cards">
                   {prod.is_wishlisted === '1' ? (
                          <button
                            className="prod-wishlist-icon"
                            variant="primary"
                            onClick={() => {
                              handledeleteitem(prod.code);
                            }}
                            value={prod.id}
                          >
                            <i class="fa-solid fa-heart wishlisted-icon"></i>
                          </button>
                        ) : (
                  <button
                    className="prod-wishlist-icon"
                    variant="primary"
                    onClick={() => {
                      handleShow(prod.code);
                    }}
                  >
                    <i class="fa-solid fa-heart prod-mainfavicon"></i>
                  </button>
                        )}
                  <Link
                    to={`/${prod.slug}/${prod.id}`}
                    className="prod-card-link"
                  >
                    <div className="prod-cards-img-div">
                      <div>
                        <Card.Img
                          variant="top"
                          src={prod.feature_image}
                          alt="product image"
                          className="prod-cards-img"
                        />
                      </div>
                    </div>
                    <Card.Body>
                      <div className="cards-price">
                        <Card.Title>₹{prod.final_amount}</Card.Title>

                        <p className="cut-price">₹{prod.mrp}</p>
                      </div>
                      <Card.Text className="prodname-card">{prod.title}</Card.Text>
                    </Card.Body>
                  </Link>
                </Card>
              </React.Fragment>
            ))}
          </Slider>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>My WishList</Modal.Title>
          </Modal.Header>
          {wistlistloader ? (
            <>
              <Modal.Body>
                {nowishlistdata ? <React.Fragment>
                {newdata.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <button className="cat-wishlist-name-butn">
                        <h5
                          className="cat-wishlist-name"
                          onClick={() => {
                            handleadditem(item.id);
                          }}
                        >
                          {item.label}
                        </h5>
                      </button>
                    </React.Fragment>
                  );
                })}
                </React.Fragment>
                : null}
              </Modal.Body>
              <Modal.Footer>
                <button onClick={handleCreate} className="cat-modal-save">
                  + Create WishList
                </button>
              </Modal.Footer>
            </>
          ) : (
            <Spinner animation="border" />
          )}
        </Modal>

        <div>
          <Modal show={createshow} onHide={handleCreateoff}>
            <Modal.Header closeButton>
              <Modal.Title>New WishList</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="modal-label">Wishlist Name</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Wishlist Name"
                    autoFocus
                    className="modal-input"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer className="madal-footer">
              <Button
                variant="secondary"
                onClick={handleCreateoff}
                className="madal-footer-close"
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={handleAdd}
                className="madal-footer-save"
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      :  <Spinner animation="border" />}
    </>
  );
};

export default Electronics;
