import React, { useState, useEffect } from "react";
import { Carousel, Container, Card, Row, Col ,Spinner } from "react-bootstrap";
import HomeCards from "../Home-caro/Cards";
import HomeTag from "../Home-tag/Tag";
// import Products from '../Products/products';
import "./Home.css";
import axios from "axios";
import { Config } from "../../Config/Config";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import { Helmet } from "react-helmet";
import prodData from "../products.json";
import Slider from "react-slick/lib/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Products from "../Products/products";
import Productcard from "../Home-caro2/Productcard";
import LatestCollection from "../Latest_Collection/LatestCollection";
import DealofDay from "../DealofDay/DealofDay";
import Electronics from "../Electronics/Electronics";
import Jewellery from "../Jewellery/Jewellery";
import Fashion from "../Fashion/Fashion";



const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIosIcon />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIosIcon />
    </div>
  );
};

function Home() {
  const [metadata, setMetaData] = useState([]);
  const [setting,setSetting] = useState([]);
 
  useEffect((e) => {
    axios
      .get(`${Config.GET_SETTINGS}`)
      .then((res) => {
        console.log("setting", res.data);
        setSetting(res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  useEffect((e) => {
    axios
      .get(`${Config.HOME_META}`)
      .then((res) => {
        console.log("metadata", res.data.og_image);
        setMetaData(res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  const[bannerloader,setBannerloader]=useState(true)
  const [homeBanner, setHomeBanner] = useState({
    homebanners: [],
  });
  const[otherbannerloader,setOtherBannerloader]=useState(true)
  const [otherBanner, setOtherBanner] = useState({
    banners: [],
  });

  useEffect(() => {
    axios
      .get(`${Config.HOME_BANNER}`)
      .then((res) => {
        // console.log("home banner axios responce", res.data);
        setHomeBanner({ homebanners: res.data });
        setBannerloader(false);
      })
      .catch((err) => {
        // console.log("Axios error", err);
        setBannerloader(false);
      });
  }, [setHomeBanner]);

  useEffect(() => {
    axios
      .get(`${Config.HOME_OTHERBANNER}`)
      .then((res) => {
        // console.log("otherbanner axios responce", res.data);
        setOtherBanner({ banners: res.data });
        // console.log("..............",otherBanner)
        setOtherBannerloader(false);
      })
      .catch((err) => {
        // console.log("Axios error", err);
        setOtherBannerloader(false);
      });
  }, [setOtherBanner]);

 
  return (
    <div>
      <Helmet>
        <title>{metadata.meta_title}</title>
        <meta name="description" property="og:descrip" content={metadata.meta_descrip} />
        <meta name="description" property="og:descrip" content={metadata.tag_line} />
        <link
          rel="icon"
          type="image/png"
          href={metadata.og_image}
          sizes="16x16"
        />
      </Helmet>
      <>
      {!bannerloader ?
      <>
        {homeBanner.homebanners.map((home) => (
          <React.Fragment key={home.index}>
            <Carousel>
              <Carousel.Item>
                <img className="d-block w-100 homebanner" src={home.image} alt="banner" />
              </Carousel.Item>
            </Carousel>
          </React.Fragment>
        ))}
        </>
        :
        <Spinner animation="border" className="loader-spinner"/> }
      </>

      <Container>
        {!otherbannerloader ?
        <div className="home-banner-container">
          {otherBanner.banners.map((ban) => (
            <React.Fragment key={ban.index}>
              <button className="home-banner">
                <div className="flex-child banner-style">
                  <img className="banner" src={ban.image} alt="banner" />
                </div>
              </button>
            </React.Fragment>
          ))}
        </div>
        :
        <Spinner animation="border" />}
      </Container>

      <HomeTag />

      {/* <div className=''>
            <Slider
                prevArrow={<PreBtn />}
                nextArrow={<NextBtn />}
                slidesToShow={5}
                slidesToScroll={1}
                infinite={false}
            >
                <div >
              {
                            prodData.products.map((prod) =>
                                <React.Fragment key={prod.id}>
<div>
                                 
                                        <Card className='prod-cards'>
                                            <div className='prod-cards-img-div'>
                                                <div>
                                                    <Card.Img variant="top" src={prod.image.thumbnail} alt="product image" className='prod-cards-img' />
                                                </div>
                                                <div>
                                                    <button className='prod-add-butn'><i class="fa fa-plus" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title>${prod.price}</Card.Title>
                                                <Card.Text>
                                                    {prod.name}
                                                </Card.Text>
                                                <Card.Text>
                                                    {prod.unit}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        </div>

                                </React.Fragment>


                            )}
                            </div>
            </Slider>
            </div> */}

      {/* <Container>
                <Slider 
                prevArrow={<PreBtn />}
				nextArrow={<NextBtn />}
                slideToShow={5}
                slideToScroll= {5}
                infinite={false}
                >
                {
                            prodData.products.map((prod) =>
                                <React.Fragment key={prod.id}>
<Col className=''>
                                 
                                        <Card className='prod-cards'>
                                            <div className='prod-cards-img-div'>
                                                <div>
                                                    <Card.Img variant="top" src={prod.image.thumbnail} alt="product image" className='prod-cards-img' />
                                                </div> */}
      {/* <div>
                                                    <button className='prod-add-butn'><i class="fa fa-plus" aria-hidden="true"></i>
                                                    </button>
                                                </div> */}
      {/* </div>
                                            <Card.Body>
                                                <Card.Title>${prod.price}</Card.Title>
                                                <Card.Text>
                                                    {prod.name}
                                                </Card.Text>
                                                <Card.Text>
                                                    {prod.unit}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        </Col>

                                </React.Fragment>


                            )}
                </Slider>
            </Container> */}

      {/* <div className="cards-block">
//       <h1 className="text-center">Trending Section</h1>
//       <div className="cards">
//         <Cards />
//         <Cards />
//         <Cards />
//         <Cards />
//       </div>
//       </div> */}
      <div>
        <LatestCollection />
      </div>
      <div>
        <DealofDay />
      </div>

      <div>
        <HomeCards />
      </div>

      <div>
        <Electronics />
      </div>
      <div>
        <Jewellery />
      </div>
      <div>
        <Fashion />
      </div>
    </div>
  );
}

export default Home;
