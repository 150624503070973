import React, { useState, useEffect, useRef, useContext } from "react";
import { Form, Spinner } from "react-bootstrap";

import "./RegOtp.css";
import axios from "axios";
import AppContext from "../../context/AppContext";
import { useNavigate } from 'react-router-dom'
import { Config } from '../../Config/Config'

const RegOtp = () => {

	const navigate = useNavigate();

	//--collect AppContext data--

	const context = useContext(AppContext);

		// --create ref for otp field--

	const firstotp = useRef(null);
	const secondotp = useRef(null);
	const thirdotp = useRef(null);
	const forthotp = useRef(null);

//--initializing states --

	const [otp1, setOtp1] = useState("");
	const [otp2, setOtp2] = useState("");
	const [otp3, setOtp3] = useState("");
	const [otp4, setOtp4] = useState("");
	const [combineOTP, setcombineOTP] = useState("");

//--getting handelChange data--

	useEffect(() => {
		console.log('combineOTP', combineOTP, otp1, otp2, otp3, otp4)
		handleChangemain()
	}, [combineOTP])


	const handleChange1 = (e) => {

		e.persist();
		console.log("data", context);
		console.log("input otp1", otp1);
		setOtp1(e.target.value);
		setcombineOTP(combineOTP + e.target.value);
		if (otp1.length == 1) {
			secondotp.current.focus()
		}
		// handleChangemain()
	}
	const handleChange2 = (e) => {

		e.persist();
		console.log("input otp2", otp2);
		setOtp2(e.target.value)
		setcombineOTP(combineOTP + e.target.value);
		if (otp2.length === null) {
			secondotp.current.focus()
		} else {
			thirdotp.current.focus()
		}
		// handleChangemain()
	}
	const handleChange3 = (e) => {

		e.persist();
		console.log("input otp3", otp3);
		setOtp3(e.target.value)
		setcombineOTP(combineOTP + e.target.value);
		if (otp3.length === null) {
			thirdotp.current.focus()
		} else {
			forthotp.current.focus()
		}
		// handleChangemain()
	}
	const handleChange4 = (e) => {

		e.persist();
		console.log("input otp4", otp4);
		setOtp4(e.target.value)
		setcombineOTP(combineOTP + e.target.value);
		// handleChangemain()
	}
	const handleChangemain = (e) => {
		const newotp = (otp1 + otp2 + otp3 + otp4)
		console.log("after submit", newotp);
		if (newotp.length === 4) {

			//--post form data--

			let formData = new FormData()

			formData.append("otp", newotp)
			formData.append("mobile", context.customerMobile)
			console.log("otp", combineOTP);

			axios.post(`${Config.USER_OTPVERIFY}`, formData)
				.then(res => {
					// console.log(newotp);
					console.log("axios responce", res.data);
					if (res.data.is_success === true) {
						navigate('/')
						window.localStorage.setItem("TokenValue", JSON.stringify(res.data.data.access_token));
						window.localStorage.setItem("CustomerData", JSON.stringify(res.data));
						context.setCustomerDetail(JSON.stringify(res.data.data))
						context.setCustomerCode(JSON.stringify(res.data.data.cust_code))

						console.log("code",context.customerCode)

					} else if (res.data.is_success === false) {
						alert("Otp does not match")
					}
				})
				.catch(err => { console.log("axios error", err) })
		} else if (newotp.length < 4) {
			console.log("Error otp");
		}
	}

// --loader & counter--

	const [show, setShow] = useState(false);

	const [loader, setLoader] = useState(false);

	let [counter, setCounter] = useState(10);
	useEffect(() => {
		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		if (counter === 0) {
			setShow(true)
		}
		return () => clearInterval(timer);
	}, [counter])

	useEffect(() => {
		firstotp.current.focus()
	}, [])

	const submitHandleresendotp = (e) => {
		e.preventDefault();
		setCounter(10)

		setLoader(true)

		setShow(false)

		//--resend otp--

		let formData = new FormData()

		formData.append("mobile", context.customerMobile)

		axios.post(`${Config.RESEND_OTP}`, formData)
			.then(res => {
				console.log("axios responce", res.data);
				if (res.data.is_success === "success") {
					setLoader(false)
				}
				setLoader(false)
			})
			.catch(err => {
				console.log("axios error", err)
				setLoader(false)
			})

	}


	return (

		<div className="otp" >
			<div>
				<img src="../../../image/signin.webp" className="otp-img" />
			</div>
			<div className="otp-form">
				<Form
					onSubmit={submitHandleresendotp}
				>
					<img src="../../../image/logo.png" className="otp-logo" />
					<h3 className="otp-h">Welcome Back!</h3>

					<label className="otp-text">Now please enter your otp</label>
					<br />
					<br />
					<div className="otp-box">
						<input
							type="text"
							maxLength="1"
							name="otp1"
							ref={firstotp}
							autoFocus
							onChange={handleChange1}
							className="otp-box-input"
						/>
						<input
							type="text"
							maxLength="1"
							name="otp2"
							ref={secondotp}
							autoFocus
							onChange={handleChange2}
							className="otp-box-input"
						/>
						<input
							type="text"
							maxLength="1"
							name="otp3"
							ref={thirdotp}
							autoFocus
							onChange={handleChange3}
							className="otp-box-input"
						/>
						<input
							type="text"
							maxLength="1"
							name="otp4"
							ref={forthotp}
							autoFocus
							onChange={handleChange4}
							className="otp-box-input"
						/>

					</div>
					<br />
					{show ? " "
						:
						<p>Resend OTP in {counter} sec</p>
					}


					{loader ?

						<button type="submit"
							disabled
							className="otp-submit-disable"
						>
							<Spinner animation="border" />
						</button>

						: <>
							{
								show ?
									<button type="submit"
										className="otp-submit"

									>
										Resend OTP
									</button>
									:
									<button
										disabled
										className="otp-submit-disable"
									>
										Resend OTP
									</button>}
						</>
					}
				</Form>
			</div>
		</div >

	);
}

export default RegOtp;
