import React, { useState, useEffect } from "react";
import "./Terms.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { Config } from "../../Config/Config";
import ReactDOM from "react-dom/client";

function Terms() {
  const [alldata, setAllData] = useState([]);

  useEffect(() => {
    axios
      .get(`${Config.GET_TERM_CONDITION}`)
      .then((res) => {
        // console.log("axiois data", res.data);
        setAllData(res.data);
      })
      .catch((err) => {
        console.log("axios error", err);
      });
  }, []);

  const renderList = () => {
    if (alldata.length == 0) {
      return null;
    }

    return alldata.map((d, index) => {
    //   console.warn("inside", alldata);
      return (
        <div key={index}>
          <h5>{d.policy_type}</h5>
          <div dangerouslySetInnerHTML={{__html: d.content}} />
        </div>
      );
    });
  };

  return (
    <div className="terms">
      <div className="terms-header">
        <h2 className="terms-text">terms & Condition</h2>
        <Container className="terms-subtext">
          <div className="terms-icon">
            <i className="fa fa-home" aria-hidden="true"></i>
          </div>
          <div>
            <Link to="" className="terms-subtext-link1">
              Home
            </Link>
          </div>
          <div className="terms-icon">
            <i className="fa fa-angle-right" aria-hidden="true"></i>
          </div>

          <div>
            <Link to="" className="terms-subtext-link2">
              terms
            </Link>
          </div>
        </Container>
      </div>
      <Container className="terms-text-container">
        {/* <h5>{alldata.policy_type}</h5> */}
        {/* <div>
          <div dangerouslySetInnerHTML={{ __html: alldata.content }} />
        </div> */}

        {renderList()}
        {/* <div>
					<h5 className="terms-container-text-h">Last updated: February 18, 2021</h5>
					<p className="terms-container-text">
						“BoroBazar” is a trademark of BoroBazar Private Limited
						(“Company”), a company incorporated under the Companies Act,
						2013 with its registered and corporate office at Plot 64H,
						Sector 18, Gudgeon 122001 in the course of its business. The
						domain name grocers.com is owned by the Company.
					</p>
					<p className="terms-container-text">
						If you are a California resident, the information below also
						applies to you. Certain terms used in this section have the
						meanings given to them in the California Consumer Privacy Act
						of 2018 (“CCPA”).
					</p>
					<p className="terms-container-text">
						Customer are advised to read and understand our Privacy terms
						carefully, as by accessing the website/app you agree to be
						bound by the terms and conditions of the Privacy terms and
						consent to the collection, storage and use of information
						relating to you as provided herein.
					</p>
					<p className="terms-container-text">
						If you do not agree with the terms and conditions of our
						Privacy terms, including in relation to the manner of
						collection or use of your information, please do not use or
						access the website/app.
					</p>
					<p className="terms-container-text">
						Our Privacy terms is incorporated into the terms and
						Conditions of Use of the website/app, and is subject to change
						from time to time without notice. It is strongly recommended
						that you periodically review our Privacy terms as posted on
						the App/Web.
                    </p>
                    <p className="terms-container-text">
					Should you have any clarifications regarding this Privacy terms,
					please do not hesitate to contact us at &nbsp;
					<Link to="" className="terms-link">
						info@borobazar.com
					</Link>
                        &nbsp;.
                        </p> 
				</div>
				<div>
					<h5 className="terms-container-text-h">
						The collection, Storage and Use of Information Related to You
					</h5>
					<p className="terms-container-text">
						We may automatically track certain information about you based
						upon your behavior on the website. We use this information to
						do internal research on our users’ demographics, interests,
						and behavior to better understand, protect and serve our
						users. This information is compiled and analyzed on an
						aggregated basis. This information may include the URL that
						you just came from (whether this URL is on the website or
						not), which URL you next go to (whether this URL is on the
						website or not), your computer browser information, your IP
						address, and other information associated with your
						interaction with the website.
						<strong>
							We may also share your Mobile IP/Device IP with third
							party(ies) and to the best of our knowledge, be-life and
							representations given to us by these third party(ies) this
							information is not stored.
						</strong>
					</p>
					<ol type="1">
						<li className="terms-container-text">
							Identifiers (e.g. name, mailing address, email address,
							phone number, credit/debit card number)
						</li>
						<li className="terms-container-text">
							Characteristics of protected classNameifications (e.g. gender,
							age)
						</li>
						<li className="terms-container-text">
							Commercial information (e.g. products or services
							purchased, purchase history)
						</li>
						<li className="terms-container-text">
							Internet or other electronic network activity (e.g. browse
							or search history)
						</li>
						<li className="terms-container-text">
							Geo location data (e.g. latitude or longitude)
						</li>
						<li className="terms-container-text">
							Audio, electronic, visual, or similar information (e.g.
							recording of Guest service calls)
						</li>
						<li className="terms-container-text">
							Inferences drawn from any of the above (e.g. preferences or
							characteristics)
						</li>
					</ol>
				</div>

				<div>
					<h5 className="terms-container-text-h">
						Choices Available Regarding Collection, Use and Distribution
						of Information
					</h5>
					<p className="terms-container-text">
						To protect against the loss, misuse and alteration of the
						information under its control, the Company has in place
						appropriate physical, electronic and managerial procedures.
						For example, the Company servers are accessible only to
						authorized personnel and your information is shared with
						employees and authorized personnel on a need to know basis to
						complete the transaction and to provide the services requested
						by you. Although the Company endeavour to safeguard the
						confidentiality of your personally identifiable information,
						transmissions made by means of the Internet cannot be made
						absolutely secure. By using the website, you agree that the
						Company will have no liability for disclosure of your
						information due to errors in transmission and/or unauthorized
						acts of third parties.
					</p>
					<p className="terms-container-text">
						Please note that the Company will not ask you to share any
						sensitive data or information via email or telephone. If you
						receive any such request by email or telephone, please do not
						respond/divulge any sensitive data or information and forward
						the information relating to the same to &nbsp;
					<Link to="" className="terms-link">
						info@borobazar.com
					</Link>
					&nbsp; for
						necessary action.
                    </p>
                    <ol type="1">
									<li className="terms-container-text">
										Performing services, including maintaining or
										servicing accounts, providing customer service,
										processing or fulfilling orders and transactions,
										verifying customer information, processing
										payments, providing advertising or marketing
										services, providing analytics services, or
										providing similar services;
									</li>
									<li className="terms-container-text">
										Auditing related to a current interaction with you
										and concurrent transactions, including, but not
										limited to, counting ad impressions to unique
										visitors, verifying positioning and quality of ad
										impressions, and auditing compliance;
									</li>
									<li className="terms-container-text">
										Short-term, transient use, including, but not
										limited to, the contextual customization of ads
										shown as part of the same interaction;
									</li>
									<li className="terms-container-text">
										Detecting security incidents, protecting against
										malicious, deceptive, fraudulent, or illegal
										activity, and prosecuting those responsible for
										that activity;
									</li>
									<li className="terms-container-text">
										Debugging to identify and repair errors that
										impair existing intended functionality;
									</li>
									<li className="terms-container-text">
										Undertaking internal research for technological
										development and demonstration; and
									</li>
									<li className="terms-container-text"> 
										Undertaking activities to verify or maintain the
										quality or safety of a service or device that is
										owned, manufactured, manufactured for, or
										controlled by us, and to improve, upgrade, or
										enhance the service or device that is owned,
										manufactured, manufactured for, or controlled by
										us.
									</li>
                    </ol>
                    
				</div> */}
      </Container>
    </div>
  );
}

export default Terms;
