import React, { useState, useRef, useEffect } from "react";
import "./Header.css";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  DropdownButton,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import axios from "axios";

import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Config } from "../../Config/Config";
import { Button } from "bootstrap";
import { ManOutlined } from "@mui/icons-material";
import { Divider, InputBase, Menu, MenuItem } from "@mui/material";

import {
  getData,
  getLocalStorage,
} from "../DataFromLocalStorage/LocalstorageData";

function Header() {
  //--countdown--

  const navigate = useNavigate();

  const LoggingOut = () => {
    window.localStorage.clear();
    navigate("/");
  };

  const isAuth = window.localStorage.getItem("TokenValue");

  const [submenu, setSubMenu] = useState([]);
  const [homemenu, setHomeMenu] = useState([]);
  const [homelogo, setHomelogo] = useState([]);

  useEffect((e) => {
    axios
      .get(`${Config.FOOTER_DETAILS}`)
      .then((res) => {
        // console.log("homelogo", res.data.footer_logo);
        setHomelogo(res.data.footer_logo);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${Config.HEADER_MENU}`)
      .then((res) => {
        // console.log("header manu", res.data[0].content);
        // console.log("header manu type", typeof res.data[0].content);
        const main = JSON.parse(res.data[0].content).filter((item) => {
          if (item.parent_id == 0) {
            return item;
          }
        });

        setHomeMenu(main);
        // setAllMenu(JSON.parse(res.data[0].content))
        // console.log(allmenu);
        const submain = JSON.parse(res.data[0].content).filter((item) => {
          if (item.parent_id != "0") {
            return item;
          }
        });
        // console.log(submain);
        setSubMenu(submain);
      })

      .catch((err) => {
        console.log("Axios error", err);
      });
  }, []);

  let [countersec, setCountersec] = useState(59);
  useEffect(() => {
    const timer =
      countersec > 0 && setInterval(() => setCountersec(countersec - 1), 1000);
    if (countersec === 0) {
      setCountersec(59);
    }
    return () => clearInterval(timer);
  }, [countersec]);

  let [countermin, setCountermin] = useState(59);
  useEffect(() => {
    const timer =
      countermin > 0 && setInterval(() => setCountermin(countermin - 1), 60000);
    if (countermin === 0) {
      setCountermin(59);
    }
    return () => clearInterval(timer);
  }, [countermin]);

  let [counterhur, setCounterhur] = useState(6);
  useEffect(() => {
    const timer =
      counterhur > 0 &&
      setInterval(() => setCounterhur(counterhur - 1), 3600000);
    if (counterhur === 0) {
      setCounterhur(6);
    }
    return () => clearInterval(timer);
  }, [counterhur]);

  const [show, setShow] = useState(true);

  //--search items--
  const [itemshow, setItemshow] = useState(false);
  const [searchitem, setSearchItem] = useState([]);
  const [searchterm, setSearchTerm] = useState("");
  const [userdata, SetUserData] = useState(undefined);
  const [custcode, setCustcode] = useState(undefined);
  const [loader, setLoader] = useState(false);
  const [totalwishlistitem, setTotalwishlistitem] = useState("");
  const [totalcartitem, setTotalcartitem] = useState("");
  const [username, setUserName] = useState("");


  useEffect(() => {
    try {
      const arrayOfData = localStorage.getItem("CustomerData");
      const d = arrayOfData !== null ? JSON.parse(arrayOfData) : [];
      // console.log('code data', d.data.cust_code)
      // console.log('access_token', d.data.first_name)
      SetUserData(d.data);
      setCustcode(d.data.cust_code);

      let formData = new FormData();

      formData.append("cust_code", d.data.cust_code);
  
      axios
        .post(`${Config.PERSONAL_INFORMATION}`, formData)
        .then((res) => {
          setTotalwishlistitem(res.data.total_item_in_wishlist);
          setTotalcartitem(res.data.total_item_in_cart);
          setUserName(res.data.first_name);
        })
        .catch((err) => {
          // console.log("PERSONAL_INFORMATION", err);
        });
    } catch (error) {
      // console.log('is error', error);
    }
  }, []);

  const handlesearchshow = () => {
    setItemshow(false);
  };
  useEffect(() => {
    if (searchterm.length > 0) {
      setItemshow(true);
    } else {
      setItemshow(false);
    }
    // console.log(searchterm.length);
    // console.log(searchterm);
    axios
      .get(`${Config.SEARCH_ITEM}`)
      .then((res) => {
        // console.log("axios responce", res.data, typeof res.data);
        setSearchItem(res.data);
        // console.log("-------------all data searchitem ----------", searchitem);
      })
      .catch((err) => {
        // console.log("Axios error", err);
      });
  }, [searchterm]);


  return (
    // --top countdown bar--
    <>
      <div>
        {show ? (
          <div className="header-countdown">
            <Row>
              <Col sm={1}></Col>
              <Col className="header-countdown-main">
                <div className="header-countdown-text">
                  <div className="delivery-box">
                    <img
                      src="../../../../image/delivery-box.svg"
                      className="delivery-box-logo"
                    />
                  </div>
                  <p className="header-p">
                    Claim your online{" "}
                    <strong>FREE Delivery or Shipping </strong>today! Expires in
                  </p>
                </div>

                <div className="header-timer">
                  <section>
                    <p className="time"> 0{counterhur} </p>
                  </section>
                  <p className="time-p">:</p>
                  <section>
                    <p className="time">{countermin} </p>
                  </section>
                  <p className="time-p">:</p>
                  <section>
                    <p className="time">{countersec}</p>
                  </section>
                </div>
              </Col>
              <Col sm={1} className="header-countdown-cross">
                <div>
                  <button className="header-Btn" onClick={() => setShow(false)}>
                    <i className="fa fa-times cross" aria-hidden="true"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>

      {/* --Middle search bar--  */}

      <div className="header-search">
        <Container className="header-search-cont">
          <Col xs={1}>
            <div className="">
              <Link to="/home">
                <img
                  src={homelogo}
                  // src='../../../../image/header-logo.png'
                  className="header-search-company-logo"
                />
              </Link>
            </div>
          </Col>
          <Col xs={6}>
            <div className="header-search-bar">
              <input
                type="search"
                placeholder="What are you looking..."
                className="header-search-bar-input"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {itemshow ? (
                <div className="home-search-items">
                  {
                    // searchterm > 0 ?
                    searchitem
                      .filter((search) => {
                        if (searchterm === "") {
                          return search;
                        } else if (
                          search.title
                            .toLowerCase()
                            .includes(searchterm.toLocaleLowerCase())
                        ) {
                          return search;
                        }
                      })
                      .map((sear) => {
                        return (
                          <React.Fragment key={sear.id}>
                            <Link
                              to={`/${sear.slug}/${sear.id}`}
                              className="search-name-link"
                              onClick={handlesearchshow}
                            >
                              <p>{sear.title}</p>
                            </Link>
                          </React.Fragment>
                        );
                      })

                    // : null
                  }
                </div>
              ) : null}
            </div>
          </Col>

          <Col xs={2}>
            <div className=" header-search-logo">
              {isAuth ? (
                <>
                  <div className="logos-header">
                    <a
                      className=" header-search-logo-btn  logo-icons"
                      href="/cart"
                    >
                      <ShoppingBagOutlinedIcon />
                      <div className="header-search-logo-btn-countr">
                        <p>{totalcartitem}</p>
                      </div>
                    </a>
                  </div>
                  <div className="logos-header">
                    <a
                      className=" header-search-logo-btn    logo-icons"
                      href="/myaccount/wishlist"
                    >
                      <FavoriteBorderOutlinedIcon />
                      <div className="header-search-logo-btn-countr">
                        <p>{totalwishlistitem}</p>
                      </div>
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div className="logos-header">
                    <a
                      className=" header-search-logo-btn  logo-icons"
                      href="/cart"
                    >
                      <ShoppingBagOutlinedIcon />
                      {/* <div className="header-search-logo-btn-countr">
                        <p>0</p>
                      </div> */}
                    </a>
                  </div>
                  <div className="logos-header">
                    <a
                      className=" header-search-logo-btn    logo-icons"
                      href="/myaccount/wishlist"
                    >
                      <FavoriteBorderOutlinedIcon />
                      {/* <div className="header-search-logo-btn-countr">
                        <p>0</p>
                      </div> */}
                    </a>
                  </div>
                </>
              )}

              <div>
                {isAuth ? (
                  <div class="dropdown  ">
                    <button class="dropbtn accountname">Hi {username}</button>
                    <div class="dropdown-content">
                      <a href="/myaccount">My Account</a>
                      <a href="/myorder">My Order</a>
                      <a onClick={LoggingOut} className="logout">
                        Log Out
                      </a>
                    </div>
                  </div>
                ) : (
                  <button className=" header-search-logo-btn">
                    <Link to="/signin" className="header-search-logo-btn-link">
                      <AccountCircleOutlinedIcon /> Sign In
                    </Link>
                  </button>
                )}
              </div>
            </div>
          </Col>
        </Container>
      </div>

      {/* --nav bar-- */}

      <div className="navfixed">
        <Navbar className="nav-outer">
          <Nav className="me-auto">
            {/* <Nav.Link as={Link} to="/" className='nav-head-1'>Home</Nav.Link> */}

            {homemenu.map((d) => {
              return (
                <React.Fragment key={d.id}>
                  {/* <NavDropdown title={d.label} id="basic-nav-dropdown" className='nav-head'> */}
                  <div class="dropdown nav-head">
                    <Link to={`/${d.label}`}>
                      <button class="dropbtn ">{d.label}</button>
                    </Link>
                    <div class="dropdown-content">
                      {submenu.map((k) => {
                        return d.id == k.parent_id ? (
                          // <NavDropdown.Item >{k.label}</NavDropdown.Item>

                          // <a>{k.label}</a>
                          <Link to={`/category/${k.label}`}>
                            <p>{k.label}</p>
                          </Link>
                        ) : null;
                      })}
                    </div>
                    {/* </NavDropdown> */}
                  </div>
                </React.Fragment>
              );
            })}
            {/* <Nav.Link as={Link} to="/products" className='nav-head-2'>Fashion</Nav.Link>
                        <Nav.Link as={Link} to="/getvalu" className='nav-head'>Mobiles</Nav.Link>

                        <Nav.Link as={Link} to="/" className='nav-head'>Electronics
                        </Nav.Link>
                        <Nav.Link as={Link} to="/" className='nav-head'>Jewellery</Nav.Link> */}

            {/* <NavDropdown title="Categories" id="basic-nav-dropdown" className='nav-head'>
                            <NavDropdown.Item >Fresh Vegetable</NavDropdown.Item>
                            <NavDropdown.Item >Diet Nutrition</NavDropdown.Item>
                            <NavDropdown.Item >Healthy Food</NavDropdown.Item>
                            <NavDropdown.Item>Grocery Item</NavDropdown.Item>
                            <NavDropdown.Item >Beaf Steak</NavDropdown.Item>
                        </NavDropdown>



                        <NavDropdown title="Dietary" id="basic-nav-dropdown" className='nav-head'>
                            <NavDropdown.Item >Vegitarian</NavDropdown.Item>
                            <NavDropdown.Item >Kakogenic</NavDropdown.Item>
                            <NavDropdown.Item >Mediterranean</NavDropdown.Item>
                            <NavDropdown.Item >Organic</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link as={Link} to="/" className='nav-head'>Search</Nav.Link>
                        <Nav.Link as={Link} to="/" className='nav-head'>Top Offers</Nav.Link>
                        <Nav.Link as={Link} to="/" className='nav-head'>Shops</Nav.Link> */}

            {/* <NavDropdown title="More" id="basic-nav-dropdown" className='nav-head'> */}
            <div class="dropdown nav-head">
              <button class="dropbtn ">More</button>
              <div class="dropdown-content">
                {["start"].map((direction) => (
                  <DropdownButton
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    drop={direction}
                    title={` User `}
                    className="nav-sidedrop"
                  >
                    <Dropdown.Item eventKey="1" href="/myaccount/:acc-setting">
                      My Account
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2" href="/signup">
                      Sign Up{" "}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" href="/signin">
                      Sign In
                    </Dropdown.Item>
                  </DropdownButton>
                ))}
                <a href="/faq">FAQ</a>
                <a href="/about">About us</a>
                <a href="/policy">Private Policy</a>
                <a href="/terms">Terms & Conditions</a>
                <a href="/contact">Contact Us</a>
                <a href="/checkout">Checkout</a>

                {/* <NavDropdown.Item href='/faq'>FAQ</NavDropdown.Item>
                            <NavDropdown.Item href='/about'>About us</NavDropdown.Item>
                            <NavDropdown.Item href='/policy'>Private Policy</NavDropdown.Item>
                            <NavDropdown.Item href='/terms'>Terms & Conditions</NavDropdown.Item>
                            <NavDropdown.Item href='/contact'>Contact Us</NavDropdown.Item>
                            <NavDropdown.Item href='/checkout'>Checkout</NavDropdown.Item>
                            <NavDropdown.Item href='/notfound'>404</NavDropdown.Item>
                        </NavDropdown>

                    */}
              </div>
            </div>
          </Nav>
          <Nav.Link as={Link} to="/" className="track">
            {" "}
            <FmdGoodOutlinedIcon className="track-icon" /> Track Order
          </Nav.Link>
        </Navbar>
      </div>
    </>
  );
}

export default Header;
