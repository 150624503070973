import React from 'react'
import Slider from 'react-slick/lib/slider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Card } from "react-bootstrap";
import './Demo2.css'


const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} >
            <ArrowForwardIosIcon />
        </div>
    )
}

const PreBtn = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} >
            <ArrowBackIosIcon />
        </div>
    )
}


const Demo2 = () => {
  return (
      <div className="slide-cards">
          <Slider
              prevArrow={<PreBtn />}
              nextArrow={<NextBtn />}
              slidesToShow={3}
              slidesToScroll={1}
              infinite={false}

          >

          <div className="cards">
                <Card>
						<Card.Img
							className="card-imge"
							variant="top"
							src="../../../../image/caro-img4.webp"
						/>
						<Card.Body>
							<Card.Title>Card Title</Card.Title>
							<Card.Text>
								Some quick example text to build on the card title
								
							</Card.Text>
						</Card.Body>
					</Card>
              </div>
              
              <div className="cards">
					<Card>
						<Card.Img
							className="card-imge"
							variant="top"
							src="../../../../image/caro-img1.webp"
						/>
						<Card.Body>
							<Card.Title>Card Title</Card.Title>
							<Card.Text>
								Some quick example text to build on the card title 
							</Card.Text>
						</Card.Body>
					</Card>
              </div>
              
              <div className="cards">
					<Card>
						<Card.Img
							className="card-imge"
							variant="top"
							src="../../../../image/caro-img2.webp"
						/>
						<Card.Body>
							<Card.Title>Card Title</Card.Title>
							<Card.Text>
								Some quick example text to build on the card title
							</Card.Text>
						</Card.Body>
					</Card>
              </div>
              
              <div className="cards">
					<Card>
						<Card.Img
							variant="top"
							src="../../../../image/caro-img3.webp"
							className="card-imge"
						/>
						<Card.Body>
							<Card.Title>Card Title</Card.Title>
							<Card.Text>
								Some quick example text to build on the card title
							</Card.Text>
						</Card.Body>
					</Card>
              </div>
              
          </Slider>
    </div>
  )
}

export default Demo2;