import React, { useState, useEffect, useRef } from "react";
import "./ProductDetail.css";
import prodData from "../products.json";
import {
  Row,
  Card,
  Container,
  Carousel,
  Spinner,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { useParams, Link, useNavigate } from "react-router-dom";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
import Zoom from "react-img-zoom";
import axios from "axios";
import { Helmet } from "react-helmet";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Config } from "../../Config/Config";
import {
  getData,
  getLocalStorage,
} from "../DataFromLocalStorage/LocalstorageData";

const ProductDetail = () => {
  const navigate = useNavigate();
  const value = useParams();
  // console.log("value in prod-detail", value);

  const [favicon, setFavIcon] = useState(true);
  const [prodDetail, setProdDetail] = useState([]);
  const [variable, setVariable] = useState(false);
  const [loader, setloader] = useState(false);
  const [galeryimg, setGaleryimg] = useState([]);
  const [sliderdata, setSliderdata] = useState(null);
  const [showbtns, setShowbtns] = useState(false);
  const [nowishlistdata, setNoWishlistdata] = useState(false);
  const [wishlistdata, setWishlistdata] = useState([]);
  const [newdata, setNewdata] = useState([]);
  const [show, setShow] = useState(false);
  const [createshow, setCreateShow] = useState(false);
  const [accesskey, setAccesskey] = useState("");
  const [inputstate, setInputstate] = useState("");
  const [wistlistloader, setWistlistloader] = useState(false);
  const [productcode, setProductcode] = useState("");
  const [userdata, SetUserData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [colorid, setColorid] = useState("");
  const [colors, setColors] = useState("");
  const [size, setSize] = useState("");
  const [saleprice, setSaleprice] = useState("");
  const [mrpprice, setMrpprice] = useState("");
  const [discount, setDiscount] = useState("");
  const [displayprod, setDisplayprod] = useState("");
  const [availablesize, setAvailablesize] = useState("");
  const [loadsize, setLoadsize] = useState(true);
  const [activecolor, setActivecolor] = useState("");
  const [activesize, setActivesize] = useState("");
  const [total_stock, setTotal_stock] = useState(true);

  const [nonvar_prod, setNonvar_prod] = useState("");

  const url_prod = Config.PRODUCT_DETAILS + value.pid;
  // console.log('......url.......', url_prod)

  useEffect(() => {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log(this.responseText);
        console.log(JSON.parse(this.responseText));

        setProdDetail(JSON.parse(this.responseText));
        setGaleryimg(prodDetail.gallery_images);
        setSliderdata(prodDetail.gallery_images["0"]);
        if(prodDetail.stocking_quantity > 0){
          setTotal_stock(false)
          console.log("stock",prodDetail.stocking_quantity)
        }

        // console.log(
        //   ".............galeryimg........",
        //   prodDetail.gallery_images
        // );
        console.log("stock",prodDetail.stocking_quantity)
        console.log(
          ".............product_varient........",
          prodDetail.product_varient
        );
        // if (prodDetail.product_varient == null) {
        //   setVariable(false);
        // }
        // console.log(".............variable........", variable);    
        if (prodDetail.is_variable == 1) {
          setVariable(true);
          const firstitem = prodDetail.product_varient.find(
            (data) => data.variant_type_id_1 == 1
          );
          setColorid(firstitem.variant_type_id_1);
          setActivecolor(firstitem.variant_type_id_1);
  // console.log("1st varient stock",firstitem.stocking_quantity)
          const firstsize = prodDetail.product_varient.filter(
            (data) => data.variant_type_id_1 == 1
          );
          if (firstsize.length > 0) {
            setLoadsize(false);
          }
          // console.log("firstitem/................", firstitem);
          // console.log("firstsize/................", firstsize);
          setSaleprice(Math.trunc(firstitem.final_amount));
          setMrpprice(Math.trunc(firstitem.mrp));
          setDiscount(Math.trunc(firstitem.discount));
          setDisplayprod(firstitem);
          // console.log("display prod 1st time", displayprod);
  
          setAvailablesize(firstsize);
          setActivesize(firstsize[0].variant_type_id_2);
          setloader(true);
        }
        setloader(true);
      }
    });

    xhr.open("GET", `${url_prod}`);
    xhr.setRequestHeader("Cache-Control", "no-store");

    xhr.send();
  }, [prodDetail.length]);

  const handleClickimage = (index) => {
    console.log(index);
    const slider = prodDetail.gallery_images[index];
    setSliderdata(slider);
  };

  useEffect(() => {
    try {
      const d = getLocalStorage("CustomerData");
      SetUserData(d.data.cust_code);
      setAccesskey(d.data.access_token);
      // console.log(">....", d.data.cust_code)
    } catch (error) {
      // console.log('is error', error);
    }
  }, []);

  const wishlist_url = Config.PRODUCT_WISHLIST + userdata;

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    console.log("product code", e);
    setProductcode(e);
    setShow(true);

    axios
      .get(`${wishlist_url}`)
      .then((res) => {
        console.log("axios response wishlist", res.data);
        console.log("axios response ", res.data.length);
        const xyz = res.data;
        setWishlistdata(xyz);
        setNewdata(res.data.wishlistData);
        if(res.data.wishlistData.length > 0){
          setNoWishlistdata(true)
        }
        setWistlistloader(true);
        console.log("axios response fash", wishlistdata);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setWistlistloader(true);
        // console.log("axios error elec", err)
      });
  };

  const handleCreate = () => {
    setShow(false);
    setCreateShow(true);
  };
  const handleCreateoff = () => {
    setShow(false);
    setCreateShow(false);
  };

  const handleChange = (e) => {
    console.log("....", e.target.value);
    setInputstate(e.target.value);
  };

  const handleAdd = () => {
    let formData = new FormData();

    formData.append("cust_code", userdata);
    formData.append("access_key", accesskey);
    formData.append("is_private", "0");
    formData.append("is_default", wishlistdata.length > 0 ? "0" : "1");
    formData.append("label", inputstate);

    axios
      .post(`${Config.CREATE_WISHLIST}`, formData)
      .then((res) => {
        console.log("............000.....", res.data);
        if (res.data.check == "success") {
          axios
            .get(`${wishlist_url}`)
            .then((res) => {
              console.log("axios response wishlist", res.data);
              console.log("axios response ", res.data.length);
              const xyz = res.data;
              setWishlistdata(xyz);
              setNewdata(res.data.wishlistData);
              if(res.data.wishlistData.length > 0){
                setNoWishlistdata(true)
              }
              setWistlistloader(true);
              console.log("axios response fash", wishlistdata);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              setWistlistloader(true);
              // console.log("axios error elec", err)
            });
        }
        alert("done");
      })
      .catch((err) => {
        console.log("............000.....", err.data);
        alert("fail");
      });

    setCreateShow(false);
    setShow(true);
  };
  const handleadditem = (e) => {
    console.log("........", e);

    let formData = new FormData();

    formData.append("cust_code", userdata);
    formData.append("access_key", accesskey);
    formData.append("wishlist_id", e);
    formData.append("type", "1");
    formData.append("product_code", productcode);

    axios
      .post(`${Config.ADD_WISHLIST}`, formData)
      .then((res) => {
        console.log("............000.....", res.data);
        alert("done");
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
          if (this.readyState === 4) {
            // console.log(this.responseText);

            setProdDetail(JSON.parse(this.responseText));
            setGaleryimg(prodDetail.gallery_images);
            setSliderdata(prodDetail.gallery_images["0"]);
            console.log(
              ".............galeryimg........",
              prodDetail.gallery_images
            );
            console.log(".............product........", prodDetail);
            // console.log(".............pVarientsOne........", prodDetail.product_varient.pVarientsOne);
            setloader(true);
            if (prodDetail.is_variable == 1) {
              setVariable(true);
              setloader(true);
            }
          }
        });

        xhr.open("GET", `${url_prod}`);
        xhr.setRequestHeader("Cache-Control", "no-store");

        xhr.send();
      })
      .catch((err) => {
        console.log("............000.....", err.data);
        alert("fail");
      });

    setShow(false);
  };

  const handledeleteitem = (e) => {
    console.log(e);

    let formData = new FormData();

    formData.append("cust_code", userdata);
    formData.append("access_key", accesskey);
    formData.append("product_code", e);
    formData.append("type", "0");

    axios
      .post(`${Config.REMOVE_WISHLIST_ITEM}`, formData)
      .then((res) => {
        console.log("............000.....", res.data);
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
          if (this.readyState === 4) {
            // console.log(this.responseText);

            setProdDetail(JSON.parse(this.responseText));
            setGaleryimg(prodDetail.gallery_images);
            setSliderdata(prodDetail.gallery_images["0"]);
            console.log(
              ".............galeryimg........",
              prodDetail.gallery_images
            );
            console.log(".............product........", prodDetail);
            setloader(true);
            if (prodDetail.is_variable == 1) {
              setVariable(true);
              setloader(true);
            }
          }
        });

        xhr.open("GET", `${url_prod}`);
        xhr.setRequestHeader("Cache-Control", "no-store");

        xhr.send();
        alert("remove done");
      })
      .catch((err) => {
        console.log("............000.....", err.data);
        alert("fail");
      });

    setShow(false);
  };

  const handleAddcart = (e, f) => {
    console.log("product data",e);
    console.log("qty", "1");
    console.log("cust_code", userdata);
    console.log("product id", `${e.variant_type_id_1},${e.variant_type_id_2}`);
    console.log("product code", f.code);
    console.log("product final_amount", e.final_amount);

    const productvar_id = `${e.variant_type_id_1},${e.variant_type_id_2}` ;
    console.log("productvar_id.................>",productvar_id);

    let formData = new FormData();

    formData.append("cust_code", userdata);
    formData.append("product code", f.code);

    formData.append("varient", `${e.variant_type_id_1},${e.variant_type_id_2}`);
    formData.append("price", e.final_amount);
    formData.append("qty", "1");

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log(this.responseText);
      }
    });

    xhr.open("POST", `${Config.ADD_CART}`);
    xhr.setRequestHeader("Cache-Control", "no-store");

    xhr.send(formData);

    // navigate("/cart");
  };
  const handleorder = (e, f) => {
    console.log("product data",e);
    console.log("qty", "1");
    console.log("cust_code", userdata);
    console.log("product id", `${e.variant_type_id_1},${e.variant_type_id_2}`);
    console.log("product code", f.code);
    console.log("product final_amount", e.final_amount);

    const productvar_id = `${e.variant_type_id_1},${e.variant_type_id_2}` ;
    console.log("productvar_id.................>",productvar_id);

    let formData = new FormData();

    formData.append("cust_code", userdata);
    formData.append("product code", f.code);

    formData.append("varient", `${e.variant_type_id_1},${e.variant_type_id_2}`);
    formData.append("price", e.final_amount);
    formData.append("qty", "1");

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log(this.responseText);
      }
    });

    xhr.open("POST", `${Config.ADD_CART}`);
    xhr.setRequestHeader("Cache-Control", "no-store");

    xhr.send(formData);

    // navigate("/checkout");
  };

  const handleColorChange = (e) => {
    console.log("color id", e);
    setColorid(e);
    setActivecolor(e);
    // setColors(prodDetail.product_varient.find((data) => data.variant_type_id_1 == e))
    const colo = prodDetail.product_varient.filter(
      (data) => data.variant_type_id_1 == e
    );
    console.log("////////./............color............", colo);
    setAvailablesize(colo);
    // if(e.length > 0){
    setSaleprice(Math.trunc(colo[0].final_amount));
    setMrpprice(Math.trunc(colo[0].mrp));
    setDiscount(Math.trunc(colo[0].discount));
    setDisplayprod(colo[0]);
    console.log("display prod 2nd time", displayprod);
    if (colo.length > 0) {
      setLoadsize(false);
    }
    // }
  };
  const handleSizeChange = (e) => {
    console.log("Size id", e);
    setActivesize(e);
    // setSize(prodDetail.product_varient.find((data) => data.variant_type_id_2 == e && data.variant_type_id_1 == colorid))

    const itemsize = prodDetail.product_varient.find(
      (data) => data.variant_type_id_2 == e && data.variant_type_id_1 == colorid
    );
    console.log("////////./............size............", itemsize);

    if (itemsize == undefined) {
      alert("item not available");
    }

    // if(e.length > 0){

    setSaleprice(Math.trunc(itemsize.final_amount));
    setMrpprice(Math.trunc(itemsize.mrp));
    setDiscount(Math.trunc(itemsize.discount));
    setDisplayprod(itemsize);
    console.log("display prod 3re time", displayprod);
    // }
  };

  return (
    <div className="subproduct">
      <Helmet>
        <title>{prodDetail.meta_title}</title>
        <meta
          name="description"
          property="og:descrip"
          content={prodDetail.meta_descrip}
        />
        <link
          rel="icon"
          type="image/png"
          href={prodDetail.meta_image}
          sizes="16x16"
        />
      </Helmet>

      {!variable ? (
        <div>
          {loader ? (
            <div className="product-outer-div">
              <div className="subproduct-img-div">
                {loader ? (
                  prodDetail.gallery_images.map((galimg, index) => (
                    <React.Fragment key={index}>
                      <img
                        src={Config.END_POINT + galimg.image}
                        // src={galimg.image_url}
                        className="subproduct-img-a subproduct-img"
                        alt="product_image"
                        onClick={() => handleClickimage(index)}
                      />
                    </React.Fragment>
                  ))
                ) : (
                  <Spinner animation="border" />
                )}
              </div>

              <div className="full-img">
                <div className="img-icon-flex">
                  <div>
                  
                    <img src={sliderdata.image_url} className="prod-full-img" />
                  </div>
                  <div>
                    {prodDetail.is_wishlisted === "1" ? (
                      <button
                        className="proddetail-wishlist-icon"
                        variant="primary"
                        onClick={() => {
                          handledeleteitem(prodDetail.code);
                        }}
                        value={prodDetail.id}
                      >
                        <i class="fa-solid fa-heart proddetail-wishlisted"></i>
                      </button>
                    ) : (
                      <button
                        className="proddetail-wishlist-icon"
                        variant="primary"
                        onClick={() => {
                          handleShow(prodDetail.code);
                        }}
                      >
                        <i class="fa-solid fa-heart proddetail-addwishlist"></i>
                      </button>
                    )}
                  </div>
                </div>
                {!total_stock ?
                <div className="prod-buy_cart-butn">
                 <Link to='/cart' className="prod-cards-link">
                  <button
                    className="subproduct-Wishlistbtn"
                    onClick={() => {
                      handleAddcart(displayprod, prodDetail);
                    }}
                  >
                    ADD TO CART
                  </button>
                  </Link>
                  <Link to="/checkout" className="prod-cards-link">
                    <button 
                    className="subproduct-buybtn"
                    onClick={() => {
                      handleorder(displayprod, prodDetail);
                    }}
                    >BUY NOW</button>
                  </Link>
                </div>
                :
                <button className="notify_me">Notify me</button>
                  }
              </div>
            
              <div className="subproduct-descrip">
                <div className="subproduct-descrip-name">
                  <h5>{prodDetail.title}</h5>

                  <div className="subprod-caro-wishlist">                   
                  </div>
                </div>

                    <div className="subproduct-price-div">
                      <div className="subproduct-saleprice">
                        <h3>₹{prodDetail.final_amount}</h3>
                      </div>
                      <div className="subproduct-mainprice">
                        <p>₹{prodDetail.mrp}</p>
                      </div>
                      <div>
                        <h6 className="subproduct-off">
                          {prodDetail.discount}% OFF
                        </h6>
                      </div>
                    </div>

                <h6>{prodDetail.unit}</h6>
                <div className="subproduct-descrip">
                  <p className="subproduct-descrip-topic">
                    {prodDetail.short_description.slice(0, 150)}
                  </p>
                  {loader ? (
                    <div>
                      {showbtns ? (
                        <div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: prodDetail.long_description,
                            }}
                          />
                          <button
                            onClick={(e) => {
                              setShowbtns(false);
                            }}
                            className="show-butns"
                          >
                            Show less
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={(e) => {
                            setShowbtns(true);
                          }}
                          className="show-butns"
                        >
                          ...Show More
                        </button>
                      )}
                    </div>
                  ) : (
                    <Spinner animation="border" />
                  )}
                </div>
              </div> 
              <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>My WishList</Modal.Title>
          </Modal.Header>
          {wistlistloader ? (
            <>
              <Modal.Body>
                {nowishlistdata ?
                <React.Fragment>
                {newdata.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <button className="cat-wishlist-name-butn">
                        <h5
                          className="cat-wishlist-name"
                          onClick={() => {
                            handleadditem(item.id);
                          }}
                        >
                          {item.label}
                        </h5>
                      </button>
                    </React.Fragment>
                  );
                })}
                </React.Fragment>
                : null}
              </Modal.Body>
              <Modal.Footer>
                <button onClick={handleCreate} className="cat-modal-save">
                  + Create WishList
                </button>
              </Modal.Footer>
            </>
          ) : (
            <Spinner animation="border" />
          )}
        </Modal>

              <div>
                <Modal show={createshow} onHide={handleCreateoff}>
                  <Modal.Header closeButton>
                    <Modal.Title>New WishList</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="modal-label">
                          Wishlist Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Wishlist Name"
                          autoFocus
                          className="modal-input"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer className="madal-footer">
                    <Button
                      variant="secondary"
                      onClick={handleCreateoff}
                      className="madal-footer-close"
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleAdd}
                      className="madal-footer-save"
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          ) : (
            <Spinner animation="border" />
          )}
        </div>


      ) : (
        <div>
          {loader ? (
            <div className="product-outer-div">
              <div className="subproduct-img-div">
                {loader ? (
                  prodDetail.gallery_images.map((galimg, index) => (
                    <React.Fragment key={index}>
                      <img
                        // src={Config.END_POINT + galimg.image}
                        src={galimg.image_url}
                        className="subproduct-img-a subproduct-img"
                        alt="product_image"
                        onClick={() => handleClickimage(index)}
                      />
                    </React.Fragment>
                  ))
                ) : (
                  <Spinner animation="border" />
                )}
              </div>

              <div className="full-img">
                <div className="img-icon-flex">
                  <div>
                    {/* <Zoom
                  img={sliderdata.image_url}
                  zoomScale={3}
                  width={400}
                  height={450}
                  className="prod-full-img"
                /> */}
                    <img src={sliderdata.image_url} className="prod-full-img" />
                  </div>
                  <div>
                    {prodDetail.is_wishlisted === "1" ? (
                      <button
                        className="proddetail-wishlist-icon"
                        variant="primary"
                        onClick={() => {
                          handledeleteitem(prodDetail.code);
                        }}
                        value={prodDetail.id}
                      >
                        <i class="fa-solid fa-heart proddetail-wishlisted"></i>
                      </button>
                    ) : (
                      <button
                        className="proddetail-wishlist-icon"
                        variant="primary"
                        onClick={() => {
                          handleShow(prodDetail.code);
                        }}
                      >
                        <i class="fa-solid fa-heart proddetail-addwishlist"></i>
                      </button>
                    )}
                  </div>
                </div>
                {!total_stock ?
                <div className="prod-buy_cart-butn">
                  <Link to='/cart' className="prod-cards-link">
                  <button
                    className="subproduct-Wishlistbtn"
                    onClick={() => {
                      handleAddcart(displayprod, prodDetail);
                    }}
                  >
                    ADD TO CART
                  </button>
                  </Link>
                  <Link to="/checkout" className="prod-cards-link">
                    <button 
                    className="subproduct-buybtn"
                     onClick={() => {
                      handleorder(displayprod, prodDetail);
                    }}
                    >BUY NOW</button>
                  </Link>
                </div>
                :
                <button className="notify_me">Notify me </button>
                  }
              </div>
              {/* <div>
              <Carousel className="subproduct-caro">
             
                <Carousel.Item>
                  <img
                    src={prodDetail.feature_image}
                    className="subproduct-caro-img"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={prodDetail.feature_image}
                    className="subproduct-caro-img"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={prodDetail.feature_image}
                    className="subproduct-caro-img"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
            <div>
              <button className="subproduct-Wishlistbtn">ADD TO CART</button>
              <Link to={`/checkout/`} className="prod-cards-link">
                <button className="subproduct-buybtn">BUY NOW</button>
              </Link>
            </div>
          </div> */}
              <div className="subproduct-descrip">
                <div className="subproduct-descrip-name">
                  <h5>{prodDetail.title}</h5>

                  <div className="subprod-caro-wishlist">
                    {/* {favicon ? (
                  <button
                    className="subprod-wishlist-icon"
                    onClick={() => setFavIcon(false)}
                  >
                    <FavoriteBorderOutlinedIcon className="" />
                  </button>
                ) : (
                  <button
                    className="subprod-wishlist-favicon"
                    onClick={() => setFavIcon(true)}
                  >
                    <FavoriteIcon className="" />
                  </button>
                )} */}
                  </div>
                </div>
                <>
                  {variable ? (
                    <div className="subproduct-price-div">
                      <div className="subproduct-saleprice">
                        <h3>₹{saleprice}</h3>
                      </div>
                      <div className="subproduct-mainprice">
                        <p>₹{mrpprice}</p>
                      </div>
                      <div>
                        <h6 className="subproduct-off">{discount}% OFF</h6>
                      </div>
                    </div>
                  ) : (
                    <div className="subproduct-price-div">
                      <div className="subproduct-saleprice">
                        <h3>₹{prodDetail.final_amount}</h3>
                      </div>
                      <div className="subproduct-mainprice">
                        <p>₹{prodDetail.mrp}</p>
                      </div>
                      <div>
                        <h6 className="subproduct-off">
                          {prodDetail.discount}% OFF
                        </h6>
                      </div>
                    </div>
                  )}
                </>

                <h6>{prodDetail.unit}</h6>
                <div className="subproduct-descrip">
                  <p className="subproduct-descrip-topic">
                    {prodDetail.short_description.slice(0, 150)}
                  </p>
                  {loader ? (
                    <div>
                      {showbtns ? (
                        <div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: prodDetail.long_description,
                            }}
                          />
                          <button
                            onClick={(e) => {
                              setShowbtns(false);
                            }}
                            className="show-butns"
                          >
                            Show less
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={(e) => {
                            setShowbtns(true);
                          }}
                          className="show-butns"
                        >
                          ...Show More
                        </button>
                      )}
                    </div>
                  ) : (
                    <Spinner animation="border" />
                  )}
                </div>
                {variable ? (
                  <>
                    {/* {prodDetail.varient.map((v, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div>
                        <p className="subproduct-descrip-topic"> {v} :</p>
                      </div>
                    </React.Fragment>
                  );
                })} */}
                    <div>
                      <p className="subproduct-descrip-topic">
                        {" "}
                        {prodDetail.varient[0]} :
                        {prodDetail.pVarientsOne.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <button
                                // className="subproduct-colors "
                                type="button"
                                className={
                                  activecolor === item.id
                                    ? "active activecolor"
                                    : "subproduct-colors"
                                }
                                // value={item.id}
                                key={index}
                                onClick={() => {
                                  handleColorChange(item.id);
                                }}
                              >
                                {item.name}
                              </button>
                            </React.Fragment>
                          );
                        })}
                      </p>
                    </div>
                    <div>
                      <p className="subproduct-descrip-topic">
                        {" "}
                        {prodDetail.varient[1]} :
                        {loadsize ? (
                          <>
                            {prodDetail.pVarientsTwo.map((item) => {
                              return (
                                <React.Fragment key={item.id}>
                                  <button
                                    className="subproduct-sizes"
                                    onClick={() => {
                                      handleSizeChange(item.id);
                                    }}
                                  >
                                    {item.name}
                                  </button>
                                </React.Fragment>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {availablesize.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <button
                                    // className="subproduct-sizes"
                                    type="button"
                                    className={
                                      activesize === item.variant_type_id_2
                                        ? "active activesize"
                                        : "subproduct-sizes"
                                    }
                                    value={item.variant_type_id_2}
                                    key={index}
                                    onClick={() => {
                                      handleSizeChange(item.variant_type_id_2);
                                    }}
                                  >
                                    {item.varient_two}
                                  </button>
                                </React.Fragment>
                              );
                            })}
                          </>
                        )}
                      </p>
                    </div>
                    {/* <div>
                <p className="subproduct-descrip-topic"> Color :</p>
              </div>
              <div>
                <p className="subproduct-descrip-topic"> Storage :</p>
              </div>
              <div>
                <p className="subproduct-descrip-topic"> RAM :</p>
              </div>
              <div>
                <p className="subproduct-descrip-topic"> Size :</p>
              </div> */}
                  </>
                ) : null}
              </div>
              <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>My WishList</Modal.Title>
          </Modal.Header>
          {wistlistloader ? (
            <>
              <Modal.Body>
                {nowishlistdata ?
                <React.Fragment>
                {newdata.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <button className="cat-wishlist-name-butn">
                        <h5
                          className="cat-wishlist-name"
                          onClick={() => {
                            handleadditem(item.id);
                          }}
                        >
                          {item.label}
                        </h5>
                      </button>
                    </React.Fragment>
                  );
                })}
                </React.Fragment>
                : null}
              </Modal.Body>
              <Modal.Footer>
                <button onClick={handleCreate} className="cat-modal-save">
                  + Create WishList
                </button>
              </Modal.Footer>
            </>
          ) : (
            <Spinner animation="border" />
          )}
        </Modal>

              <div>
                <Modal show={createshow} onHide={handleCreateoff}>
                  <Modal.Header closeButton>
                    <Modal.Title>New WishList</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="modal-label">
                          Wishlist Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Wishlist Name"
                          autoFocus
                          className="modal-input"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer className="madal-footer">
                    <Button
                      variant="secondary"
                      onClick={handleCreateoff}
                      className="madal-footer-close"
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleAdd}
                      className="madal-footer-save"
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          ) : (
            <Spinner animation="border" />
          )}
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
