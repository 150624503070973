import React, { Component } from 'react'
import "./Myorder.css"
import axios from 'axios';
import { Config } from "../../Config/Config";
import {
  getData,
  getLocalStorage,
} from "../DataFromLocalStorage/LocalstorageData";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Myaccount from '../MyAccount/Myaccount';

export default class Myorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alldata:[],
      custCode: "",
      access_key:"",
      no_order: false,
      loader: false,
    };
  }
  componentDidMount() {
    const d = getLocalStorage("CustomerData");
    const custCode = d.data.cust_code;
    this.setState({ custCode: d.data.cust_code });
    this.setState({ access_key: d.data.access_token });

    var data = new FormData();
    data.append("cust_code", custCode);
    data.append("access_key", d.data.access_token);

    this.setState({ loader: false});
    axios.post(`${Config.ORDER_HISTORY}`,data)
    .then(res=>{
      console.log("all order",res.data);
      this.setState({ alldata: res.data });
      this.setState({ no_order: true});
      this.setState({ loader: true});
    })
    .catch(err=>{
      console.log("error",err)
      this.setState({ no_order: false});
      this.setState({ loader: true});
    })
  }

  render() {
    return (
      <div className='order_page'>
      <div>
        <Myaccount />
      </div>
      <div className='order_items'>
        {this.state.loader ?
      <div>
        {this.state.no_order ?
        <div >
          {this.state.alldata.map((item , index)=>{
            return(
              <React.Fragment key={index}>
                <div className='order_full_details'>
              <div>
              <img 
              src={item.feature_image} 
              className="order_img"/>
              </div>
           
              <div className="order-item-detail">
                                    <Link
                                      to={`/${item.slug}/${item.product_id}`}
                                    >
                                      <h6 className="order_iten_name">
                                        {" "}
                                        {item.title}
                                      </h6>
                                    </Link>
                                    <p className="order-item-variant">{`${item.varient_one},${item.varient_two}`}</p>

                                    <div className="order_item_price_details">
                                      <h6 className="order_item_price_details_mrp">
                                        ₹{Math.trunc(item.mrp)}
                                      </h6>

                                      <h6 className="order_item_price_details_final">
                                        ₹{Math.trunc(item.sale_price)}
                                      </h6>

                                      <h6 className="order_item_price_details_discount">
                                        {Math.trunc(item.discount)}% off
                                      </h6>
                                    </div>
                                    {/* <div dangerouslySetInnerHTML={{__html: item.short_description}} /> */}
                                    <h6 className="order_item_price_details_seller">
                                      Seller: {item.seller_name}
                                    </h6>

                                    {/* {this.state.descrip ? */}

                                    <h6 className="order_item_price_details_seller">
                                      Description:{" "}
                                      {item.short_description.slice(0, 100)}
                                      {/* <button onClick={()=>this.setState({descrip :false})} className="descrip-show">...Show more</button> */}
                                    </h6>
                                    {/* :
                                     <h6 className="cart_item_price_details_seller">Description: {item.short_description}
                                    <button onClick={()=>this.setState({descrip :true})} className="descrip-show">Show less</button></h6>
                       } */}
                                  </div>
          
              </div>
              </React.Fragment>
            )
          })}
        </div>
:
<div className="empty-wish">
<i class="fa-solid fa-cart-arrow-down  empty-wish-icon"></i>
<h3>No Order found</h3>
<h6>Looks like you haven't order your products yet.</h6>
</div>
  }
      </div>
      :
      <Spinner
                          animation="border"
                          className="loader-spinner"
                        />
}
</div>
</div>
    )
  }
}
