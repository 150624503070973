import axios from "axios";


export const getLocalStorage = (keyName) => {
    const arrayOfData = localStorage.getItem(keyName);
      return arrayOfData !== null ? JSON.parse(arrayOfData) : [];
};

export const getData = async (url) => {
  const res = await axios.get(`${url}`);
  return res.data;
};
export const postData = async (url) => {
  const res = await axios.post(`${url}`);
  return res.data;
};
