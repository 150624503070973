import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import Myaccount from '../Myaccount'
import { Col, Row } from 'react-bootstrap';
// import AddingAddress from './AddingAddress'
import './Address.css'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AppContext from "../../../context/AppContext";
import axios from 'axios';
import { Config } from '../../../Config/Config';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function Address() {

    const Navigate = useNavigate()

    //--initializing states--

    const [editshow, setEditshow] = useState(false)

    const context = useContext(AppContext);

    const [alladdress, setAllAddress] = useState([])

    const [userdata, SetUserData] = useState(undefined);

    const [deletedata, setDeleteData] = useState();

    const [loadingdata, setLoadingData] = useState(null)

    const [makedefault, setMakedefault] = useState(false)


    //--getting localstorage value--

    useEffect(() => {
        try {
            const arrayOfData = localStorage.getItem('CustomerData')
            const d = arrayOfData !== null ? JSON.parse(arrayOfData) : [];
            console.log('code data', d.data.cust_code)
            console.log('access_token', d.data.access_token)
            SetUserData(d.data);
        }
        catch (error) {
            console.log('is error', error);
        }
    }, [])


    //getting all address data--

    useEffect(() => {

        console.warn(typeof userdata !== 'undefined')

        if (typeof userdata != 'undefined') {

            const addressdata = (Config.GET_ADDRESS + userdata.cust_code);

            console.log("inside", addressdata, userdata)
            var xhr = new XMLHttpRequest();
            xhr.withCredentials = true;

            xhr.addEventListener("readystatechange", function () {



                if (this.readyState === 4) {
                    console.log("data", this.responseText, typeof this.responseText);

                    setAllAddress(JSON.parse(this.responseText))
                    console.log("array data", alladdress, JSON.parse(this.responseText))
                }

            });

            xhr.open("POST", `${addressdata}`);
            xhr.setRequestHeader("Cache-Control", "no-cache");

            xhr.send();
        }

    }, [userdata, loadingdata , makedefault])


    const addAddress = () => {
        Navigate('/add')
    }


    const handleDelete = (id) => {


        console.log("Address delete id", id)

        let formData = new FormData()

        formData.append("cust_code", userdata.cust_code)
        formData.append("access_key", userdata.access_token)
        formData.append("id", id)

        axios.post(`${Config.DELETE_ADDRESS}`, formData)
            .then(res => {
                console.log("axios response delete", res.data);
                setDeleteData(deletedata)
                setLoadingData(res.data)
            })
            .catch(err => {

            })
    }

    const handleDef = (id) => {

        console.log("Address delete id", id)

        let formData = new FormData()

        formData.append("cust_code", userdata.cust_code)
        formData.append("access_key", userdata.access_token)
        formData.append("id", id)

        axios.post(`${Config.DEFAULT_ADDRESS}`, formData)
            .then(res => {
                console.log("axios response delete", res.data);
                setMakedefault(res.data)
            })
            .catch(err => {

            })

            (alladdress || []).map((adrs) => {
                if (adrs.is_default === 1) {
                    return (
                        <CheckCircleOutlineOutlinedIcon className='def-iconfill' />
                    )
                } else if (adrs.is_default === 0) {
                    return (
                        <CheckCircleOutlineOutlinedIcon className='def-icon' />
                    )
                }
            })
    }


    return (
        <div className='myaddress'>
            <div>
                <Myaccount />
            </div>
            <div className='acc-address'>

                <div
                    className='adddetail'
                    onMouseEnter={() => setEditshow(true)}
                    onMouseLeave={() => setEditshow(false)}
                >

                    {
                        (alladdress || []).map((adrs) =>
                            <React.Fragment key={adrs.id} >
                                <Row md={2}>

                                    <Col >
                                    {
                                    (`${ adrs.is_default }`==1)
                                                                ?
                                        <div className='address-input-default' role='radio'>
                                            <h6>{adrs.first_name} {adrs.last_name}</h6>
                                            <p>{adrs.address_one}</p>
                                            <p>{adrs.mobile}</p>

                                            {editshow ?
                                                <div className='add-def' >
                                                    <button className="defadd-link" onClick={() => { handleDef(adrs.id) }}>
                                                        {
                                                           (`${ adrs.is_default }`==1)
                                                                ?
                                                                <CheckCircleOutlineOutlinedIcon className='def-iconfill' />
                                                                :
                                                                <CheckCircleOutlineOutlinedIcon className='def-icon' />
                                                        }
                                                    </button>
                                                </div>
                                                : null}

                                            {editshow ?
                                                <div className='add-pen' >
                                                    <Link to={`/editadd/${adrs.id}`} className="editadd-link">
                                                        <ModeEditOutlineOutlinedIcon />
                                                    </Link>
                                                </div>
                                                : null}
                                            {editshow ?
                                                <div className='delete-pen' >
                                                    <button
                                                        className="deleteadd-link"
                                                        onClick={() => { handleDelete(adrs.id) }}
                                                    // value={adrs.id}
                                                    >

                                                        <DeleteOutlineOutlinedIcon />

                                                    </button>
                                                </div>
                                                : null}
                                        </div>
                                        :
                                        <div className='address-input' role='radio'>
                                        <h6>{adrs.first_name} {adrs.last_name}</h6>
                                        <p>{adrs.address_one}</p>
                                        <p>{adrs.mobile}</p>

                                        {editshow ?
                                            <div className='add-def' >
                                                <button className="defadd-link" onClick={() => { handleDef(adrs.id) }}>
                                                    {
                                                       (`${ adrs.is_default }`==1)
                                                            ?
                                                            <CheckCircleOutlineOutlinedIcon className='def-iconfill' />
                                                            :
                                                            <CheckCircleOutlineOutlinedIcon className='def-icon' />
                                                    }
                                                </button>
                                            </div>
                                            : null}

                                        {editshow ?
                                            <div className='add-pen' >
                                                <Link to={`/editadd/${adrs.id}`} className="editadd-link">
                                                    <ModeEditOutlineOutlinedIcon />
                                                </Link>
                                            </div>
                                            : null}
                                        {editshow ?
                                            <div className='delete-pen' >
                                                <button
                                                    className="deleteadd-link"
                                                    onClick={() => { handleDelete(adrs.id) }}
                                                // value={adrs.id}
                                                >

                                                    <DeleteOutlineOutlinedIcon />

                                                </button>
                                            </div>
                                            : null}
                                    </div>
                                            }

                                    </Col>
                                </Row>
                            </React.Fragment>

                        )
                    }

                </div>


                <button className='addButton' onClick={addAddress}>
                    + Add Address
                </button>

            </div>
        </div>
    )
}

export default Address