
import React from 'react'
// import axios from 'axios'
import prodData from '../products.json'
import { Col, Row, Card, Container } from 'react-bootstrap'
import './products.css'
import { Link } from 'react-router-dom'


function Products() {

    
    return (
        <div>
            <div>

            </div>
            <div className='all-product'>
                <Container>
                    <Row xs={1} md={4} className="g-4">
                        {
                            prodData.products.map((prod) =>
                                <React.Fragment key={prod.id}>

                                 <Col>
                                        <Card className='prod-cards'>
                                            <Link to={`/products-detail/${prod.id}`}  className="prod-cards-link">
                                            <div className='prod-cards-img-div'>
                                                <div>
                                                    <Card.Img variant="top" src={prod.image.thumbnail} alt="product image" className='prod-cards-img' />
                                                </div>
                                                
                                            </div>
                                            <Card.Body>
                                                <Card.Title>${prod.price}</Card.Title>
                                                <Card.Text>
                                                    {prod.name}
                                                </Card.Text>
                                                <Card.Text>
                                                    {prod.unit}
                                                </Card.Text>
                                            </Card.Body>
                                            </Link>
                                        </Card>
                                        </Col>

                                </React.Fragment>


                            )

                        }
                    </Row>
                </Container>
            </div>
        </div >
    )
}

export default Products