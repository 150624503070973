import React, { useState, useEffect, useContext } from "react";
import "./Accountsettings.css";
import { Col, Form, Row, Modal, Button } from "react-bootstrap";
// import { Link } from "react-router-dom"
// import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
// import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Myaccount from "../Myaccount";
import axios from "axios";
import { Config } from "../../../Config/Config";

function Accountsettings() {
  // const [passwordType, setPasswordType] = useState("password");
  // const [passwordInput, setPasswordInput] = useState("");
  // const handlePasswordChange = (evnt) => {
  //     setPasswordInput(evnt.target.value);
  // }
  // const togglePassword = () => {
  //     if (passwordType === "password") {
  //         setPasswordType("text")
  //         return;
  //     }
  //     setPasswordType("password")
  // }

  const validateEmail = RegExp("^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]");
  const validateMobile = RegExp("^([6-9]{1})([0-9]{9})$");

  const [userdata, SetUserData] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //--getting localstorage data--

  useEffect(() => {
    try {
      const arrayOfData = localStorage.getItem("CustomerData");
      const d = arrayOfData !== null ? JSON.parse(arrayOfData) : [];
      console.log("code data", d.data.mobile);
      SetUserData(d.data);
      let formData = new FormData();

      formData.append("cust_code",d.data.cust_code);
      axios
        .post(`${Config.PERSONAL_INFORMATION}`,formData)
        .then((res) => {
          setProfileimg(res.data);
          setShowimg(true);
          console.log("axios response", res.data);
        })
        .catch((err) => {
          console.log("axios Error", err.data);
        });
    } catch (error) {
      console.log("is error", error);
    }
  }, []);

  const [profileimg, setProfileimg] = useState("");

  const [uplodeimg, setUplodeimg] = useState("");

  const [showimg, setShowimg] = useState(false);

  const [inputState, setInputState] = useState({
    isError: {
      full_name: "",
      gender: "",
      mobile: "",
      email: "",
    },
  });

  const handleChange = (e) => {
    e.persist();
    console.log("Event", e);

    //--form validations--

    let { name, value } = e.target;
    let err = { ...inputState.isError };

    switch (name) {
      case "full_name":
        err.full_name = value.length < 3 ? "Atleast 3 characters required" : "";
        break;
      case "mobile":
        err.mobile = validateMobile.test(value) ? " " : "Invalid Mobile Number";
        break;
      case "email":
        err.pincode = validateEmail.test(value) ? " " : "Invalid Email";
        break;

      default:
        break;
    }

    setInputState({ ...inputState, [name]: value, isError: err });
    console.log("input value", inputState);
  };

  const submitHandle = (e) => {
    e.preventDefault();
    console.log("after submit", inputState);

    //--post form data to api --

    let full_name = inputState.full_name;
    let splitFull_name = full_name.split(" ");
    let first_name = splitFull_name[0];
    let last_name = splitFull_name[1];

    let formData = new FormData();

    formData.append("cust_code", userdata.cust_code);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("gender", inputState.gender);
    formData.append("mobile", inputState.mobile);
    formData.append("email", inputState.email);

    axios
      .post(`${Config.UPDATE_PRESONAL_DETAILS}`, formData)
      .then((res) => {
        console.log("axios responce", res.data);
        alert("update done");

        // navigate("/myaccount/:acc-address")
      })
      .catch((err) => {
        console.log("axios error", err);
        alert("update error");
      });
  };

  const handleImagechange =(e)=>{
    console.log("image",e.target.value);
    setUplodeimg(e.target.value);
  }

  const hadleUpdateimg = () => {
    console.log("cust_code", userdata.cust_code);

    let formData = new FormData();

    formData.append("cust_code", userdata.cust_code);
    formData.append("uploadProfileImage", uplodeimg);

    axios
          .post(`${Config.UPLODE_PROFILE_IMAGE}`,formData)
          .then((res) => {
            console.log("axios response", res.data);

            axios
              .post(`${Config.PERSONAL_INFORMATION}`,formData)
              .then((res) => {
                setProfileimg(res.data);
                setShowimg(true);
                console.log("axios response", res.data);
              })
              .catch((err) => {
                console.log("axios Error", err.data);
              });

          })
          .catch((err) => {
            console.log("axios Error", err.data);
          });
          setShow(false);
  };

  return (
    <div className="my-acc-setting">
      <div>
        <Myaccount />
      </div>
      <div className="acc-setting">
        <h4>Personal Information</h4>

        <div className="profile_image">
          {showimg ? (
            <img src="" alt="profile image" className="acc-profileimg" />
          ) : (
            <img
              src="../../../../../image/profileimg.jpg"
              alt="profile image"
              className="acc-profileimg"
            />
          )}

          <button onClick={handleShow} className="uplode_image">
            <i class="fa-regular fa-pen  uplode_image_icon"></i>
          </button>
        </div>
        <Form onSubmit={submitHandle}>
          <div>
            <div>
              <label className="acc-text">Full Name *</label>
              <br />
              <input
                type="text"
                className="acc-text-input full_name"
                name="full_name"
                onChange={handleChange}
              />
            </div>

            {/* <div>
                            <label className="acc-text">Last Name *</label>
                            <br />
                            <input type="text" className="acc-text-input" />
                        </div> */}

            <div className="acc-text-div">
              <div>
                <label className="acc-text">Gender</label>
                <br />
                <select
                  className="acc-text-input"
                  name="gender"
                  onChange={handleChange}
                >
                  <option> Male </option>
                  <option> Female </option>
                  <option> Other </option>
                </select>
                <br />
              </div>

              <div>
                <label className="acc-text">Phone/Mobile </label>
                <br />
                <input
                  disabled
                  className="acc-text-input"
                  name="mobile"
                  placeholder={userdata.mobile}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <h4>Account Information</h4>

              <label className="acc-text">Email *</label>
              <br />
              <input
                type="email"
                className="acc-text-input"
                name="email"
                onChange={handleChange}
              />

              {/* <div className='acc-text-div'>

                        <div>
                            <label className="acc-text">
                                Password</label><br />
                            <div className="input-group acc-text-input">

                                <input type={passwordType} onChange={handlePasswordChange} value={passwordInput} name="password" class="form-control acc-password" />
                                <div className="acc-pass-toggleBtn">
                                    <button className="toggleBtn" onClick={togglePassword}>
                                        {passwordType === "password" ? <VisibilityOffOutlinedIcon className="toggleBtn-icon"/> : <RemoveRedEyeOutlinedIcon className="toggleBtn-icon"/>}
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div>
                            <label className="acc-text">
                               Confirm Password</label><br />
                            <div className="input-group acc-text-input">

                                <input type={passwordType} onChange={handlePasswordChange} value={passwordInput} name="password" class="form-control acc-password " />
                                <div className="acc-pass-toggleBtn">
                                    <button className="toggleBtn" onClick={togglePassword}>
                                        {passwordType === "password" ? <VisibilityOffOutlinedIcon className="toggleBtn-icon"/> : <RemoveRedEyeOutlinedIcon className="toggleBtn-icon"/>}
                                    </button>
                                </div>
                            </div>

                    </div>*/}
            </div>
          </div>
          <br />

          {/* <div> */}
          {/* <Row className='acc-redioBtn'> */}
          {/* <Col>
                                <h6>Share Profile Data</h6>
                                <p className="acc-text">Share your profile information to collect the product search result</p>
                            </Col>
                            <Col sm={2}>
                                <label className="switch" >
                                    <input type="checkbox" id="checkbox" />
                                    <div className="slider round"></div>
                                </label>
                            </Col>
                        </Row>

                        <Row className='acc-redioBtn'>
                            <Col>
                                <h6>Ads Performance</h6>
                                <p className="acc-text">To improve your ads search result we need to collect your cookies behavior</p>
                            </Col>
                            <Col sm={2}>
                                <label className="switch" >
                                    <input type="checkbox" id="checkbox" />
                                    <div className="slider round"></div>
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col></Col> */}
          {/* <Col sm={3}> */}
          <button type="submit" className="acc-submit">
            Save Changes
          </button>
          {/* </Col> */}
          {/* </Row> */}
          {/* </div> */}
        </Form>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Select Image</Modal.Title>
          </Modal.Header>
          <Modal.Body className="image_input_text">
            <input type="file" name="img" accept="image/*" onChange={handleImagechange} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              className="cancel_image"
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={hadleUpdateimg}
              className="save_image"
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Accountsettings;
