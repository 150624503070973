import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Col,
  Row,
  Card,
  Container,
  Spinner,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import "./Category.css";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Config } from "../../Config/Config";
import { Helmet } from "react-helmet";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  getData,
  getLocalStorage
} from "../DataFromLocalStorage/LocalstorageData";

function Category() {
  const value = useParams();
  // console.log("value in category item", value);

  const [userdata, SetUserData] = useState(undefined);
  const [alldata, setAlldata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subcatloading, setSubcatLoading] = useState(true);
  const [metadata, setMetaData] = useState([]);
  const [subcatagory, setSubcatagory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [seller, setSeller] = useState([]);
  const [price_Range, setPrice_Range] = useState([]);
  const [searchparams, setSearchparams] = useSearchParams();
  const [wishlistdata, setWishlistdata] = useState([]);
  const [nowishlistdata, setNoWishlistdata] = useState(false);
  const [show, setShow] = useState(false);
  const [createshow, setCreateShow] = useState(false);
  const [accesskey, setAccesskey] = useState("");
  const [inputstate, setInputstate] = useState("");
  const [wistlistloader, setWistlistloader] = useState(false);
  const [productcode, setProductcode] = useState("");
  const [newdata, setNewdata] = useState([]);

  const handleSearch = (event) => {
    console.log("...........", event);
    console.log("/////", event.target.__reactProps$beo14n5jwz9.value);
    const sub_cat = event.target.__reactProps$beo14n5jwz9.value;

    setSearchparams(sub_cat);
    console.log("....", searchparams);
  };

  useEffect(() => {
    try {
      const d = getLocalStorage("CustomerData");
      SetUserData(d.data.cust_code);
      setAccesskey(d.data.access_token);
      // console.log(">....", d.data.cust_code)
    } catch (error) {
      // console.log('is error', error);
    }
  }, []);

  const URL = Config.PRODUCT_CATEGORY + userdata;
  // console.log(URL);

  useEffect((e) => {
    fetch_data();
  }, []);

  const fetch_data = async () => {
    const res = await getData(URL);
    if (res) {
      setAlldata(res);
    }
    setLoading(false);
  }

  const category_data = alldata.find(
    (data) => data.category_name == value.c_name
  );
  // console.log("-----------", category_data);

  const catmetaURL = Config.CATEGORY_META + value.c_name;
  // console.log(catmetaURL);

  useEffect(
    (e) => {
      axios
        .get(`${catmetaURL}`)
        .then((res) => {
          // console.log("metadata", res.data);
          setMetaData(res.data);
        })
        .catch((err) => {
          // console.log("error", err);
        });
    },
    [value]
  );

  const subcatURL = Config.PRODUCT_SUBCATEGORY_FILTER + value.c_name;
  // console.log(catmetaURL);

  useEffect((e) => {
    axios
      .get(`${subcatURL}`)
      .then((res) => {
        // console.log("subcatagory", res.data);
        setSubcatagory(res.data.categories);
        setBrand(res.data.brand);
        setSeller(res.data.seller);
        setPrice_Range(res.data.price_Range);
        setSubcatLoading(false);
      })
      .catch((err) => {
        // console.log("error", err);
      });
  }, []);

  const wishlist_url = Config.PRODUCT_WISHLIST + userdata;

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    console.log("product code", e);
    setProductcode(e);
    setShow(true);

    axios
      .get(`${wishlist_url}`,{headers: { 
        'Cache-Control': 'no-store'
      }})
      .then((res) => {
        console.log("axios response wishlist", res.data);
        console.log("axios response ", res.data.length);
        const xyz = res.data;
        setWishlistdata(xyz);
        setNewdata(res.data.wishlistData)
        if(res.data.wishlistData.length > 0){
          setNoWishlistdata(true)
        }
        setWistlistloader(true);
        console.log("axios response fash", wishlistdata);
        console.log("res.data.wishlistData", res.data.wishlistData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setWistlistloader(true);
        // console.log("axios error elec", err)
      });
  };

  const handleCreate = () => {
    setShow(false);
    setCreateShow(true);
  };
  const handleCreateoff = () => {
    setShow(false);
    setCreateShow(false);
  };

  const handleChange = (e) => {
    console.log("....", e.target.value);
    setInputstate(e.target.value);
  };

  const handleAdd = () => {
    let formData = new FormData();

    formData.append("cust_code", userdata);
    formData.append("access_key", accesskey);
    formData.append("is_private", "0");
    formData.append("is_default", wishlistdata.length > 0 ? "0" : "1");
    formData.append("label", inputstate);

    axios
      .post(`${Config.CREATE_WISHLIST}`, formData)
      .then((res) => {
        console.log("............000.....", res.data);
        if(res.data.check == "success"){
          axios
          .get(`${wishlist_url}`)
          .then((res) => {
            console.log("axios response wishlist", res.data);
            console.log("axios response ", res.data.length);
            const xyz = res.data;
            setWishlistdata(xyz);
            if(res.data.wishlistData.length > 0){
              setNoWishlistdata(true)
            }
            setWistlistloader(true);
            console.log("axios response fash", wishlistdata);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            setWistlistloader(true);
            // console.log("axios error elec", err)
          });
        }
        alert("done");
      })
      .catch((err) => {
        console.log("............000.....", err.data);
        alert("fail");
      });

    setCreateShow(false);
    setShow(true);
  };
  const handleadditem = (e) => {
    console.log("........", e);

    let formData = new FormData();

    formData.append("cust_code", userdata);
    formData.append("access_key", accesskey);
    formData.append("wishlist_id", e);
    formData.append("type", "1");
    formData.append("product_code", productcode);

    axios
      .post(`${Config.ADD_WISHLIST}`, formData)
      .then((res) => {
        console.log("............000.....", res.data);
        alert("done");
      })
      .catch((err) => {
        console.log("............000.....", err.data);
        alert("fail");
      });

    setShow(false);
  };

  const handledeleteitem = (e) => {
    console.log(e);

    let formData = new FormData();

    formData.append("cust_code", userdata);
    formData.append("access_key", accesskey);
    formData.append("product_code", e);
    formData.append("type", "0");

    axios
      .post(`${Config.REMOVE_WISHLIST_ITEM}`, formData)
      .then((res) => {
        console.log("............000.....", res.data);    
        fetch_data();
        console.log("all data",alldata)
          alert("remove done");
      })
      .catch((err) => {
        console.log("............000.....", err.data);
        alert("fail");
      });

    setShow(false);
  };

  return (
    <div>
      <Helmet>    
          <title>{metadata.meta_title}</title>
        <meta name="description" property="og:descrip" content={metadata.meta_descrip} />
        <meta name="description" property="og:descrip" content={metadata.tag_line} />
        <link
          rel="icon"
          type="image/png"
          href={metadata.og_image}
          sizes="16x16"
        />
      </Helmet>
      <div className="category-main-div">
        <div className="subcat-texts">
          {/* <h5>Categories</h5> */}
          {/* <div>
            {!subcatloading ? (
              <>
                <ul className="subcat-ul">
                  {subcatagory.map((h) => {
                    return (
                      <React.Fragment key={h.id}>
                        <a
                          // onClick={handleSearch}
                          // value={h.subcat}
                          className="subcat-links"
                        >
                          <li
                            className="subcat-li"
                            onClick={handleSearch}
                            value={h.subcat}
                          >
                            {h.subcat}
                          </li>
                        </a>
                      </React.Fragment>
                    );
                  })}
                </ul>
                <ul className="subcat-ul">
                  {brand.map((h) => {
                    return (
                      <React.Fragment key={h.id}>
                        <a className="subcat-links">
                          <li className="subcat-li">{h.name}</li>
                        </a>
                      </React.Fragment>
                    );
                  })}
                </ul>
                <ul className="subcat-ul">
                  {seller.map((h) => {
                    return (
                      <React.Fragment key={h.id}>
                        <a className="subcat-links">
                          <li className="subcat-li">{h.name}</li>
                        </a>
                      </React.Fragment>
                    );
                  })}
                </ul>
                <ul className="subcat-ul">
                  {price_Range.map((h) => {
                    return (
                      <React.Fragment key={h.id}>
                        <a className="subcat-links">
                          <li className="subcat-li">{h.name}</li>
                        </a>
                      </React.Fragment>
                    );
                  })}
                </ul>
              </>
            ) : (
              <Spinner animation="border" />
            )}
          </div> */}
        </div>
        <div>
          {!loading ? (
            <Container>
              <Row xs={1} md={3} className="g-4">
                {category_data.books.map((prod) => {
                  return (
                    <React.Fragment key={prod.book_id}>
                      <Col>
                        <Card className="prod-cards">
                         
                          {prod.is_wishlisted === '1' ? (
                          <button
                            className="prod-wishlist-icon"
                            variant="primary"
                            onClick={() => {
                              handledeleteitem(prod.code);
                            }}
                            value={prod.id}
                          >
                            <i class="fa-solid fa-heart wishlisted-icon"></i>
                          </button>
                        ) : (
                  <button
                    className="prod-wishlist-icon"
                    variant="primary"
                    onClick={() => {
                      handleShow(prod.code);
                    }}
                  >
                    <i class="fa-solid fa-heart prod-mainfavicon"></i>
                  </button>
                        )}
                          <Link
                            to={`/${prod.slug}/${prod.book_id}`}
                            className="prod-card-link"
                          >
                            <div className="prod-cards-img-div">
                              <div>
                                <Card.Img
                                  variant="top"
                                  src={prod.feature_image}
                                  alt="product image"
                                  className="prod-cards-img"
                                />
                              </div>
                            </div>
                            <Card.Body>
                              <div className="cards-price">
                                <Card.Title>₹{prod.sale_price}</Card.Title>

                                <p className="cut-price">₹{prod.mrp}</p>
                              </div>
                              <Card.Text className="category-prodname">{prod.title}</Card.Text>
                            </Card.Body>
                          </Link>
                        </Card>
                      </Col>
                    </React.Fragment>
                  );
                })}
              </Row>
              <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>My WishList</Modal.Title>
          </Modal.Header>
          {wistlistloader ? (
            <>
              <Modal.Body>
                {nowishlistdata ?
                <React.Fragment>
                {newdata.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <button className="cat-wishlist-name-butn">
                        <h5
                          className="cat-wishlist-name"
                          onClick={() => {
                            handleadditem(item.id);
                          }}
                        >
                          {item.label}
                        </h5>
                      </button>
                    </React.Fragment>
                  );
                })}
                </React.Fragment>
                : null}
              </Modal.Body>
              <Modal.Footer>
                <button onClick={handleCreate} className="cat-modal-save">
                  + Create WishList
                </button>
              </Modal.Footer>
            </>
          ) : (
            <Spinner animation="border" />
          )}
        </Modal>

              <div>
                <Modal show={createshow} onHide={handleCreateoff}>
                  <Modal.Header closeButton>
                    <Modal.Title>New WishList</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="modal-label">
                          Wishlist Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Wishlist Name"
                          autoFocus
                          className="modal-input"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer className="madal-footer">
                    <Button
                      variant="secondary"
                      onClick={handleCreateoff}
                      className="madal-footer-close"
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleAdd}
                      className="madal-footer-save"
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Container>
          ) : (
            <Spinner animation="border" />
          )}
        </div>
      </div>
    </div>
  );
}

export default Category;
