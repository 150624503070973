

const END_POINT = "https://desuntechnologies.com/admin/";

const BASE_URL = END_POINT + "api"

export let Config = {

    END_POINT,

    BASE_URL,

    SUCCESS_TYPE: "success",

    FAILURE_TYPE: "failure",

    USER_SIGNUP: BASE_URL + "/signupwithotp",

    USER_OTPVERIFY: BASE_URL + "/validate_otp",

    USER_SIGNIN: BASE_URL + "/signin",

    RESEND_OTP: BASE_URL + "/resend_otp",

    SEARCH_ITEM: BASE_URL + "/search_product",

    HOME_BANNER: BASE_URL + "/get_home_banner",

    HOME_OTHERBANNER: BASE_URL + "/get_others_banner",

    HOME_META: BASE_URL + "/home_page_meta",

    CATEGORY_META: BASE_URL + "/get_category_meta?cat_slug=",

    ADD_ADDRESS: BASE_URL + "/add_address",

    GET_ADDRESS: BASE_URL + "/get_address?cust_code=",

    EDIT_ADDRESS: BASE_URL + "/edit_address",

    DELETE_ADDRESS: BASE_URL + "/delete_address",

    DEFAULT_ADDRESS: BASE_URL + "/make_default_address",

    GET_STATE: BASE_URL + "/get_state",

    GET_CITY: BASE_URL + "/get_city",

    FOOTER_DETAILS: BASE_URL + "/getFooterDetails",

    FOOTER_SOCIAL: BASE_URL + "/getSocial",

    FOOTER_SUBDETAILS: BASE_URL + "/widget?slug=footer-section",

    FOOTER_COPYRIGHT: BASE_URL + "/widget?slug=copyright",

    UPDATE_PRESONAL_DETAILS: BASE_URL + "/update_personal_information",

    PERSONAL_INFORMATION: BASE_URL + "/personal_information",

    GET_FAQ_DETAIL: BASE_URL + "/get_menu?menu_slug=faqs",

    GET_TERMS_DETAIL: BASE_URL + "/get_menu?menu_slug=terms",
    GET_CONTACT_DETAIL: BASE_URL + "/get_menu?menu_slug=contact_us",
    GET_CATEGORIES_DETAIL: BASE_URL + "/get_menu?menu_slug=categories",
    GET_ABOUT_DETAIL: BASE_URL + "/get_menu?menu_slug=about_us",
    GET_OUR_INFO_DETAIL: BASE_URL + "/get_menu?menu_slug=our_information",
    GET_SUBSCRIBE_DETAIL: BASE_URL + "/get_menu?menu_slug=subscribe_now",

    GET_PRIVACY_DETAIL: BASE_URL + "/get_menu?menu_slug=policy",

    GET_TERM_CONDITION: BASE_URL + "/get_policy?policy_type=terms_conditions",

    GET_CATEGORY: BASE_URL + "/getCategory",

    LATEST_COLLECTION: BASE_URL + "/get_featured?id=1&cust_code=",

    DEALS_OF_THEDAY: BASE_URL + "/get_featured?id=2&cust_code=",

    ELECTRONICS: BASE_URL + "/get_featured?id=3&cust_code=",

    JEWELLWRY: BASE_URL + "/get_featured?id=4&cust_code=",

    FASHION: BASE_URL + "/get_featured?id=5&cust_code=",

    HEADER_MENU: BASE_URL + "/mainmenu?menu_slug=header-menu",

    PRODUCT_DETAILS: BASE_URL + "/product_details?id=",

    PRODUCT_CATEGORY: BASE_URL + "/categoryItems?cust_code=",

    PRODUCT_SUBCATEGORY_FILTER: BASE_URL + "/get_sub_filter?cat_slug=",

    PRODUCT_WISHLIST: BASE_URL + "/wishlist?cust_code=",

    ADD_WISHLIST: BASE_URL + "/add_wishlist",

    CREATE_WISHLIST: BASE_URL + "/create_wishlist",

    REMOVE_WISHLIST: BASE_URL + "/remove_wishlist",

    REMOVE_WISHLIST_ITEM: BASE_URL + "/remove_wishlist_item",

    MOVE_WISHLIST_ITEM: BASE_URL + "/move_wishlist",

    ADD_CART: BASE_URL + "/add_cart",

    GET_CART: BASE_URL + "/get_cart",

    REMOVE_CART_ITEM: BASE_URL + "/remove_cart_item",

    CLEAR_CART: BASE_URL + "/clear_cart",

    MASTERCARD: BASE_URL + "/media_management?id=7",

    VISACARD: BASE_URL + "/media_management?id=8",

    RUPAYCARD: BASE_URL + "/media_management?id=9",

    UPI: BASE_URL + "/media_management?id=10",

    PROMO_ICON: BASE_URL + "/media_management?id=13",

    FOOTER_IMG: BASE_URL + "/media_management?id=14",

    FOOTER_APPLE_STORE: BASE_URL + "/media_management?id=15",

    FOOTER_PLAY_STORE: BASE_URL + "/media_management?id=16",

    GET_SETTINGS: BASE_URL + "/getSettings",

    PROMO_CODE: BASE_URL + "/validate_promo_code",

    UPLODE_PROFILE_IMAGE: BASE_URL + "/upload_profile_image",

    GET_COUPON: BASE_URL + "/get_coupon",

    GET_ORDER_ID: BASE_URL + "/get_order_id",

    ADD_TXN: BASE_URL + "/add_txn",

    PLACE_ORDER: BASE_URL + "/place_order",

    ORDER_HISTORY: BASE_URL + "/order_history",



}
