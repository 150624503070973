import React, { useState, useEffect, useContext } from "react";
import Myaccount from "../MyAccount/Myaccount";
import "./Wishlist.css";
// import prodData from '../products.json'
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Config } from "../../Config/Config";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";

function Subwishlist() {
  const value = useParams();
  const navigate = useNavigate();
  //   console.log("....value.....",value);

  //   const context = useContext(AppContext);
  //   console.log(".......custcode.....", context.customerCode);

  const [userdata, SetUserData] = useState(undefined);
  const [custcode, SetCustcode] = useState(undefined);
  const [alldata, setAlldata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wishlist_mainid, setWishlist_mainid] = useState("");
  const [productid, setProductid] = useState("");
  const [wishlistdata, setWishlistdata] = useState([]);
  const [show, setShow] = useState(false);
  const [createshow, setCreateShow] = useState(false);
  const [accesskey, setAccesskey] = useState("");
  const [inputstate, setInputstate] = useState("");
  const [wistlistloader, setWistlistloader] = useState(false);
  const [productcode, setProductcode] = useState("");
  const [wishlistid, setWishlistide] = useState("");
  const [newdata,setNewdata] = useState([]);
  const [newlist,setNewlist] = useState([]);

  useEffect(() => {
    try {
      const arrayOfData = localStorage.getItem("CustomerData");
      const d = arrayOfData !== null ? JSON.parse(arrayOfData) : [];

      SetUserData(d.data);
      SetCustcode(d.data.cust_code);
      setAccesskey(d.data.access_token);
      // console.log(">....", userdata);
      // console.log(">....", custcode);
    } catch (error) {
      // console.log('is error', error);
    }
  }, [custcode]);

  const URL = Config.PRODUCT_WISHLIST + custcode;
  // console.log(URL);

  useEffect(() => {
  
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log(this.responseText);
        console.log(JSON.parse(this.responseText).wishlistData);
        setAlldata(JSON.parse(this.responseText))
        // if(JSON.parse(this.responseText).wishlistData.length > 0){
        setNewdata(
          JSON.parse(this.responseText).wishlistData.find(
            (data) => data.label == value.wname
          )
        );
        console.log("axios response newdata...", newdata);
        setLoading(false);
        //  }
      }
    });

    xhr.open("GET", `${URL}`);
    xhr.setRequestHeader("Cache-Control", "no-store");

    xhr.send();
  }, [custcode,loading,newdata.length]);

  const handledelete = (e) => {
    console.log("id", e);
    setWishlist_mainid(e);
    console.log("......id...........", wishlist_mainid);
    console.log(".....remove url.........", Config.REMOVE_WISHLIST);

    var data = new FormData();
    data.append("wishlist_id", e);
    data.append("cust_code", custcode);
    data.append("access_key", userdata.access_token);

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log(this.responseText);
        alert("wishlist removed");
        navigate("/myaccount/wishlist");
      }
    });

    xhr.open("POST", `${Config.REMOVE_WISHLIST}`);

    xhr.send(data);
  };
  const handledeleteitem = (e, f) => {
    console.log("product code", e);
    console.log("wishlist id", f);
    setProductid(e);
    console.log("......id...........", productid);
    console.log(".....remove url.........", Config.REMOVE_WISHLIST_ITEM);

    var data = new FormData();
    data.append("product_code", e);
    data.append("wishlist_id", f);
    data.append("type", "0");
    data.append("cust_code", custcode);
    data.append("access_key", userdata.access_token);

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log(this.responseText);
        alert("Product removed");
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
    
        xhr.addEventListener("readystatechange", function () {
          if (this.readyState === 4) {
            console.log(this.responseText);
            console.log(JSON.parse(this.responseText).wishlistData);
            setAlldata(JSON.parse(this.responseText))
            // if(JSON.parse(this.responseText).wishlistData.length > 0){
            setNewdata(
              JSON.parse(this.responseText).wishlistData.find(
                (data) => data.label == value.wname
              )
            );
            console.log("axios response newdata...", newdata);
            setLoading(false);
            //  }
          }
        });
    
        xhr.open("GET", `${URL}`);
        xhr.setRequestHeader("Cache-Control", "no-store");
    
        xhr.send();
      }
    });

    xhr.open("POST", `${Config.REMOVE_WISHLIST_ITEM}`);

    xhr.send(data);
  };

  const wishlist_url = Config.PRODUCT_WISHLIST + custcode;
  console.log(wishlist_url)

  const handleClose = () => setShow(false);
  const handleShow = (e,f) => {
    console.log("product code", e);
    console.log("wishlist id", f);
    setProductcode(e);
    setWishlistide(f);
    setShow(true);

    axios
      .get(`${wishlist_url}`)
      .then((res) => {
        console.log("axios response wishlist", res.data);
        console.log("axios response ", res.data.length);
        const xyz = res.data;
        setWishlistdata(xyz);
        setNewlist(res.data.wishlistData);
        setWistlistloader(true);
        console.log("axios response fash", wishlistdata);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("axios error elec", err)
      });
  };

  const handleCreate = () => {
    setShow(false);
    setCreateShow(true);
  };
  const handleCreateoff = () => {
    setShow(false);
    setCreateShow(false);
  };

  const handleChange = (e) => {
    console.log("....", e.target.value);
    setInputstate(e.target.value);
  };

  const handleAdd = () => {
    let formData = new FormData();

    formData.append("cust_code", custcode);
    formData.append("access_key", accesskey);
    formData.append("is_private", "0");
    formData.append("is_default", alldata.length > 0 ? "0" : "1");
    formData.append("label", inputstate);

    axios
      .post(`${Config.CREATE_WISHLIST}`, formData)
      .then((res) => {
        console.log("............000.....", res.data);
   
          axios
          .get(`${wishlist_url}`)
          .then((res) => {
            console.log("axios response wishlist", res.data);
            console.log("axios response ", res.data.length);
            const xyz = res.data;
            setWishlistdata(xyz);
            setNewlist(res.data.wishlistData);
            setWistlistloader(true);
            console.log("axios response fash", wishlistdata);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            // console.log("axios error elec", err)
          });
       
        alert("done");
      })
      .catch((err) => {
        console.log("............000.....", err.data);
        alert("fail");
      });

    setCreateShow(false);
    setShow(true);
  };
  const handleremoveitem = (e) => {
    console.log("........", e);

    console.log("cust_code", custcode)
    console.log("to_wishlist_id", e)
    console.log("from_wishlist_id", wishlistid)
    console.log("product_code", productcode)

    let formData = new FormData();

    formData.append("cust_code", custcode);
    formData.append("to_wishlist_id", e);
    formData.append("from_wishlist_id", wishlistid);
    formData.append("product_code", productcode);

    axios
      .post(`${Config.MOVE_WISHLIST_ITEM}`, formData)
      .then((res) => {
        console.log("............000.....", res.data);
        alert("done");

        var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log(this.responseText);
        console.log(JSON.parse(this.responseText).wishlistData);
        setAlldata(JSON.parse(this.responseText))
        // if(JSON.parse(this.responseText).wishlistData.length > 0){
        setNewdata(
          JSON.parse(this.responseText).wishlistData.find(
            (data) => data.label == value.wname
          )
        );
        console.log("axios response newdata...", newdata);
        setLoading(false);
        //  }
      }
    });

    xhr.open("GET", `${URL}`);
    xhr.setRequestHeader("Cache-Control", "no-store");

    xhr.send();

      })
      .catch((err) => {
        console.log("............000.....", err.data);
        alert("fail");
      });

    setShow(false);
  };

  return (
    <div className="user-wishlist">
       <div>
        <Myaccount />
      </div>
      {/* <div>hi</div>
      {!loading ?
      <div>
        {newdata.products.map((prod) => {
          return(
                  <React.Fragment key={prod.id}>
                    <h5>{prod.title}</h5>
                    </React.Fragment>
                    )
        }
      )}
      <h5>done</h5>
                  </div>
                  :
                  <h5>sorry fail</h5>
      } */}

      <div className="user-wishlist-product">
        <div className="sub-wish-header">
          <h4 className="subwish-label">
          <i class="fa-solid fa-folder-closed wishlist-folder"></i>
            {newdata.label}</h4>
          <i
            className="fa-solid fa-trash delete-wishlist-icon"
            onClick={() => {
              handledelete(newdata.id);
            }}
            value={newdata.id}
          ></i>
        </div>
        {!loading ? (
          <>
            {
               newdata.products.map((prod) => (
                  <React.Fragment key={prod.id}>
                    <div className="user-wishlist-item">
                      <Link
                        to={`/${prod.slug}/${prod.book_id}`}
                        className="subwish-link"
                      >
                        <div className="subwish-img-div">
                          <img
                            src={prod.feature_image}
                            className="user-wishlist-item-img"
                          />
                        </div>
                        <div>
                          <h6> {prod.title}</h6>
                          <div className="wish_item_price_details">
                                    <h6 className="wish_item_price_details_mrp">₹{Math.trunc(prod.mrp)}</h6>

                                    <h6 className="wish_item_price_details_final">₹{Math.trunc(prod.sale_price)}</h6>
                                    
                                    <h6 className="wish_item_price_details_discount">{Math.trunc(prod.discount)}% off</h6>
                                    </div>
                                    <h6 className="wish_item_price_details_seeler">Seller: {prod.seller_name}</h6>
                                    <h6 className="wish_item_price_details_seeler">Description: {prod.short_description.slice(0,100)}</h6>
                        </div>
                      </Link>
                      <div>
                        <div>
                          <i
                            class="fa-solid fa-heart user-wishlist-item-icon"
                            onClick={() => {
                              handledeleteitem(prod.product_code, newdata.id);
                            }}
                          ></i>
                        </div>
                        <div>               
                          <i class="fa-solid fa-arrow-up-from-bracket user-wishlist-moveitem-icon"
                           onClick={() => {
                            handleShow(prod.product_code , newdata.id);
                          }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))
             }
          </>
        ) : (
          <Spinner animation="border" />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>My WishList</Modal.Title>
                </Modal.Header>
                {wistlistloader ? (
                  <>
                    <Modal.Body>
                      {newlist.map((item) => {
                        return (
                          <React.Fragment key={item.id}>
                            <button className="cat-wishlist-name-butn">
                              <h5
                                className="cat-wishlist-name"
                                onClick={() => {
                                  handleremoveitem(item.id);
                                }}
                              >
                                {item.label}
                              </h5>
                            </button>
                          </React.Fragment>
                        );
                      })}
                    </Modal.Body>
                    <Modal.Footer>
                      <button onClick={handleCreate} className="cat-modal-save">
                        + Create WishList
                      </button>
                    </Modal.Footer>
                  </>
                ) : (
                  <Spinner animation="border" />
                )}
              </Modal>

              <div>
                <Modal show={createshow} onHide={handleCreateoff}>
                  <Modal.Header closeButton>
                    <Modal.Title>New WishList</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="modal-label">
                          Wishlist Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Wishlist Name"
                          autoFocus
                          className="modal-input"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer className="madal-footer">
                    <Button
                      variant="secondary"
                      onClick={handleCreateoff}
                      className="madal-footer-close"
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleAdd}
                      className="madal-footer-save"
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
                </div>

    </div>
  );
}

export default Subwishlist;
