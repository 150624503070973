import React, { useState } from 'react'
import Slider from 'react-slick/lib/slider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
// import { Card } from "react-bootstrap";
import './Tag.css'


const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} >
            <ArrowForwardIosIcon />
        </div>
    )
}

const PreBtn = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} >
            <ArrowBackIosIcon />
        </div>
    )
}


const HomeTag = () => {


    return (
        <div className="slide-tags">
            <Slider
                prevArrow={<PreBtn />}
                nextArrow={<NextBtn />}
                slidesToShow={3}
                slidesToScroll={1}
                infinite={false}
            >
                <div className='home-slide-tag'>
                    <img src='../../../../image/home-tag1.png' />
                </div>
                <div className='home-slide-tag' >
                    <img src='../../../../image/home-tag2.png' />
                </div>
                <div  className='home-slide-tag'>
                    <img src='../../../../image/home-tag3.png' />
                </div>
                <div className='home-slide-tag'>
                    <img src='../../../../image/home-tag4.png' />
                </div>
            </Slider>

        </div>
    )
}

export default HomeTag;