import React, { useState, useEffect, useContext } from 'react'
import { Form, Button } from "react-bootstrap";
import './AddingAddress.css'
import { Config } from '../../../Config/Config';
import axios from "axios";
import AppContext from "../../../context/AppContext";
import { useNavigate } from 'react-router-dom';



const AddingAddress = (props) => {

    const validateMobile = RegExp('^([6-9]{1})([0-9]{9})$');
    const validatePincode = RegExp('^([0-9]{6})$');

    const context = useContext(AppContext);

    const navigate = useNavigate();

    const [allstate, setAllState] = useState({
        allstates: []
    });
    const [allcity, setAllCity] = useState({
        allCitys: []
    });

    let [userdata, SetUserData] = useState([]);

    let [input_states, setInput_States] = useState("");
    let [input_states_id, setInput_States_id] = useState("");
    let [inputcitys, setInputCitys] = useState("");
    let [redirec, setRedirec] = useState(props?.redirec ?? undefined);

    const handleStateChange = (e) => {
        console.log(e);
        setInput_States_id(e.target.value);
        {
            allstate.allstates.map((d) => {
                if (e.target.value == d.id) {
                    setInput_States(d.state_name)
                    console.log(d.state_name);
                }
            })
        }
        console.log("input state", input_states);

        let formData = new FormData()

        formData.append("state_id", input_states_id)

        axios.get(`${Config.GET_CITY}`, formData)
            .then(res => {
                console.log("all city", res.data);
                setAllCity({ allCitys: res.data })
            })
            .catch(err => {
                console.log("error", err);
            })
    }


    const handleCityChange = (e) => {
        console.log(e.target.value);
        setInputCitys(e.target.value);

    }

    // --Initializing  input State--

    const [inputState, setInputState] = useState({
        isError: {
            full_name: '', mobile: '', pincode: '', address_one: '', address_two: '', landmark: ''
        }

    })



    var [varifysubmit, setVarifySubmit] = useState(false);

    const handleChange = (e) => {
        e.persist();
        console.log("Event", e);
        let { name, value } = e.target;
        let err = { ...inputState.isError };

        //--form validation--

        switch (name) {
            case 'full_name': err.full_name = value.length < 3 ? "Atleast 3 characters required" : "";
                break;
            // case 'full_name': err.full_name = value.length < 3 ? setVarifySubmit(false) : setVarifySubmit(true);
            //     break;


            // case 'city': err.city = value.length < 3 ? "Atleast 3 characters required" : "";
            //     break;
            // // case 'city': err.city = value.length < 3 ?   setVarifySubmit(false) : setVarifySubmit(true);
            // //     break;

            // case 'state': err.state = value.length < 3 ? "Atleast 3 characters required" : "";
            //     break;
            // // case 'state': err.state = value.length < 3 ? setVarifySubmit(false) : setVarifySubmit(true);
            // //     break;

            case 'mobile': err.mobile = validateMobile.test(value) ? " " : "Invalid Mobile Number";
                break;
            // case 'mobile': err.mobile = validateMobile.test(value) ? setVarifySubmit(true) : setVarifySubmit(false);
            // break;

            case 'pincode': err.pincode = validatePincode.test(value) ? " " : "Invalid pincode"
                break;
            // case 'pincode': err.pincode = validatePincode.test(value) ? setVarifySubmit(true) : setVarifySubmit(false);
            // break;

            case 'address_one': err.address_one = value.length < 5 ? "Atleast 5 characters required" : "";
                break;
            // case 'address_one': err.address_one = value.length < 5 ? setVarifySubmit(false) : setVarifySubmit(true);
            //     break;

            case 'address_two': err.address_two = value.length < 5 ? "Atleast 5 characters required" : "";
                break;
            // case 'address_two': err.address_two = value.length < 5 ? setVarifySubmit(false) : setVarifySubmit(true);
            //     break;

            case 'landmark': err.landmark = value.length < 3 ? "Atleast 3 characters required" : "";
                break;
            // case 'landmark': err.landmark = value.length < 3 ? setVarifySubmit(false) : setVarifySubmit(true);
            //     break;

            default:
                break;
        }


        setInputState({ ...inputState, [name]: value, isError: err });
        console.log('input value', inputState);

    }

    const submitHandle = (e) => {
        e.preventDefault();
        console.log("after submit", inputState);
        console.log("after submit state", input_states);
        console.log("after submit city", inputcitys);


        try {
            const arrayOfData = localStorage.getItem('CustomerData')
            // console.log(arrayOfData);
            const d = arrayOfData !== null ? JSON.parse(arrayOfData) : [];
            console.log(d.data.cust_code)
            SetUserData(d.data);
        }
        catch (error) {
            console.log('is error', error);
        }


        // --send form data to api--

        let full_name = inputState.full_name
        let splitFull_name = full_name.split(" ");
        let first_name = splitFull_name[0];
        let last_name = splitFull_name[1];
        let formData = new FormData()
        formData.append("cust_code", userdata.cust_code)
        formData.append("first_name", first_name)
        formData.append("last_name", last_name)
        formData.append("city", inputcitys)
        formData.append("state", input_states)
        formData.append("mobile", inputState.mobile)
        formData.append("pincode", inputState.pincode)
        formData.append("address_one", inputState.address_one)
        formData.append("address_two", inputState.address_two)
        formData.append("landmark", inputState.landmark)

        axios.post(`${Config.ADD_ADDRESS}`, formData)
        .then(res => {

            // props.setStatus(true);

            console.log("axios responce", res.data);
            alert('add done')

            navigate("/myaccount/:acc-address")
        })
        .catch(err => {
            console.log("axios error", err)
            alert('add error')
        })
    }
    //-- get allstates--

    useEffect(() => {
        axios.get(`${Config.GET_STATE}`)
            .then(res => {

                setAllState({ allstates: res.data })
            })
            .catch(err => {

            })
    }, [allstate])


    return (
        <div className='acc-address-main' >
            <Form
                className='acc-address-form'
                onSubmit={submitHandle}
            >
                <h4>Add Delivery Address</h4>
                <label className="signup-text">Full Name*</label>
                <input
                    type="text"
                    className='Addingaddress-input'
                    name="full_name"
                    // placeholder={context.customerName}
                    onChange={handleChange}
                />
                {inputState.isError.full_name.length > 0 && (<span className="signup-input-error">{inputState.isError.full_name}</span>)}<br />
                <div className='add-address-state_city'>
                    <div className='add-address-state-div'>
                        <label className="signup-text">State</label>

                        <select
                            name='state'
                            className='Addingaddress-input-select'
                            onChange={handleStateChange}
                        >
                            <option>  --Select State--  </option>
                            {/* --maping allstates-- */}
                            {

                                allstate.allstates.map((everystates) => {
                                    return (
                                        <React.Fragment key={everystates.id}>


                                            <option value={everystates.id}>{everystates.state_name}</option>

                                        </React.Fragment>
                                    )
                                }

                                )
                            }
                        </select>
                        {/* {inputState.isError.state.length > 0 && (<span className="signup-input-error">{inputState.isError.state}</span>)}<br /> */}
                    </div>

                    <div className='add-address-city-div'>
                        <label className="signup-text">City</label>


                        <select
                            name='city'
                            className='Addingaddress-input-select'
                            onChange={handleCityChange}
                        >
                            <option>  --Select City--  </option>
                            {/* --maping allstates-- */}

                            {
                                allcity.allCitys.filter((xyz) => {

                                    if (xyz.state_id === input_states_id) {
                                        return xyz
                                    }
                                })
                                    .map((everycitys) => {
                                        return (
                                            <React.Fragment key={everycitys.id}>


                                                <option>{everycitys.city_name}</option>

                                            </React.Fragment>
                                        )
                                    }

                                    )



                            }

                        </select>
                        {/* {inputState.isError.city.length > 0 && (<span className="signup-input-error">{inputState.isError.city}</span>)}<br /> */}
                    </div>

                </div>
                <label className="signup-text">Phone Number</label>
                <input
                    type="text"
                    className='Addingaddress-input'
                    name="mobile"
                    // placeholder={context.customerSigninMobile}
                    onChange={handleChange}
                />
                {inputState.isError.mobile.length > 0 && (<span className="signup-input-error">{inputState.isError.mobile}</span>)}<br />
                <label className="signup-text">Pincode</label>
                <input
                    type="text"
                    className='Addingaddress-input'
                    name="pincode"
                    onChange={handleChange}
                />
                {inputState.isError.pincode.length > 0 && (<span className="signup-input-error">{inputState.isError.pincode}</span>)}<br />
                <label className="signup-text">House No.,Building Name</label>
                <input
                    type="text"
                    className='Addingaddress-input'
                    name="address_one"
                    onChange={handleChange}
                />
                {inputState.isError.address_one.length > 0 && (<span className="signup-input-error">{inputState.isError.address_one}</span>)}<br />
                <label className="signup-text">Road Name,Area,Colony</label>
                <input
                    type="text"
                    className='Addingaddress-input'
                    name="address_two"
                    onChange={handleChange}
                />
                {inputState.isError.address_two.length > 0 && (<span className="signup-input-error">{inputState.isError.address_two}</span>)}<br />
                <label className="signup-text">Add Nearby Location/Landmark</label>
                <input
                    type="text"
                    className='Addingaddress-input'
                    name="landmark"
                    onChange={handleChange}
                />
                {inputState.isError.landmark.length > 0 && (<span className="signup-input-error">{inputState.isError.landmark}</span>)}<br />

                {/* {varifysubmit ? */}

                <button type="submit" className='Addaddress-Btne' >
                    Save Address
                </button>
                {/* :  */}
                {/* <button 
                disabled
                type="submit" 
                className='Addaddress-Btne-disable' >
                Save Address
            </button> */}
                {/* } */}
            </Form>
        </div>
    )
}

export default AddingAddress