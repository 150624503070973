import React from "react";

export default React.createContext({

    customerName: null,
    setCustomerName: (data) => { },

    customerMobile: null,
    setCustomerMobile: (data) => { },

    customerSigninMobile: null,
    setCustomerSigninMobile: (data) => { },

    customerFirstName: null,
    setCustomerFirstName: (data) => { },

    customerLastName: null,
    setCustomerLastName: (data) => { },

    customerSigninDetail: null,
    setCustomerSigninDetail: (data) => { },
    
    customerSignupDetail: null,
    setCustomerSignupDetail: (data) => { },

    customerDetail: null,
    setCustomerDetail: (data) => { },
    
    customerCode: null,
    setCustomerCode: (data) => { },
});
