import React from "react";
import "./Notfound.css";

function Notfound() {
	return (
		<div className="notfound-header">
      <div className="notfound-content">
        <img src="../../../../image/404-img.webp" />
        <h1 className="notfound-h">404</h1>
        <div className="notfound-p">
				<p >
					We're sorry! This page is currently unavailable. We request you
					to please try again later.
          </p>
          </div>
        </div>
		</div>
	);
}

export default Notfound;
